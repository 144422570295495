/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.getSpinToWinPrizes.internal

import features.getSpinToWinPrizes.models.SpinToWinPrizesModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GetSpinToWinPrizesServiceImpl : GetSpinToWinPrizesService {

    private val getSpinToWinPrizesRequest = GetSpinToWinPrizesRequest()

    override suspend fun getSpinToWinPrizes(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<SpinToWinPrizesModel> {
        return getSpinToWinPrizesRequest.getSpinToWinPrizes(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
