/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.getCrmFields.internal

import features.getCrmFields.models.CrmFields
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.getCrmFields.internal.GetCrmFieldsUseCase.Params

internal class GetCrmFieldsUseCase : AsyncUseCase<CrmFields, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val getCrmFieldsService: GetCrmFieldsService = GetCrmFieldsServiceImpl()

    override suspend fun run(params: Params): Result<CrmFields> {
        return getCrmFieldsService.getCrmFields(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
