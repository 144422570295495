/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.business.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Business profile data
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class BusinessData(
    /** Unique business ID. */
    @SerialName("id")
    val id: String?,

    /** The name of this business */
    @SerialName("name")
    val name: String?,

    /** The manner in which this business rewards its customers. This can be by purchase amount of by visit to the store  */
    @SerialName("loyalty_type")
    val loyaltyType: String?,

    /** Whether this is a conglomerate business. Conglomerate businesses are umbrellas for multiple business&#39; in Kangaroo  */
    @SerialName("conglomerate")
    val conglomerate: String?,

    /** Whether this is a white label business. White label business&#39; don&#39;t appear in the Kangaroo Mobile Customer app, as they usually have their own app.  */
    @SerialName("white_label")
    val whiteLabel: String?,

    /** General about text for the business */
    @SerialName("about")
    val about: String?,

    @SerialName("logo")
    val logo: ImageModel?,

    @SerialName("logo_transparent_background")
    val logoTransparentBackground: ImageModel?,

    @SerialName("cover_photo")
    val coverPhoto: ImageModel?,

    @SerialName("category")
    val category: BusinessCategory?
)
