/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessProducts.internal

import features.userBusinessProducts.models.UserProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessProductsServiceImpl : UserBusinessProductsService {

    private val userBusinessProductsRequest = UserBusinessProductsRequest()

    override suspend fun getUserBusinessProducts(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<UserProductsModel> {
        return userBusinessProductsRequest.getUserBusinessProducts(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
