/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.banners.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A business banner.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class Banner(
    /** Unique banner id. */
    @SerialName("id")
    val id: Int?,

    /** The banner type. */
    @SerialName("type")
    val type: Int?,

    /** Phone number associated with this user. */
    @SerialName("image_path")
    val imagePath: String?,

    /** First name associated with this user. */
    @SerialName("link")
    val link: String?,

    /** Last name associated with this user. */
    @SerialName("created_at")
    val createdAt: String?,

    /** Unique QR code identifying this user. */
    @SerialName("published_at")
    val publishedAt: String?,

    /** Gender associated with this user. */
    @SerialName("expires_at")
    val expiresAt: String?,

    @SerialName("language")
    val language: Language?,

    /** If this banners is archived or not. */
    @SerialName("archived")
    val archived: Boolean?
)
