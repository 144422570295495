/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.archiveInboxItem.internal

import features.archiveInboxItem.models.ArchiveRequest
import features.archiveInboxItem.models.ArchiveResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class ArchiveInboxItemServiceImpl : ArchiveInboxItemService {

    private val archiveInboxItemRequest = ArchiveInboxItemRequest()

    override suspend fun archiveInboxItem(
        overrideHeaders : Map<String, String>?,
        archiveRequest: ArchiveRequest    ): Result<ArchiveResponseModel> {
        return archiveInboxItemRequest.archiveInboxItem(
            overrideHeaders = overrideHeaders,
            archiveRequest = archiveRequest        ).toModel()
    }
}
