/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userTransferRecall.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class TransferMessageGiftCardQueue(
    /** The unique ID of this gift_card_queue */
    @SerialName("id")
    val id: Int?,

    /** Whether this item is enabled. Usually true. */
    @SerialName("enabled")
    val enabled: Boolean?,

    /** The name of the sender of this transfer. */
    @SerialName("sender_name")
    val senderName: String?,

    /** The name of the recipient of this transfer. */
    @SerialName("recipient_name")
    val recipientName: String?,

    /** The phone number or email address that this transfer was sent to.  */
    @SerialName("email_phone")
    val emailPhone: String?,

    /** The amount of points sent in this transfer. */
    @SerialName("points")
    val points: Int?,

    /** The amount in $ sent in this transfer. */
    @SerialName("amount")
    val amount: Double?,

    /** The orignal creation date of this transfer. */
    @SerialName("created_at")
    val createdAt: String?,

    /** The original scheduled delivery date of this transfer. */
    @SerialName("scheduled_at")
    val scheduledAt: String?
)
