@file:OptIn(ExperimentalJsExport::class, ExperimentalSerializationApi::class)

package kangaroorewards.appsdk.core.io

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport

/**
 * Base DAO object returned from Network results and Database queries.
 * Should be Serializable.
 */
@Serializable
//TODO investigate @JsExport for base Model class
abstract class Model

/**
 * Represents an empty response. This could be either a successful response
 * or an error - it simply means the http response has no body.
 */
@ExperimentalJsExport
@Serializable()
object EmptyResponse : Model()