/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.uploadImage.internal

import features.uploadImage.internal.UploadImageUseCase.Params
import features.uploadImage.models.ImagesResponseModel
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.krMultiPartForm.UploadImageRequestModel

internal class UploadImageUseCase : AsyncUseCase<ImagesResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val uploadImageBodyRequest: UploadImageRequestModel
    )

    private val uploadImageService: UploadImageService = UploadImageServiceImpl()

    override suspend fun run(params: Params): Result<ImagesResponseModel> {
        return uploadImageService.uploadImage(
            overrideHeaders = params.overrideHeaders,
            uploadImageBodyRequest = params.uploadImageBodyRequest
        )
    }
}
