/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userDelete.internal

import kangaroorewards.appsdk.core.io.EmptyResponse
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userDelete.internal.UserDeleteUseCase.Params

internal class UserDeleteUseCase : AsyncUseCase<EmptyResponse, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val userDeleteService: UserDeleteService = UserDeleteServiceImpl()

    override suspend fun run(params: Params): Result<EmptyResponse> {
        return userDeleteService.deleteUserAccount(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
