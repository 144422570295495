/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.strings.internal

import features.strings.models.ApplicationStringsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.strings.internal.StringsUseCase.Params

internal class StringsUseCase : AsyncUseCase<ApplicationStringsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val stringsService: StringsService = StringsServiceImpl()

    override suspend fun run(params: Params): Result<ApplicationStringsModel> {
        return stringsService.getStrings(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
