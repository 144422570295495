/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userRegistrationWithCrm.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class CrmRegisterRequest(
    @SerialName("first_name")
    val firstName: String?,

    @SerialName("last_name")
    val lastName: String?,

    @SerialName("email")
    val email: String?,

    @SerialName("phone")
    val phone: String?,

    @SerialName("country_code")
    val countryCode: String?,

    @SerialName("gender")
    val gender: String?,

    @SerialName("referral_code")
    val referralCode: String?,

    @SerialName("birth_date")
    val birthDate: String?,

    @SerialName("postal_code")
    val postalCode: String?,

    @SerialName("country")
    val country: String?,

    @SerialName("branch_id")
    val branchId: String?,

    @SerialName("promotions_mail")
    val promotionsMail: Boolean?,

    @SerialName("terms_and_conditions")
    val termsAndConditions: Boolean?,

    @SerialName("consent")
    val consent: UserConsent?,

    @SerialName("custom_field_1")
    val customField1: String?,

    @SerialName("custom_field_2")
    val customField2: String?,

    @SerialName("custom_field_3")
    val customField3: String?,

    @SerialName("custom_field_4")
    val customField4: String?,

    @SerialName("custom_field_5")
    val customField5: String?,

    @SerialName("address")
    val address: UserAddress?,

    @SerialName("language")
    val language: String?
)
