/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessProfile.internal

import features.userBusinessProfile.models.UserBusinessProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userBusinessProfile.internal.UserBusinessProfileUseCase.Params

internal class UserBusinessProfileUseCase : AsyncUseCase<UserBusinessProfileModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val userBusinessProfileService: UserBusinessProfileService = UserBusinessProfileServiceImpl()

    override suspend fun run(params: Params): Result<UserBusinessProfileModel> {
        return userBusinessProfileService.getUserBusinessProfile(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
