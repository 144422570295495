package kangaroorewards.appsdk.core.krMultiPartForm

import io.ktor.client.fetch.*
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get

actual class FileFromPath {
    /**
     * Gets a File object from String path
     */

    actual fun get(path: String, callback: (ByteArray?) -> Unit) {
        fetch(path).then { response ->
            if (!response.ok) {
                callback(null) // Call the callback with null if the response is not ok
            } else {
                response.arrayBuffer().then { arrayBuffer ->
                    val uint8Array = Uint8Array(arrayBuffer)
                    val byteArray = ByteArray(uint8Array.length) { uint8Array[it] }
                    callback(byteArray) // Call the callback with the ByteArray
                }.catch { error ->
                    console.error("Error fetching file: ${error.message}")
                    callback(null) // Call the callback with null on error
                }
            }
        }.catch { error ->
            console.error("Error fetching file: ${error.message}")
            callback(null) // Call the callback with null on error
        }
    }



}