/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.claimOffer.internal

import features.claimOffer.models.ClaimedOfferModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class ClaimOfferRequest : NetworkRequest() {

    suspend fun claimOffer(
        overrideHeaders: Map<String, String>? = null,
        offerId: Int,
        customerId: String
    ): IOResult<ClaimedOfferModel> {
        return safeNetworkCall {
            patch<ClaimedOfferModel, Any>(
                endpoint = ClaimOfferEndpoint.replaceInPath(
                    PathParameter("offerId", offerId),
                    PathParameter("customerId", customerId),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
