/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.getDefaultBusinessId.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Default business chosen by the user
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class DefaultBusiness(
    /** Details about the default business */
    @SerialName("data")
    val data: DefaultBusinessData?
) : Model()
