/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicBusinessSettings.internal

import features.publicBusinessSettings.models.PublicBusinessSettings
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class PublicBusinessSettingsRequest : NetworkRequest() {

    suspend fun getPublicBusinessSettings(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<PublicBusinessSettings> {
        return safeNetworkCall {
            get(
                endpoint = PublicBusinessSettingsEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
