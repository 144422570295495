/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.makeGiftCardPurchase

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.makeGiftCardPurchase.GiftCardPurchaseApi
import features.makeGiftCardPurchase.models.GiftCardPayPalPaymentResponseModel
import features.makeGiftCardPurchase.serializeGiftCardPurchaseState
import features.makeGiftCardPurchase.models.GiftCardPurchaseRequest

import kotlinx.serialization.decodeFromString

/**
 * Purchase a gift card [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("GiftCardPurchaseApi")
class GiftCardPurchaseApiJs {

    /**
     * Purchase a gift card
     */


    
    fun purchaseGiftCard(overrideHeaders: String?,
serializedPurchaseGiftCardRequest: String): Promise<String?>  {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

        return GlobalScope.promise {
            val result = GiftCardPurchaseApi().purchaseGiftCard(
            overrideHeaders = decodedOverrideHeaders,
                purchaseGiftCardRequest = Json.decodeFromString(
                    serializedPurchaseGiftCardRequest
                )
            ).toJsonResult<GiftCardPayPalPaymentResponseModel>()

           return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    fun observeGiftCardPurchaseState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        GiftCardPurchaseApi().giftCardPurchaseState.serializeGiftCardPurchaseState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
