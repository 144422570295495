/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessProducts.internal

import features.userBusinessProducts.models.UserProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userBusinessProducts.internal.UserBusinessProductsUseCase.Params

internal class UserBusinessProductsUseCase : AsyncUseCase<UserProductsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val userBusinessProductsService: UserBusinessProductsService = UserBusinessProductsServiceImpl()

    override suspend fun run(params: Params): Result<UserProductsModel> {
        return userBusinessProductsService.getUserBusinessProducts(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
