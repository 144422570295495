/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userNotificationPreferencesUpdate.internal

import features.userNotificationPreferencesUpdate.models.NotificationSettingsModel
import features.userNotificationPreferencesUpdate.models.NotificationSettingsUpdateRequest
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userNotificationPreferencesUpdate.internal.UpdateNotificationPreferencesUseCase.Params

internal class UpdateNotificationPreferencesUseCase : AsyncUseCase<NotificationSettingsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val notificationPreferencesRequest: NotificationSettingsUpdateRequest
    )

    private val updateNotificationPreferencesService: UpdateNotificationPreferencesService = UpdateNotificationPreferencesServiceImpl()

    override suspend fun run(params: Params): Result<NotificationSettingsModel> {
        return updateNotificationPreferencesService.updateNotificationPreferences(
            overrideHeaders = params.overrideHeaders,
            notificationPreferencesRequest = params.notificationPreferencesRequest
        )
    }
}
