package kangaroorewards.appsdk.core.krMultiPartForm

import kangaroorewards.appsdk.core.io.Model
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@ExperimentalJsExport
@JsExport
@Serializable
data class UploadImageRequestModel(
    @SerialName("module")
    val module: Int?,

    @SerialName("images")
    val images: List<KRMultiPartFormFileModel>?
) : Model()


@ExperimentalJsExport
@JsExport
@Serializable
data class KRMultiPartFormFileModel(
    @SerialName("name")
    val name: String?,

    @SerialName("filePath")
    val filePath: String?
) : Model()

