/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.business

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.business.internal.BusinessUseCase
import features.business.internal.BusinessUseCase.Params
import features.business.models.Business


typealias BusinessState = CFlow<BusinessUseCaseResult?>

typealias BusinessUseCaseResult = Result<Business>

fun BusinessUseCaseResult.serializeBusinessApiResult(): SerializedResult<String> {
    return this.toJsonResult<Business>()
}

/**
 * Serializes [BusinessState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun BusinessState.serializeBusinessState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<Business>()
    }.asCFlow()
}

/**
 * Get the business associated with this user account and application [Api] class
 */
class BusinessApi : Api() {

    val businessState: BusinessState
        get() = _businessState.asCFlow()

    companion object {
        private val _businessState: MutableStateFlow<BusinessUseCaseResult> = MutableStateFlow(Idle())
    }

    private val businessUseCase = BusinessUseCase()

    /**
     * Get the business associated with this user account and application
     */
    suspend fun getBusiness(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<Business> {
        /* set state to Loading as soon as function is called */
        _businessState.value = Loading()
        val result = this.businessUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _businessState.value = result
            return@businessUseCase result
        }
        return result
    }

}
