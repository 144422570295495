/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userConglomerateNotificationPreferences.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class NotificationSettingsData(
    /** The notification settings id. This is not required when updating notification settings.  */
    @SerialName("id")
    val id: String?,

    @SerialName("business")
    val business: NotificationBusiness?,

    @SerialName("allow_email")
    val allowEmail: Boolean?,

    @SerialName("allow_sms")
    val allowSms: Boolean?,

    @SerialName("allow_push")
    val allowPush: Boolean?,

    @SerialName("allow_sms_status")
    val allowSmsStatus: Int?
)
