/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userNotificationPreferences.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * The business who these settings this affect. This is not required when updating notification settings. 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class NotificationBusiness(
    @SerialName("id")
    val id: String?,

    @SerialName("name")
    val name: String?,

    @SerialName("settings")
    val settings: NotificationBusinessSettings?
)
