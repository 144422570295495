/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.postOfferFacebookShare.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents a single tier perk for a tier level.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class TierPerk(
    /** The ID for this tier perk. */
    @SerialName("id")
    val id: Int?,

    /** The title of this tier perk. */
    @SerialName("title")
    val title: String?,

    /** The ID for the tier level this tier perk is related to. */
    @SerialName("tier_id")
    val tierId: Int?,

    @SerialName("offer_id")
    val offerId: Int?,

    @SerialName("punch_item_id")
    val punchItemId: Int?,

    /** Whether this tier perk is currently enabled or disabled. */
    @SerialName("enabled")
    val enabled: Boolean?,

    /** Whether the offer this tier perk is currently attached to is expired or not. */
    @SerialName("is_expired")
    val isExpired: Boolean?,

    /** The perks related to this tier level. */
    @SerialName("languages")
    val languages: List<TierPerkTranslation>?
)
