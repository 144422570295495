/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userDetails.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * User Details model class containing user details
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class UserDetailsModel(
    /** Contains all user details */
    @SerialName("data")
    val data: UserProfileDataModel?
) : Model()
