/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.publicALaCarteProducts

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.publicALaCarteProducts.internal.PublicAlaCarteProductsUseCase
import features.publicALaCarteProducts.internal.PublicAlaCarteProductsUseCase.Params
import features.publicALaCarteProducts.models.PublicALaCarteProductsModel


typealias PublicAlaCarteProductsState = CFlow<PublicAlaCarteProductsUseCaseResult?>

typealias PublicAlaCarteProductsUseCaseResult = Result<PublicALaCarteProductsModel>

fun PublicAlaCarteProductsUseCaseResult.serializePublicAlaCarteProductsApiResult(): SerializedResult<String> {
    return this.toJsonResult<PublicALaCarteProductsModel>()
}

/**
 * Serializes [PublicAlaCarteProductsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun PublicAlaCarteProductsState.serializePublicAlaCarteProductsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PublicALaCarteProductsModel>()
    }.asCFlow()
}

/**
 * Get public A la carte products list [Api] class
 */
class PublicAlaCarteProductsApi : Api() {

    val publicAlaCarteProductsState: PublicAlaCarteProductsState
        get() = _publicAlaCarteProductsState.asCFlow()

    companion object {
        private val _publicAlaCarteProductsState: MutableStateFlow<PublicAlaCarteProductsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val publicAlaCarteProductsUseCase = PublicAlaCarteProductsUseCase()

    /**
     * Get public A la carte products list
     */
    suspend fun getPublicAlaCarteProducts(
        overrideHeaders: Map<String, String>? = null,

    ): Result<PublicALaCarteProductsModel> {
        /* set state to Loading as soon as function is called */
        _publicAlaCarteProductsState.value = Loading()
        val result = this.publicAlaCarteProductsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _publicAlaCarteProductsState.value = result
            return@publicAlaCarteProductsUseCase result
        }
        return result
    }

}
