@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package features.userAuthentication

import features.strings.models.ApplicationStringsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import features.userAuthentication.internal.AuthenticateUser
import features.userAuthentication.internal.AuthenticateUser.Params
import features.userAuthentication.internal.UserReAuthenticationRequest
import features.userAuthentication.models.UserAuthenticationModel
import features.userAuthentication.models.UserAuthenticationResult
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlin.js.ExperimentalJsExport

@OptIn(ExperimentalJsExport::class)
typealias UserAuthenticationState = CFlow<Result<UserAuthenticationModel>?>

typealias UserAuthenticationResult = Result<UserAuthenticationModel>

fun UserAuthenticationResult.serializeUserAuthenticationResult(): SerializedResult<String> {
    return this.toJsonResult<UserAuthenticationModel>()
}

/**
 * Serializes [UserAuthenticationState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class, ExperimentalJsExport::class)
fun UserAuthenticationState.serializeUserAuthenticationState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserAuthenticationModel>()
    }.asCFlow()
}

@ExperimentalJsExport
class UserAuthenticationApi : Api() {

    // The UI collects from this StateFlow to get its state updates
    val userAuthenticationState: UserAuthenticationState
        get() = _userAuthenticationState.asCFlow()

    companion object {
        // Backing property to avoid state updates from other classes
        private val _userAuthenticationState: MutableStateFlow<UserAuthenticationResult> =
            MutableStateFlow(Idle())
    }


    private val authenticateUserUseCase = AuthenticateUser()

    suspend fun authenticateUser(username: String?, password: String?, googleToken: String?, overrideHeaders: Map<String, String>?): Result<UserAuthenticationModel> {
        /* set state to Loading as soon as function is called */

        _userAuthenticationState.value = Loading()
        val result = authenticateUserUseCase(
            Params(
                username = username,
                password = password,
                googleToken = googleToken,
                overrideHeaders = overrideHeaders,
            )
        ) { result ->
            _userAuthenticationState.value = result
            return@authenticateUserUseCase result
        }
        return result
    }
}