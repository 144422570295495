package kangaroorewards.appsdk.core.domain

import kangaroorewards.appsdk.core.io.Model
import kangaroorewards.appsdk.core.utils.dispatcher
import kotlinx.coroutines.*

/**
 * Abstract class for a Use Case.
 * This abstraction represents an execution unit for different use cases (this means that any use
 * case in the application should implement this contract).
 *
 * By convention, each [AsyncUseCase] implementation will execute its job on a background thread
 * (kotlin coroutine) and will post the result in the UI thread.
 */
abstract class AsyncUseCase<T, in Params> where T : Model {

    abstract suspend fun run(params: Params): Result<T>

    suspend operator fun invoke(
        params: Params,
        onResult: (Result<T>) -> Result<T>
    ): Result<T> {
        val job = Job()
        val result: Deferred<Result<T>> = CoroutineScope(Dispatchers.Main).async {
            onResult(
                withContext(dispatcher() + job) {
                    run(params)
                }
            )
        }

        val data: Result<T> = result.await();
        return data
    }

    class None
}