/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userUnsubscribe.internal

import kangaroorewards.appsdk.core.io.EmptyResponse
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserUnsubscribeServiceImpl : UserUnsubscribeService {

    private val userUnsubscribeRequest = UserUnsubscribeRequest()

    override suspend fun unsubscribe(
        overrideHeaders : Map<String, String>?,
        campaignId: String,
        token: String    ): Result<EmptyResponse> {
        return userUnsubscribeRequest.unsubscribe(
            overrideHeaders = overrideHeaders,
            campaignId = campaignId,
            token = token        ).toModel()
    }
}
