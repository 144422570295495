/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userBusinessProducts

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userBusinessProducts.UserBusinessProductsApi
import features.userBusinessProducts.models.UserProductsModel
import features.userBusinessProducts.serializeUserBusinessProductsState



/**
 * Get user products list by specific business [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UserBusinessProductsApi")
class UserBusinessProductsApiJs {

    /**
     * Get user products list by specific business
     */
    fun getUserBusinessProducts(
        overrideHeaders: String?, 
        businessId: String
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = UserBusinessProductsApi().getUserBusinessProducts(
            overrideHeaders = decodedOverrideHeaders,
                        businessId = businessId
        ).toJsonResult<UserProductsModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeUserBusinessProductsState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserBusinessProductsApi().userBusinessProductsState.serializeUserBusinessProductsState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
