/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.draws.internal

import features.draws.models.DrawsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.draws.internal.DrawsUseCase.Params

internal class DrawsUseCase : AsyncUseCase<DrawsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val drawsService: DrawsService = DrawsServiceImpl()

    override suspend fun run(params: Params): Result<DrawsModel> {
        return drawsService.getDraws(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
