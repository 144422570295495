/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.claimOffer

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.claimOffer.ClaimOfferApi
import features.claimOffer.models.ClaimedOfferModel
import features.claimOffer.serializeClaimOfferState



/**
 * Claim an offer. Once it has been claimed it will be available to be used towards a purchase.  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("ClaimOfferApi")
class ClaimOfferApiJs {

    /**
     * Claim an offer. Once it has been claimed it will be available to be used towards a purchase. 
     */
    fun claimOffer(
        overrideHeaders: String?, 
        offerId: Int,
        customerId: String
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = ClaimOfferApi().claimOffer(
            overrideHeaders = decodedOverrideHeaders,
                        offerId = offerId,
            customerId = customerId
        ).toJsonResult<ClaimedOfferModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeClaimOfferState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        ClaimOfferApi().claimOfferState.serializeClaimOfferState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
