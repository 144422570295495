/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userPinResetWithToken.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class PinResetWithTokenRequest(
    @SerialName("token")
    val token: String,

    @SerialName("pin_code")
    val pinCode: String,

    @SerialName("use_same_pin")
    val useSamePin: Boolean
)
