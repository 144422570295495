/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userProducts.internal

import features.userProducts.models.UserProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userProducts.internal.UserProductsUseCase.Params

internal class UserProductsUseCase : AsyncUseCase<UserProductsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val userProductsService: UserProductsService = UserProductsServiceImpl()

    override suspend fun run(params: Params): Result<UserProductsModel> {
        return userProductsService.getUserProducts(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
