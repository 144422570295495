/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.publicDepositGiftCard.internal

import features.publicDepositGiftCard.models.TransferActionResultModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PublicDepositGiftCardServiceImpl : PublicDepositGiftCardService {

    private val publicDepositGiftCardRequest = PublicDepositGiftCardRequest()

    override suspend fun publicDepositGiftCard(
        overrideHeaders : Map<String, String>?,
        userId: String,
        messageId: String    ): Result<TransferActionResultModel> {
        return publicDepositGiftCardRequest.publicDepositGiftCard(
            overrideHeaders = overrideHeaders,
            userId = userId,
            messageId = messageId        ).toModel()
    }
}
