/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBalanceTransfer.internal

import features.userBalanceTransfer.models.TransferRequestModel
import features.userBalanceTransfer.models.TransferResponseModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class BalanceTransferRequest : NetworkRequest() {

    suspend fun transfer(
        overrideHeaders: Map<String, String>? = null,
        transferRequest: TransferRequestModel
    ): IOResult<TransferResponseModel> {
        return safeNetworkCall {
            post<TransferResponseModel, TransferRequestModel>(
                endpoint = BalanceTransferEndpoint,
                requestBody = transferRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
