/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicProducts.internal

import features.publicProducts.models.PublicProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.publicProducts.internal.PublicProductsUseCase.Params

internal class PublicProductsUseCase : AsyncUseCase<PublicProductsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val publicProductsService: PublicProductsService = PublicProductsServiceImpl()

    override suspend fun run(params: Params): Result<PublicProductsModel> {
        return publicProductsService.getPublicProducts(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
