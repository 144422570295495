/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.redeemReward.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class RedemptionModel(
    /** A unique ID identifying this reward. */
    @SerialName("id")
    val id: Int,

    /** The amount of times to redeem this reward */
    @SerialName("quantity")
    val quantity: Int
) : Model()
