/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userConglomerateNotificationPreferences.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * The settings of this business. 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class NotificationBusinessSettings(
    @SerialName("double_opt_in")
    val doubleOptIn: Boolean?
)
