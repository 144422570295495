/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userBusinessTiers.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class Tier(
    /** The ID for this tier program. */
    @SerialName("id")
    val id: Int?,

    /** The sequence for this tier program. */
    @SerialName("tiers_sequence")
    val tiersSequence: List<Int>?,

    /** If the tier program is an &#39;and&#39; or &#39;or&#39; */
    @SerialName("is_combined")
    val isCombined: Boolean?,

    @SerialName("reset_type")
    val resetType: Int?,

    /** The month in which this tier program will reset. */
    @SerialName("reset_period_month")
    val resetPeriodMonth: Int?,

    @SerialName("base_previous_period")
    val basePreviousPeriod: Int?,

    /** Whether this tier program is currently enabled or disabled. */
    @SerialName("enabled")
    val enabled: Boolean?,

    /** Default icon to show in case the user has no current tier level. */
    @SerialName("default_tier_icon")
    val defaultTierIcon: String?,

    /** The various tier levels within this tier program. */
    @SerialName("tier_levels")
    val tierLevels: List<TierLevel>?,

    /** To control whether to use customer registration month for reset.. */
    @SerialName("reset_by_customer_registration_month")
    val resetByCustomerRegistrationMonth: Boolean?,

    /** How many months after the reset month. */
    @SerialName("grace_period")
    val gracePeriod: String?
)
