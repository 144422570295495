/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessUsedCoupons.internal

import features.userBusinessUsedCoupons.models.UserBusinessCouponsModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class UserBusinessUsedCouponsRequest : NetworkRequest() {

    suspend fun getUserBusinessUsedCoupons(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): IOResult<UserBusinessCouponsModel> {
        return safeNetworkCall {
            get(
                endpoint = UserBusinessUsedCouponsEndpoint.replaceInPath(
                    PathParameter("businessId", businessId),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
