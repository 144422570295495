/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.publicProducts.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A list of products available to all users.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class PublicProductsModel(
    /** A list of products available to all users. */
    @SerialName("data")
    val data: ProductsList?
) : Model()
