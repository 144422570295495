/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.getDefaultBusinessId.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Default Business id data
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class DefaultBusinessData(
    /** Unique branch Id */
    @SerialName("default_branch_id")
    val defaultBranchId: String?,

    /** Unique business Id */
    @SerialName("default_business_id")
    val defaultBusinessId: String?
)
