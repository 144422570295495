/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.redeemCoupon.internal

import features.redeemCoupon.models.CouponRedemptionResponseModel
import features.redeemCoupon.models.RedeemCouponRequest
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.redeemCoupon.internal.RedeemCouponsUseCase.Params

internal class RedeemCouponsUseCase : AsyncUseCase<CouponRedemptionResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val redeemCouponRequest: RedeemCouponRequest
    )

    private val redeemCouponsService: RedeemCouponsService = RedeemCouponsServiceImpl()

    override suspend fun run(params: Params): Result<CouponRedemptionResponseModel> {
        return redeemCouponsService.redeemCoupon(
            overrideHeaders = params.overrideHeaders,
            redeemCouponRequest = params.redeemCouponRequest
        )
    }
}
