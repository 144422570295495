/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userCheckIn

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userCheckIn.internal.UserCheckInUseCase
import features.userCheckIn.internal.UserCheckInUseCase.Params
import features.userCheckIn.models.CheckInResponseModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.userCheckIn.models.CheckInRequest

typealias UserCheckInState = CFlow<UserCheckInUseCaseResult?>

typealias UserCheckInUseCaseResult = Result<CheckInResponseModel>

fun UserCheckInUseCaseResult.serializeUserCheckInApiResult(): SerializedResult<String> {
    return this.toJsonResult<CheckInResponseModel>()
}

/**
 * Serializes [UserCheckInState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserCheckInState.serializeUserCheckInState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<CheckInResponseModel>()
    }.asCFlow()
}

/**
 * Check in with a business. The user may be rewarded for this action according to rules set in the business portal on Kangaroo.  [Api] class
 */
class UserCheckInApi : Api() {

    val userCheckInState: UserCheckInState
        get() = _userCheckInState.asCFlow()

    companion object {
        private val _userCheckInState: MutableStateFlow<UserCheckInUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userCheckInUseCase = UserCheckInUseCase()

    /**
     * Check in with a business. The user may be rewarded for this action according to rules set in the business portal on Kangaroo. 
     */
    suspend fun userCheckIn(
        overrideHeaders: Map<String, String>? = null,
        checkInRequest: CheckInRequest
    ): Result<CheckInResponseModel> {
        /* set state to Loading as soon as function is called */
        _userCheckInState.value = Loading()
        val result = this.userCheckInUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                checkInRequest = checkInRequest
            )
        ) { result ->
            _userCheckInState.value = result
            return@userCheckInUseCase result
        }
        return result
    }

    /**
    * Check in with a business. The user may be rewarded for this action according to rules set in the business portal on Kangaroo. 
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun userCheckIn(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<CheckInResponseModel> {
        return userCheckIn(
            overrideHeaders = overrideHeaders,
            checkInRequest = Json.decodeFromString(methods.getValue("checkInRequest") as String)
        )
    }
}
