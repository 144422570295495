/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.getCrmFields.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A list of the business crm fields. 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class CrmFields(
    /** A list of the business crm fields. */
    @SerialName("data")
    val data: List<CrmFieldData>?
) : Model()
