/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userAlaCarte.internal

import kangaroorewards.appsdk.core.network.base.Endpoint

/**
 * The HTTP endpoint for the [AlaCarteProductsApi].
 *
 * [Endpoint] has a single [Endpoint.path] parameter ex. "users/me"
 */
internal val AlaCarteProductsEndpoint = Endpoint(path = "users/me?include=product_rewards&relationships=product_rewards.product")
