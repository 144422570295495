/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userTransactionHistory.internal

import features.userTransactionHistory.models.UserTransactionHistoryModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class UserTransactionHistoryRequest : NetworkRequest() {

    suspend fun getUserTransactionHistory(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<UserTransactionHistoryModel> {
        return safeNetworkCall {
            get(
                endpoint = UserTransactionHistoryEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
