/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.frequentBuyerPrograms

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.frequentBuyerPrograms.internal.FrequentBuyerProgramsUseCase
import features.frequentBuyerPrograms.internal.FrequentBuyerProgramsUseCase.Params
import features.frequentBuyerPrograms.models.FrequentBuyerProgramsModel


typealias FrequentBuyerProgramsState = CFlow<FrequentBuyerProgramsUseCaseResult?>

typealias FrequentBuyerProgramsUseCaseResult = Result<FrequentBuyerProgramsModel>

fun FrequentBuyerProgramsUseCaseResult.serializeFrequentBuyerProgramsApiResult(): SerializedResult<String> {
    return this.toJsonResult<FrequentBuyerProgramsModel>()
}

/**
 * Serializes [FrequentBuyerProgramsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun FrequentBuyerProgramsState.serializeFrequentBuyerProgramsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<FrequentBuyerProgramsModel>()
    }.asCFlow()
}

/**
 * Get a list of frequent buyer programs for this business [Api] class
 */
class FrequentBuyerProgramsApi : Api() {

    val frequentBuyerProgramsState: FrequentBuyerProgramsState
        get() = _frequentBuyerProgramsState.asCFlow()

    companion object {
        private val _frequentBuyerProgramsState: MutableStateFlow<FrequentBuyerProgramsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val frequentBuyerProgramsUseCase = FrequentBuyerProgramsUseCase()

    /**
     * Get a list of frequent buyer programs for this business
     */
    suspend fun getFrequentBuyerPrograms(
        overrideHeaders: Map<String, String>? = null,

    ): Result<FrequentBuyerProgramsModel> {
        /* set state to Loading as soon as function is called */
        _frequentBuyerProgramsState.value = Loading()
        val result = this.frequentBuyerProgramsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _frequentBuyerProgramsState.value = result
            return@frequentBuyerProgramsUseCase result
        }
        return result
    }

}
