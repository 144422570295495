/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.claimOffer

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.claimOffer.internal.ClaimOfferUseCase
import features.claimOffer.internal.ClaimOfferUseCase.Params
import features.claimOffer.models.ClaimedOfferModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json


typealias ClaimOfferState = CFlow<ClaimOfferUseCaseResult?>

typealias ClaimOfferUseCaseResult = Result<ClaimedOfferModel>

fun ClaimOfferUseCaseResult.serializeClaimOfferApiResult(): SerializedResult<String> {
    return this.toJsonResult<ClaimedOfferModel>()
}

/**
 * Serializes [ClaimOfferState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun ClaimOfferState.serializeClaimOfferState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<ClaimedOfferModel>()
    }.asCFlow()
}

/**
 * Claim an offer. Once it has been claimed it will be available to be used towards a purchase.  [Api] class
 */
class ClaimOfferApi : Api() {

    val claimOfferState: ClaimOfferState
        get() = _claimOfferState.asCFlow()

    companion object {
        private val _claimOfferState: MutableStateFlow<ClaimOfferUseCaseResult> = MutableStateFlow(Idle())
    }

    private val claimOfferUseCase = ClaimOfferUseCase()

    /**
     * Claim an offer. Once it has been claimed it will be available to be used towards a purchase. 
     */
    suspend fun claimOffer(
        overrideHeaders: Map<String, String>? = null,
        offerId: Int,
        customerId: String
    ): Result<ClaimedOfferModel> {
        /* set state to Loading as soon as function is called */
        _claimOfferState.value = Loading()
        val result = this.claimOfferUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                offerId = offerId,
                customerId = customerId
            )
        ) { result ->
            _claimOfferState.value = result
            return@claimOfferUseCase result
        }
        return result
    }

}
