/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userPinResetWithToken.internal

import features.userPinResetWithToken.models.PinResetWithTokenRequestModel
import kangaroorewards.appsdk.core.io.EmptyResponse
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userPinResetWithToken.internal.UserPinResetWithTokenUseCase.Params

internal class UserPinResetWithTokenUseCase : AsyncUseCase<EmptyResponse, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val pinResetWithTokenRequest: PinResetWithTokenRequestModel
    )

    private val userPinResetWithTokenService: UserPinResetWithTokenService = UserPinResetWithTokenServiceImpl()

    override suspend fun run(params: Params): Result<EmptyResponse> {
        return userPinResetWithTokenService.resetPinWithToken(
            overrideHeaders = params.overrideHeaders,
            pinResetWithTokenRequest = params.pinResetWithTokenRequest
        )
    }
}
