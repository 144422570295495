/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userOffers

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userOffers.internal.UserOffersUseCase
import features.userOffers.internal.UserOffersUseCase.Params
import features.userOffers.models.UserOffersModel


typealias UserOffersState = CFlow<UserOffersUseCaseResult?>

typealias UserOffersUseCaseResult = Result<UserOffersModel>

fun UserOffersUseCaseResult.serializeUserOffersApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserOffersModel>()
}

/**
 * Serializes [UserOffersState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserOffersState.serializeUserOffersState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserOffersModel>()
    }.asCFlow()
}

/**
 * Get a list of offers available to this user. [Api] class
 */
class UserOffersApi : Api() {

    val userOffersState: UserOffersState
        get() = _userOffersState.asCFlow()

    companion object {
        private val _userOffersState: MutableStateFlow<UserOffersUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userOffersUseCase = UserOffersUseCase()

    /**
     * Get a list of offers available to this user.
     */
    suspend fun getUserOffers(
        overrideHeaders: Map<String, String>? = null,

    ): Result<UserOffersModel> {
        /* set state to Loading as soon as function is called */
        _userOffersState.value = Loading()
        val result = this.userOffersUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _userOffersState.value = result
            return@userOffersUseCase result
        }
        return result
    }

}
