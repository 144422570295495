/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.publicReward

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.publicReward.internal.PublicRewardUseCase
import features.publicReward.internal.PublicRewardUseCase.Params
import features.publicReward.models.PublicRewardResponseModel


typealias PublicRewardState = CFlow<PublicRewardUseCaseResult?>

typealias PublicRewardUseCaseResult = Result<PublicRewardResponseModel>

fun PublicRewardUseCaseResult.serializePublicRewardApiResult(): SerializedResult<String> {
    return this.toJsonResult<PublicRewardResponseModel>()
}

/**
 * Serializes [PublicRewardState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun PublicRewardState.serializePublicRewardState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PublicRewardResponseModel>()
    }.asCFlow()
}

/**
 * Get a public reward [Api] class
 */
class PublicRewardApi : Api() {

    val publicRewardState: PublicRewardState
        get() = _publicRewardState.asCFlow()

    companion object {
        private val _publicRewardState: MutableStateFlow<PublicRewardUseCaseResult> = MutableStateFlow(Idle())
    }

    private val publicRewardUseCase = PublicRewardUseCase()

    /**
     * Get a public reward
     */
    suspend fun getPublicReward(
        overrideHeaders: Map<String, String>? = null,
        rewardSlug: String
    ): Result<PublicRewardResponseModel> {
        /* set state to Loading as soon as function is called */
        _publicRewardState.value = Loading()
        val result = this.publicRewardUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                rewardSlug = rewardSlug
            )
        ) { result ->
            _publicRewardState.value = result
            return@publicRewardUseCase result
        }
        return result
    }

}
