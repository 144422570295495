/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessUsedCoupons.internal

import kangaroorewards.appsdk.core.network.base.Endpoint

/**
 * The HTTP endpoint for the [UserBusinessUsedCouponsApi].
 *
 * [Endpoint] has a single [Endpoint.path] parameter ex. "users/me"
 */
internal val UserBusinessUsedCouponsEndpoint = Endpoint(path = "users/me/businesses/{businessId}?per_page=50&include=coupons&coupon_type_id=eq|75")
