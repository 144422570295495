/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userTransferRecall.internal

import features.userTransferRecall.models.TransferActionResultModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class TransferRecallRequest : NetworkRequest() {

    suspend fun recallTransfer(
        overrideHeaders: Map<String, String>? = null,
        recallId: String
    ): IOResult<TransferActionResultModel> {
        return safeNetworkCall {
            patch<TransferActionResultModel, Any>(
                endpoint = TransferRecallEndpoint.replaceInPath(
                    PathParameter("recallId", recallId),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
