/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBalanceTransfer

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userBalanceTransfer.internal.BalanceTransferUseCase
import features.userBalanceTransfer.internal.BalanceTransferUseCase.Params
import features.userBalanceTransfer.models.TransferResponseModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.userBalanceTransfer.models.TransferRequestModel

typealias BalanceTransferState = CFlow<BalanceTransferUseCaseResult?>

typealias BalanceTransferUseCaseResult = Result<TransferResponseModel>

fun BalanceTransferUseCaseResult.serializeBalanceTransferApiResult(): SerializedResult<String> {
    return this.toJsonResult<TransferResponseModel>()
}

/**
 * Serializes [BalanceTransferState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun BalanceTransferState.serializeBalanceTransferState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<TransferResponseModel>()
    }.asCFlow()
}

/**
 * Transfer points or e-wallet balance to another users account.  [Api] class
 */
class BalanceTransferApi : Api() {

    val balanceTransferState: BalanceTransferState
        get() = _balanceTransferState.asCFlow()

    companion object {
        private val _balanceTransferState: MutableStateFlow<BalanceTransferUseCaseResult> = MutableStateFlow(Idle())
    }

    private val balanceTransferUseCase = BalanceTransferUseCase()

    /**
     * Transfer points or e-wallet balance to another users account. 
     */
    suspend fun transfer(
        overrideHeaders: Map<String, String>? = null,
        transferRequest: TransferRequestModel
    ): Result<TransferResponseModel> {
        /* set state to Loading as soon as function is called */
        _balanceTransferState.value = Loading()
        val result = this.balanceTransferUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                transferRequest = transferRequest
            )
        ) { result ->
            _balanceTransferState.value = result
            return@balanceTransferUseCase result
        }
        return result
    }

    /**
    * Transfer points or e-wallet balance to another users account. 
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun transfer(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<TransferResponseModel> {
        return transfer(
            overrideHeaders = overrideHeaders,
            transferRequest = Json.decodeFromString(methods.getValue("transferRequest") as String)
        )
    }
}
