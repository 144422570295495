/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.postOfferFacebookShare.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class TierPerkTranslation(
    /** The ID of teh language. */
    @SerialName("language_id")
    val languageId: Int?,

    /** The name of this tier level as translated. */
    @SerialName("title")
    val title: String?,

    /** The language for which this translation applies. */
    @SerialName("language")
    val language: Language?
)
