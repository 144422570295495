/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.makeGiftCardPurchase.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class GiftCardPayPalPaymentResponseData(
    @SerialName("id")
    val id: String?,

    @SerialName("provider")
    val provider: String?,

    @SerialName("giftcard")
    val giftcard: GiftCardPurchaseResponseGiftCardData?,

    @SerialName("balance")
    val balance: UserBalance?
)
