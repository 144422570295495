/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userTransferMessages.internal

import features.userTransferMessages.models.TransferMessagesModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userTransferMessages.internal.TransferMessagesUseCase.Params

internal class TransferMessagesUseCase : AsyncUseCase<TransferMessagesModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val transferMessagesService: TransferMessagesService = TransferMessagesServiceImpl()

    override suspend fun run(params: Params): Result<TransferMessagesModel> {
        return transferMessagesService.getTransferMessages(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
