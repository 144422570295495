/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userRegistrationWithCrm

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userRegistrationWithCrm.internal.UserRegistrationWithCrmUseCase
import features.userRegistrationWithCrm.internal.UserRegistrationWithCrmUseCase.Params
import features.userRegistrationWithCrm.models.UserProfileDataModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.userRegistrationWithCrm.models.CrmRegisterRequest

typealias UserRegistrationWithCrmState = CFlow<UserRegistrationWithCrmUseCaseResult?>

typealias UserRegistrationWithCrmUseCaseResult = Result<UserProfileDataModel>

fun UserRegistrationWithCrmUseCaseResult.serializeUserRegistrationWithCrmApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProfileDataModel>()
}

/**
 * Serializes [UserRegistrationWithCrmState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserRegistrationWithCrmState.serializeUserRegistrationWithCrmState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProfileDataModel>()
    }.asCFlow()
}

/**
 * Register a new user using the crm settings. [Api] class
 */
class UserRegistrationWithCrmApi : Api() {

    val userRegistrationWithCrmState: UserRegistrationWithCrmState
        get() = _userRegistrationWithCrmState.asCFlow()

    companion object {
        private val _userRegistrationWithCrmState: MutableStateFlow<UserRegistrationWithCrmUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userRegistrationWithCrmUseCase = UserRegistrationWithCrmUseCase()

    /**
     * Register a new user using the crm settings.
     */
    suspend fun createAccountWithCrm(
        overrideHeaders: Map<String, String>? = null,
        registerWithCrmRequest: CrmRegisterRequest
    ): Result<UserProfileDataModel> {
        /* set state to Loading as soon as function is called */
        _userRegistrationWithCrmState.value = Loading()
        val result = this.userRegistrationWithCrmUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                registerWithCrmRequest = registerWithCrmRequest
            )
        ) { result ->
            _userRegistrationWithCrmState.value = result
            return@userRegistrationWithCrmUseCase result
        }
        return result
    }

    /**
    * Register a new user using the crm settings.
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun createAccountWithCrm(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<UserProfileDataModel> {
        return createAccountWithCrm(
            overrideHeaders = overrideHeaders,
            registerWithCrmRequest = Json.decodeFromString(methods.getValue("registerWithCrmRequest") as String)
        )
    }
}
