/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.frequentBuyerPrograms.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class FrequentBuyerProgramModel(
    @SerialName("id")
    val id: Int?,

    @SerialName("enabled")
    val enabled: Boolean?,

    @SerialName("title")
    val title: String?,

    @SerialName("buy_units")
    val buyUnits: Int?,

    @SerialName("get_units")
    val getUnits: Int?,

    @SerialName("reset_interval")
    val resetInterval: Int?,

    @SerialName("created_at")
    val createdAt: String?
) : Model()
