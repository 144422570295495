/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userScanReceipt.internal

import features.userScanReceipt.models.ScanReceiptRequest
import features.userScanReceipt.models.ScanReceiptResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserScanReceiptServiceImpl : UserScanReceiptService {

    private val userScanReceiptRequest = UserScanReceiptRequest()

    override suspend fun scanReceipt(
        overrideHeaders : Map<String, String>?,
        scanReceiptRequest: ScanReceiptRequest    ): Result<ScanReceiptResponseModel> {
        return userScanReceiptRequest.scanReceipt(
            overrideHeaders = overrideHeaders,
            scanReceiptRequest = scanReceiptRequest        ).toModel()
    }
}
