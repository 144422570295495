/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userPinResetRequest.internal

import features.userPinResetRequest.models.UserPinResetRequest
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserPinResetRequestServiceImpl : UserPinResetRequestService {

    private val userPinResetRequestRequest = UserPinResetRequestRequest()

    override suspend fun requestPinReset(
        overrideHeaders : Map<String, String>?,
        mode: String,
        email: String?,
        phone: String?,
        countryCode: String?    ): Result<UserPinResetRequest> {
        return userPinResetRequestRequest.requestPinReset(
            overrideHeaders = overrideHeaders,
            mode = mode,
            email = email,
            phone = phone,
            countryCode = countryCode        ).toModel()
    }
}
