/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userRegistration.internal

import features.userRegistration.models.UserProfileDataModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserRegistrationServiceImpl : UserRegistrationService {

    private val userRegistrationRequest = UserRegistrationRequest()

    override suspend fun createAccount(
        overrideHeaders : Map<String, String>?,
        email: String?,
        phone: String?,
        countryCode: String?,
        language: String?    ): Result<UserProfileDataModel> {
        return userRegistrationRequest.createAccount(
            overrideHeaders = overrideHeaders,
            email = email,
            phone = phone,
            countryCode = countryCode,
            language = language        ).toModel()
    }
}
