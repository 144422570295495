/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userConglomerateNotificationPreferences.internal

import features.userConglomerateNotificationPreferences.models.NotificationSettingsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userConglomerateNotificationPreferences.internal.GetConglomerateNotificationPreferencesUseCase.Params

internal class GetConglomerateNotificationPreferencesUseCase : AsyncUseCase<NotificationSettingsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val getConglomerateNotificationPreferencesService: GetConglomerateNotificationPreferencesService = GetConglomerateNotificationPreferencesServiceImpl()

    override suspend fun run(params: Params): Result<NotificationSettingsModel> {
        return getConglomerateNotificationPreferencesService.getConglomerateNotificationPreferences(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
