/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userTransferMessages

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userTransferMessages.internal.TransferMessagesUseCase
import features.userTransferMessages.internal.TransferMessagesUseCase.Params
import features.userTransferMessages.models.TransferMessagesModel


typealias TransferMessagesState = CFlow<TransferMessagesUseCaseResult?>

typealias TransferMessagesUseCaseResult = Result<TransferMessagesModel>

fun TransferMessagesUseCaseResult.serializeTransferMessagesApiResult(): SerializedResult<String> {
    return this.toJsonResult<TransferMessagesModel>()
}

/**
 * Serializes [TransferMessagesState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun TransferMessagesState.serializeTransferMessagesState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<TransferMessagesModel>()
    }.asCFlow()
}

/**
 * Get this users transfer messages. Transfer messages are points or e-wallet transfers from other users.  [Api] class
 */
class TransferMessagesApi : Api() {

    val transferMessagesState: TransferMessagesState
        get() = _transferMessagesState.asCFlow()

    companion object {
        private val _transferMessagesState: MutableStateFlow<TransferMessagesUseCaseResult> = MutableStateFlow(Idle())
    }

    private val transferMessagesUseCase = TransferMessagesUseCase()

    /**
     * Get this users transfer messages. Transfer messages are points or e-wallet transfers from other users. 
     */
    suspend fun getTransferMessages(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<TransferMessagesModel> {
        /* set state to Loading as soon as function is called */
        _transferMessagesState.value = Loading()
        val result = this.transferMessagesUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _transferMessagesState.value = result
            return@transferMessagesUseCase result
        }
        return result
    }

}
