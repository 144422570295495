/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.business.internal

import features.business.models.Business
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class BusinessRequest : NetworkRequest() {

    suspend fun getBusiness(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): IOResult<Business> {
        return safeNetworkCall {
            get(
                endpoint = BusinessEndpoint.replaceInPath(
                    PathParameter("businessId", businessId),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
