/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.getPublicCampaign.internal

import features.getPublicCampaign.models.PublicCampaignsResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GetPublicCampaignServiceImpl : GetPublicCampaignService {

    private val getPublicCampaignRequest = GetPublicCampaignRequest()

    override suspend fun getPublicCampaign(
        overrideHeaders : Map<String, String>?,
        campaignId: String    ): Result<PublicCampaignsResponseModel> {
        return getPublicCampaignRequest.getPublicCampaign(
            overrideHeaders = overrideHeaders,
            campaignId = campaignId        ).toModel()
    }
}
