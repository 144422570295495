/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.updateDefaultBusinessId.internal

import features.updateDefaultBusinessId.models.DefaultBusiness
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class UpdateDefaultBusinessIdRequest : NetworkRequest() {

    suspend fun updateDefaultBusinessId(
        overrideHeaders: Map<String, String>? = null,
        businessId: String,
        defaultBusinessId: String
    ): IOResult<DefaultBusiness> {
        return safeNetworkCall {
            patch<DefaultBusiness, Any>(
                endpoint = UpdateDefaultBusinessIdEndpoint.replaceInPath(
                    PathParameter("businessId", businessId),
                ),
                fields = listOf(
                    FormField(name = "default_business_id", value = defaultBusinessId)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
