/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userUnsubscribe.internal

import kangaroorewards.appsdk.core.io.EmptyResponse
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userUnsubscribe.internal.UserUnsubscribeUseCase.Params

internal class UserUnsubscribeUseCase : AsyncUseCase<EmptyResponse, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val campaignId: String,
        val token: String
    )

    private val userUnsubscribeService: UserUnsubscribeService = UserUnsubscribeServiceImpl()

    override suspend fun run(params: Params): Result<EmptyResponse> {
        return userUnsubscribeService.unsubscribe(
            overrideHeaders = params.overrideHeaders,
            campaignId = params.campaignId,
            token = params.token
        )
    }
}
