/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.tiers.internal

import features.tiers.models.UserProfileWithTierProgram
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.tiers.internal.TiersUseCase.Params

internal class TiersUseCase : AsyncUseCase<UserProfileWithTierProgram, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val tiersService: TiersService = TiersServiceImpl()

    override suspend fun run(params: Params): Result<UserProfileWithTierProgram> {
        return tiersService.getTiers(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
