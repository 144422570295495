/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userVerification.internal

import features.userVerification.models.UserVerificationResponseModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField

internal class UserAccountVerificationRequest : NetworkRequest() {

    suspend fun verifyAccount(
        overrideHeaders: Map<String, String>? = null,
        token: String,
        email: String?,
        phone: String?,
        countryCode: String?
    ): IOResult<UserVerificationResponseModel> {
        return safeNetworkCall {
            post<UserVerificationResponseModel, Any>(
                endpoint = UserAccountVerificationEndpoint,
                fields = listOf(
                    FormField(name = "token", value = token),
                    FormField(name = "email", value = email),
                    FormField(name = "phone", value = phone),
                    FormField(name = "country_code", value = countryCode)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
