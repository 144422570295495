package kangaroorewards.appsdk.core.api

import kangaroorewards.appsdk.core.KangarooSdkNotInitializedException
import kangaroorewards.appsdk.core.SdkContext
import kotlin.js.JsExport

/**
 * Base class for all Api classes in the SDK. Api classes are the
 * gateway to all functionality in the sdk, and will throw an error
 * if they are accessed before the sdk has been initialized.
 */
abstract class Api() {
    init {
        if (!SdkContext.sdkInit) throw KangarooSdkNotInitializedException()
    }
}