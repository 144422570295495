/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userRegistrationWithCrm.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class UserConsent(
    @SerialName("allow_sms")
    val allowSms: Boolean,

    @SerialName("allow_email")
    val allowEmail: Boolean,

    @SerialName("allow_push")
    val allowPush: Boolean
)
