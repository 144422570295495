/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userCampaignMessages.internal

import features.userCampaignMessages.models.CampaignMessagesModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userCampaignMessages.internal.CampaignMessagesUseCase.Params

internal class CampaignMessagesUseCase : AsyncUseCase<CampaignMessagesModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val campaignMessagesService: CampaignMessagesService = CampaignMessagesServiceImpl()

    override suspend fun run(params: Params): Result<CampaignMessagesModel> {
        return campaignMessagesService.getCampaignMessages(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
