/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessTiers.internal

import features.userBusinessTiers.models.UserProfileWithTierProgram
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessTiersServiceImpl : UserBusinessTiersService {

    private val userBusinessTiersRequest = UserBusinessTiersRequest()

    override suspend fun getUserBusinessTiers(
        overrideHeaders : Map<String, String>?,
        memberBusinessId: String    ): Result<UserProfileWithTierProgram> {
        return userBusinessTiersRequest.getUserBusinessTiers(
            overrideHeaders = overrideHeaders,
            memberBusinessId = memberBusinessId        ).toModel()
    }
}
