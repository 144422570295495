/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.draws.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class RewardTranslation(
    @SerialName("id")
    val id: Int?,

    @SerialName("language_id")
    val languageId: Int?,

    @SerialName("title")
    val title: String?,

    @SerialName("description")
    val description: String?,

    @SerialName("terms_conditions")
    val termsConditions: String?,

    @SerialName("link")
    val link: String?,

    @SerialName("language")
    val language: Language?,

    @SerialName("note")
    val note: String?
)
