/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.getPublicCampaign.internal

import features.getPublicCampaign.models.PublicCampaignsResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.getPublicCampaign.internal.GetPublicCampaignUseCase.Params

internal class GetPublicCampaignUseCase : AsyncUseCase<PublicCampaignsResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val campaignId: String
    )

    private val getPublicCampaignService: GetPublicCampaignService = GetPublicCampaignServiceImpl()

    override suspend fun run(params: Params): Result<PublicCampaignsResponseModel> {
        return getPublicCampaignService.getPublicCampaign(
            overrideHeaders = params.overrideHeaders,
            campaignId = params.campaignId
        )
    }
}
