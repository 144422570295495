/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.makeGiftCardPurchase.internal

import features.makeGiftCardPurchase.models.GiftCardPayPalPaymentResponseModel
import features.makeGiftCardPurchase.models.GiftCardPurchaseRequest
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GiftCardPurchaseServiceImpl : GiftCardPurchaseService {

    private val giftCardPurchaseRequest = GiftCardPurchaseRequest()

    override suspend fun purchaseGiftCard(
        overrideHeaders : Map<String, String>?,
        purchaseGiftCardRequest: GiftCardPurchaseRequest    ): Result<GiftCardPayPalPaymentResponseModel> {
        return giftCardPurchaseRequest.purchaseGiftCard(
            overrideHeaders = overrideHeaders,
            purchaseGiftCardRequest = purchaseGiftCardRequest        ).toModel()
    }
}
