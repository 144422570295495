/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userUnsubscribe

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userUnsubscribe.UserUnsubscribeApi

import features.userUnsubscribe.serializeUserUnsubscribeState
import kangaroorewards.appsdk.core.io.EmptyResponse



/**
 * Unsubscribe from marketing material  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UserUnsubscribeApi")
class UserUnsubscribeApiJs {

    /**
     * Unsubscribe from marketing material 
     */
    fun unsubscribe(
        overrideHeaders: String?, 
        campaignId: String,
        token: String
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = UserUnsubscribeApi().unsubscribe(
            overrideHeaders = decodedOverrideHeaders,
                        campaignId = campaignId,
            token = token
        ).toJsonResult<EmptyResponse>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeUserUnsubscribeState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserUnsubscribeApi().userUnsubscribeState.serializeUserUnsubscribeState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.EmptyResponse -> onData(Json.encodeToString(EmptyResponse.serializer(), EmptyResponse))
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
