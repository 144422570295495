/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userBusinessGiftCards.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class UserBusinessGiftCardsModel(
    /** Contains business data */
    @SerialName("data")
    val data: BusinessData,

    /** Contains all gift cards items */
    @SerialName("included")
    val included: UserBusinessGiftCardsIncluded?
) : Model()
