/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicBanners.internal

import features.publicBanners.models.BannersModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.publicBanners.internal.PublicBannersUseCase.Params

internal class PublicBannersUseCase : AsyncUseCase<BannersModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val publicBannersService: PublicBannersService = PublicBannersServiceImpl()

    override suspend fun run(params: Params): Result<BannersModel> {
        return publicBannersService.getPublicBanners(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
