/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessRewards.internal

import features.userBusinessRewards.models.UserBusinessRewardsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessRewardsServiceImpl : UserBusinessRewardsService {

    private val userBusinessRewardsRequest = UserBusinessRewardsRequest()

    override suspend fun getUserBusinessRewards(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<UserBusinessRewardsModel> {
        return userBusinessRewardsRequest.getUserBusinessRewards(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
