/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userCheckIn

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userCheckIn.UserCheckInApi
import features.userCheckIn.models.CheckInResponseModel
import features.userCheckIn.serializeUserCheckInState
import features.userCheckIn.models.CheckInRequest

import kotlinx.serialization.decodeFromString

/**
 * Check in with a business. The user may be rewarded for this action according to rules set in the business portal on Kangaroo.  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UserCheckInApi")
class UserCheckInApiJs {

    /**
     * Check in with a business. The user may be rewarded for this action according to rules set in the business portal on Kangaroo. 
     */


    
    fun userCheckIn(overrideHeaders: String?,
serializedCheckInRequest: String): Promise<String?>  {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

        return GlobalScope.promise {
            val result = UserCheckInApi().userCheckIn(
            overrideHeaders = decodedOverrideHeaders,
                checkInRequest = Json.decodeFromString(
                    serializedCheckInRequest
                )
            ).toJsonResult<CheckInResponseModel>()

           return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    fun observeUserCheckInState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserCheckInApi().userCheckInState.serializeUserCheckInState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
