/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userBalanceTransfer.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class TransferRequestModel(
    /** The transfer intent for this transfer. This can be transfer_amount or transfer_points  */
    @SerialName("intent")
    val intent: String,

    @SerialName("business_id")
    val businessId: String,

    /** The two character language code of the language that this transfer should be sent in.  */
    @SerialName("language")
    val language: String,

    @SerialName("from")
    val from: TransferRequestFrom,

    @SerialName("to")
    val to: TransferRequestTo,

    @SerialName("points")
    val points: Int?,

    @SerialName("amount")
    val amount: Double?,

    @SerialName("giftcard_id")
    val giftcardId: Int?
) : Model()
