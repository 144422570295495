/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userPinResetRequest.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * User PIN reset request status
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class UserPinResetRequest(
    /** The response code for the pin reset request. Anything within the 200 range indicates a success  */
    @SerialName("status_code")
    val statusCode: Int,

    /** The message and status returned by the pin reset request */
    @SerialName("message")
    val message: String,

    /** A description of the pin reset request */
    @SerialName("description")
    val description: String
) : Model()
