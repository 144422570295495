/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.publicRewards

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.publicRewards.internal.PublicRewardsUseCase
import features.publicRewards.internal.PublicRewardsUseCase.Params
import features.publicRewards.models.PublicRewardsModel


typealias PublicRewardsState = CFlow<PublicRewardsUseCaseResult?>

typealias PublicRewardsUseCaseResult = Result<PublicRewardsModel>

fun PublicRewardsUseCaseResult.serializePublicRewardsApiResult(): SerializedResult<String> {
    return this.toJsonResult<PublicRewardsModel>()
}

/**
 * Serializes [PublicRewardsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun PublicRewardsState.serializePublicRewardsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PublicRewardsModel>()
    }.asCFlow()
}

/**
 * Get a list of rewards available to all users. [Api] class
 */
class PublicRewardsApi : Api() {

    val publicRewardsState: PublicRewardsState
        get() = _publicRewardsState.asCFlow()

    companion object {
        private val _publicRewardsState: MutableStateFlow<PublicRewardsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val publicRewardsUseCase = PublicRewardsUseCase()

    /**
     * Get a list of rewards available to all users.
     */
    suspend fun getPublicRewards(
        overrideHeaders: Map<String, String>? = null,

    ): Result<PublicRewardsModel> {
        /* set state to Loading as soon as function is called */
        _publicRewardsState.value = Loading()
        val result = this.publicRewardsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _publicRewardsState.value = result
            return@publicRewardsUseCase result
        }
        return result
    }

}
