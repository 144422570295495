/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userBusinessProfile.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents a single tier level.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class TierLevel(
    /** The ID for this tier. */
    @SerialName("id")
    val id: Int?,

    /** The name of this tier level. */
    @SerialName("name")
    val name: String?,

    /** The amount of money spent required to reach this tier. */
    @SerialName("reach_spend")
    val reachSpend: Float?,

    /** The amount of visits made to reach this tier. */
    @SerialName("reach_visits")
    val reachVisits: Int?,

    /** The amount of points acquired that is required to reach this tier. */
    @SerialName("reach_points")
    val reachPoints: Int?,

    /** The icon of this tier level. */
    @SerialName("icon")
    val icon: String?,

    /** The perks related to this tier level. */
    @SerialName("tier_perks")
    val tierPerks: List<TierPerk>?,

    /** translation for each tier level. */
    @SerialName("languages")
    val languages: List<TierLevelTranslation>?
)
