/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userRewards.internal

import features.userRewards.models.UserRewardsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserRewardsServiceImpl : UserRewardsService {

    private val userRewardsRequest = UserRewardsRequest()

    override suspend fun getUserRewards(
        overrideHeaders : Map<String, String>?,
    ): Result<UserRewardsModel> {
        return userRewardsRequest.getUserRewards(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
