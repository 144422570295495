/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userCheckIn.internal

import features.userCheckIn.models.CheckInRequest
import features.userCheckIn.models.CheckInResponseModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class UserCheckInRequest : NetworkRequest() {

    suspend fun userCheckIn(
        overrideHeaders: Map<String, String>? = null,
        checkInRequest: CheckInRequest
    ): IOResult<CheckInResponseModel> {
        return safeNetworkCall {
            post<CheckInResponseModel, CheckInRequest>(
                endpoint = UserCheckInEndpoint,
                requestBody = checkInRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
