/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.draws.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A draw prize.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class DrawPrize(
    /** Unique draw prize id. */
    @SerialName("id")
    val id: Int?,

    /** The draw prize title. */
    @SerialName("title")
    val title: String?,

    /** The draw prize description. */
    @SerialName("description")
    val description: String?,

    /** Unique draw id. */
    @SerialName("draw_id")
    val drawId: Int?,

    @SerialName("offer")
    val offer: OfferModel?,

    @SerialName("catalog_item")
    val catalogItem: RewardModel?,

    /** If this draw prize is enabled or not. */
    @SerialName("enabled")
    val enabled: Boolean?,

    /** If this draw prize is expired or not. */
    @SerialName("is_expired")
    val isExpired: Boolean?,

    @SerialName("languages")
    val languages: List<Languages>?
)
