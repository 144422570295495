/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessGiftCards.internal

import features.userBusinessGiftCards.models.UserBusinessGiftCardsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userBusinessGiftCards.internal.UserBusinessGiftCardsUseCase.Params

internal class UserBusinessGiftCardsUseCase : AsyncUseCase<UserBusinessGiftCardsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val userBusinessGiftCardsService: UserBusinessGiftCardsService = UserBusinessGiftCardsServiceImpl()

    override suspend fun run(params: Params): Result<UserBusinessGiftCardsModel> {
        return userBusinessGiftCardsService.getUserBusinessGiftCards(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
