/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userVerification

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userVerification.internal.UserAccountVerificationUseCase
import features.userVerification.internal.UserAccountVerificationUseCase.Params
import features.userVerification.models.UserVerificationResponseModel


typealias UserAccountVerificationState = CFlow<UserAccountVerificationUseCaseResult?>

typealias UserAccountVerificationUseCaseResult = Result<UserVerificationResponseModel>

fun UserAccountVerificationUseCaseResult.serializeUserAccountVerificationApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserVerificationResponseModel>()
}

/**
 * Serializes [UserAccountVerificationState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserAccountVerificationState.serializeUserAccountVerificationState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserVerificationResponseModel>()
    }.asCFlow()
}

/**
 * Verify a user's account  [Api] class
 */
class UserAccountVerificationApi : Api() {

    val userAccountVerificationState: UserAccountVerificationState
        get() = _userAccountVerificationState.asCFlow()

    companion object {
        private val _userAccountVerificationState: MutableStateFlow<UserAccountVerificationUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userAccountVerificationUseCase = UserAccountVerificationUseCase()

    /**
     * Verify a user's account 
     */
    suspend fun verifyAccount(
        overrideHeaders: Map<String, String>? = null,
        token: String,
        email: String? = null,
        phone: String? = null,
        countryCode: String? = null
    ): Result<UserVerificationResponseModel> {
        /* set state to Loading as soon as function is called */
        _userAccountVerificationState.value = Loading()
        val result = this.userAccountVerificationUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                token = token,
                email = email,
                phone = phone,
                countryCode = countryCode
            )
        ) { result ->
            _userAccountVerificationState.value = result
            return@userAccountVerificationUseCase result
        }
        return result
    }

}
