/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.postOfferFacebookShare.internal

import features.postOfferFacebookShare.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.postOfferFacebookShare.internal.OfferFacebookShareUseCase.Params

internal class OfferFacebookShareUseCase : AsyncUseCase<UserProfileModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val offerId: String,
        val include: String,
        val facebookUserId: String,
        val type: String,
        val friendsCount: String
    )

    private val offerFacebookShareService: OfferFacebookShareService = OfferFacebookShareServiceImpl()

    override suspend fun run(params: Params): Result<UserProfileModel> {
        return offerFacebookShareService.postOfferFacebookShare(
            overrideHeaders = params.overrideHeaders,
            offerId = params.offerId,
            include = params.include,
            facebookUserId = params.facebookUserId,
            type = params.type,
            friendsCount = params.friendsCount
        )
    }
}
