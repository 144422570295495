/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessUsedCoupons.internal

import features.userBusinessUsedCoupons.models.UserBusinessCouponsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessUsedCouponsServiceImpl : UserBusinessUsedCouponsService {

    private val userBusinessUsedCouponsRequest = UserBusinessUsedCouponsRequest()

    override suspend fun getUserBusinessUsedCoupons(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<UserBusinessCouponsModel> {
        return userBusinessUsedCouponsRequest.getUserBusinessUsedCoupons(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
