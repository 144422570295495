/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userProfileUpdate.internal

import features.userProfileUpdate.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserProfileUpdateServiceImpl : UserProfileUpdateService {

    private val userProfileUpdateRequest = UserProfileUpdateRequest()

    override suspend fun updateUserProfile(
        overrideHeaders : Map<String, String>?,
        firstName: String?,
        lastName: String?,
        birthDate: String?,
        language: String?,
        gender: String?,
        profilePhoto: String?    ): Result<UserProfileModel> {
        return userProfileUpdateRequest.updateUserProfile(
            overrideHeaders = overrideHeaders,
            firstName = firstName,
            lastName = lastName,
            birthDate = birthDate,
            language = language,
            gender = gender,
            profilePhoto = profilePhoto        ).toModel()
    }
}
