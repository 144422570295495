/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userCampaignMessages.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * The core campaign object for this campaign. This might include data such as the original html content of the campaign, sms or push data, or offers and rewards attached to this campaign. 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class Campaign(
    @SerialName("id")
    val id: Int?,

    @SerialName("subject")
    val subject: String?,

    @SerialName("html_content")
    val htmlContent: String?,

    @SerialName("plain_content")
    val plainContent: String?,

    @SerialName("push_title")
    val pushTitle: String?,

    @SerialName("push_body")
    val pushBody: String?,

    @SerialName("push_link")
    val pushLink: String?,

    @SerialName("push_image")
    val pushImage: String?,

    @SerialName("offers")
    val offers: List<OfferModel>?,

    @SerialName("business")
    val business: BusinessData?
)
