/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userRegistrationWithCrm.internal

import features.userRegistrationWithCrm.models.CrmRegisterRequest
import features.userRegistrationWithCrm.models.UserProfileDataModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserRegistrationWithCrmServiceImpl : UserRegistrationWithCrmService {

    private val userRegistrationWithCrmRequest = UserRegistrationWithCrmRequest()

    override suspend fun createAccountWithCrm(
        overrideHeaders : Map<String, String>?,
        registerWithCrmRequest: CrmRegisterRequest    ): Result<UserProfileDataModel> {
        return userRegistrationWithCrmRequest.createAccountWithCrm(
            overrideHeaders = overrideHeaders,
            registerWithCrmRequest = registerWithCrmRequest        ).toModel()
    }
}
