/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.publicOffer.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A language translation of this offer.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class OfferTranslation(
    @SerialName("id")
    val id: Int,

    /** The ID of this specific translation */
    @SerialName("language_id")
    val languageId: Int?,

    /** The translated offer title. */
    @SerialName("offer_title")
    val offerTitle: String?,

    /** The translated offer description. */
    @SerialName("offer_description")
    val offerDescription: String?,

    /** The translated offer terms and conditions. */
    @SerialName("offer_terms_conditions")
    val offerTermsConditions: String?,

    /** The language appropriate link of this offer. */
    @SerialName("offer_link")
    val offerLink: String?,

    /** The translated coupon title. */
    @SerialName("coupon_title")
    val couponTitle: String?,

    /** The translated coupon description. */
    @SerialName("coupon_description")
    val couponDescription: String?,

    /** The translated coupon terms and conditions. */
    @SerialName("coupon_terms_conditions")
    val couponTermsConditions: String?,

    /** The language appropriate link of this coupon. */
    @SerialName("coupon_link")
    val couponLink: String?,

    /** where is this coupon available at. */
    @SerialName("coupon_available_at")
    val couponAvailableAt: String?,

    /** The language for which this translation applies. */
    @SerialName("language")
    val language: Language?
)
