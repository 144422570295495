package kangaroorewards.appsdk.core.cache

import kotlinx.browser.localStorage
import org.w3c.dom.get
import org.w3c.dom.set

internal actual class EncryptedKeyValueStore {
    /**
     * Gets a value from the encrypted cache using the given key
     *
     * @param T can only be of types String, Boolean, Float, Int or Long
     * @throws IllegalArgumentException
     */
    actual inline fun <reified T> get(key: String): T {
        val storage = localStorage

        return when (T::class) {
            String::class -> storage[key].toString() as T ?: "" as T
            Boolean::class -> storage[key].toBoolean() as T ?: false as T
            Float::class -> storage[key]?.toFloat() as T ?: -1f as T
            Int::class -> storage[key]?.toInt() as T ?: -1 as T
            Long::class -> storage[key]?.toLong() as T ?: -1L as T
            else -> throw IllegalArgumentException()
        }
    }

    /**
     * Puts a value in the encrypted cache using the given key
     *
     * @param T can only be of types String, Boolean, Float, Int or Long
     * @throws IllegalArgumentException
     */
    actual inline fun <reified T> put(key: String, value: T) {
        val storage = localStorage
        storage[key] = value.toString()
    }

    /**
     * Removes a value from the encrypted cache.
     */
    actual inline fun remove(key: String) {
        val storage = localStorage
        println("attempting to remove from web storage: $key")
        storage.removeItem(key)
    }

}