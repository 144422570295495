/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.countries.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A list of countries supported by Kangaroo
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class Countries(
    /** A list of countries supported by Kangaroo */
    @SerialName("data")
    val data: List<Country>?
) : Model()
