/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userRegistrationWithCrm.internal

import features.userRegistrationWithCrm.models.CrmRegisterRequest
import features.userRegistrationWithCrm.models.UserProfileDataModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class UserRegistrationWithCrmRequest : NetworkRequest() {

    suspend fun createAccountWithCrm(
        overrideHeaders: Map<String, String>? = null,
        registerWithCrmRequest: CrmRegisterRequest
    ): IOResult<UserProfileDataModel> {
        return safeNetworkCall {
            post<UserProfileDataModel, CrmRegisterRequest>(
                endpoint = UserRegistrationWithCrmEndpoint,
                requestBody = registerWithCrmRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
