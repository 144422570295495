/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userUsedCoupons.internal

import features.userUsedCoupons.models.UserCouponsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserUsedCouponsServiceImpl : UserUsedCouponsService {

    private val userUsedCouponsRequest = UserUsedCouponsRequest()

    override suspend fun getUserUsedCoupons(
        overrideHeaders : Map<String, String>?,
    ): Result<UserCouponsModel> {
        return userUsedCouponsRequest.getUserUsedCoupons(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
