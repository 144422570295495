@file:Suppress("VARIABLE_IN_SINGLETON_WITHOUT_THREAD_LOCAL")
@file:OptIn(ExperimentalJsExport::class)

package kangaroorewards.appsdk.core

import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import kangaroorewards.appsdk.core.io.Model
import kangaroorewards.appsdk.core.network.base.KHttpClient
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

expect class KangarooSdk {

    /**
     * Initializes the sdk. Accessing the sdk prior to running this command
     * will throw an exception
     */
    fun initialize(
        applicationKey: String,
        clientId: String,
        clientSecret: String,
        environment: String = "production",
        baseURL: String
    )

    fun setPreferredLanguage(iso639LanguageCode: String)

    fun getPreferredLanguage(): String?

    /**
     * Gets the access token. If null or empty, the session has expired.
     */
    fun getSession(): String?

    /**
     * Kills the session by removing authorization tokens
     */
    fun killSession()
}

object SdkContext {
    var sdkInit: Boolean = false

    var tokenStore: TokenStore? = null

    fun storeTokens(tokens: AuthenticationModel) {
        tokenStore?.storeTokens(tokens)
        KHttpClient.defaultClient.plugin(Auth).providers
            .filterIsInstance<BearerAuthProvider>()
            .first().clearToken()
    }

    var applicationKey: String? = null
    var clientId: String? = null
    var clientSecret: String? = null
    var environment: String? = null
    var baseURL: String? = null
}

class KangarooSdkNotInitializedException : Exception() {

    override val message =
        "The Kangaroo sdk hasn't been initialized. Be sure to run KangarooSdk.initialize() before accessing any API classes"
}

enum class AuthenticationScope {Customer, Business}

expect class TokenStore {
    fun storeTokens(tokens: AuthenticationModel)
    fun getTokens(): AuthenticationModel?
    fun getPreferredLanguage(): String?
}

@ExperimentalJsExport
@Serializable
@JsExport
data class AuthenticationModel(
    val authScope: String,
    val tokenType: String? = "Bearer",
    val expiresIn: Int? = 0,
    val accessToken: String?,
    val refreshToken: String? = "",
) : Model() {
    companion object {
        const val ACCESS_TOKEN = "access_token"
        const val REFRESH_TOKEN = "refresh_token"
        const val AUTH_SCOPE = "auth_scope"
    }
}