/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userTransferRecall.internal

import kangaroorewards.appsdk.core.network.base.Endpoint

/**
 * The HTTP endpoint for the [TransferRecallApi].
 *
 * [Endpoint] has a single [Endpoint.path] parameter ex. "users/me"
 */
internal val TransferRecallEndpoint = Endpoint(path = "users/me/messages/{recallId}/?intent=recall_giftcard?relationships=sender,receiver,gift_card_queue,offer")
