/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.publicProducts.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class Language(
    /** The Kangaroo ID of this language. */
    @SerialName("id")
    val id: Int,

    /** A two letter abbreviation of this language. Follows ISO standards (en, fr, es)  */
    @SerialName("abbreviation")
    val abbreviation: String?,

    /** The full name of this language (English, French, Spanish) */
    @SerialName("name")
    val name: String?
)
