@file:OptIn(ExperimentalJsExport::class)

package features.userAuthentication.internal

import co.touchlab.kermit.Logger
import features.userAuthentication.models.UserAuthenticationModel
import features.userAuthentication.models.toAuthenticationModel
import kangaroorewards.appsdk.core.SdkContext
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Success
import kangaroorewards.appsdk.core.io.toModel
import kangaroorewards.appsdk.core.utils.doNothing
import kotlin.js.ExperimentalJsExport

internal class UserAuthenticationServiceImpl : UserAuthenticationService {

    private val userAuthenticationRequest = UserAuthenticationRequest()

    override suspend fun authenticateUser(
        username: String?,
        password: String?,
        googleToken: String?,
        overrideHeaders: Map<String, String>?,
    ): Result<UserAuthenticationModel> {
        val result = userAuthenticationRequest.authorizeUser(
            username = username,
            password = password,
            googleToken = googleToken,
            overrideHeaders = overrideHeaders,
        ).toModel()
        when (result) {
            is Success -> {
              try {
                    SdkContext.storeTokens(
                        result.data.toAuthenticationModel()
                    )
                } catch (e: Exception) {
                    Logger.e { e.message ?: "Failed to store authentication tokens" }
                }
            }
            else -> doNothing
        }
        return result
    }
}