/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.banners

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.banners.internal.BannersUseCase
import features.banners.internal.BannersUseCase.Params
import features.banners.models.BannersModel


typealias BannersState = CFlow<BannersUseCaseResult?>

typealias BannersUseCaseResult = Result<BannersModel>

fun BannersUseCaseResult.serializeBannersApiResult(): SerializedResult<String> {
    return this.toJsonResult<BannersModel>()
}

/**
 * Serializes [BannersState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun BannersState.serializeBannersState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<BannersModel>()
    }.asCFlow()
}

/**
 * Acquires business banners. [Api] class
 */
class BannersApi : Api() {

    val bannersState: BannersState
        get() = _bannersState.asCFlow()

    companion object {
        private val _bannersState: MutableStateFlow<BannersUseCaseResult> = MutableStateFlow(Idle())
    }

    private val bannersUseCase = BannersUseCase()

    /**
     * Acquires business banners.
     */
    suspend fun getBanners(
        overrideHeaders: Map<String, String>? = null,

    ): Result<BannersModel> {
        /* set state to Loading as soon as function is called */
        _bannersState.value = Loading()
        val result = this.bannersUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _bannersState.value = result
            return@bannersUseCase result
        }
        return result
    }

}
