/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userConglomerateNotificationPreferences.internal

import features.userConglomerateNotificationPreferences.models.NotificationSettingsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GetConglomerateNotificationPreferencesServiceImpl : GetConglomerateNotificationPreferencesService {

    private val getConglomerateNotificationPreferencesRequest = GetConglomerateNotificationPreferencesRequest()

    override suspend fun getConglomerateNotificationPreferences(
        overrideHeaders : Map<String, String>?,
    ): Result<NotificationSettingsModel> {
        return getConglomerateNotificationPreferencesRequest.getConglomerateNotificationPreferences(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
