/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userPinUpdate.internal

import features.userPinUpdate.models.UpdatePinRequest
import features.userPinUpdate.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserPinUpdateServiceImpl : UserPinUpdateService {

    private val userPinUpdateRequest = UserPinUpdateRequest()

    override suspend fun updatePin(
        overrideHeaders : Map<String, String>?,
        updatePinRequest: UpdatePinRequest    ): Result<UserProfileModel> {
        return userPinUpdateRequest.updatePin(
            overrideHeaders = overrideHeaders,
            updatePinRequest = updatePinRequest        ).toModel()
    }
}
