/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBusinessCoupons

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userBusinessCoupons.internal.UserBusinessCouponsUseCase
import features.userBusinessCoupons.internal.UserBusinessCouponsUseCase.Params
import features.userBusinessCoupons.models.UserBusinessCouponsModel


typealias UserBusinessCouponsState = CFlow<UserBusinessCouponsUseCaseResult?>

typealias UserBusinessCouponsUseCaseResult = Result<UserBusinessCouponsModel>

fun UserBusinessCouponsUseCaseResult.serializeUserBusinessCouponsApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserBusinessCouponsModel>()
}

/**
 * Serializes [UserBusinessCouponsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserBusinessCouponsState.serializeUserBusinessCouponsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserBusinessCouponsModel>()
    }.asCFlow()
}

/**
 * Get a list of available coupons for this user for a specific business. [Api] class
 */
class UserBusinessCouponsApi : Api() {

    val userBusinessCouponsState: UserBusinessCouponsState
        get() = _userBusinessCouponsState.asCFlow()

    companion object {
        private val _userBusinessCouponsState: MutableStateFlow<UserBusinessCouponsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userBusinessCouponsUseCase = UserBusinessCouponsUseCase()

    /**
     * Get a list of available coupons for this user for a specific business.
     */
    suspend fun getUserBusinessCoupons(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<UserBusinessCouponsModel> {
        /* set state to Loading as soon as function is called */
        _userBusinessCouponsState.value = Loading()
        val result = this.userBusinessCouponsUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _userBusinessCouponsState.value = result
            return@userBusinessCouponsUseCase result
        }
        return result
    }

}
