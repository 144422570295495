/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userCampaignMessages

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userCampaignMessages.internal.CampaignMessagesUseCase
import features.userCampaignMessages.internal.CampaignMessagesUseCase.Params
import features.userCampaignMessages.models.CampaignMessagesModel


typealias CampaignMessagesState = CFlow<CampaignMessagesUseCaseResult?>

typealias CampaignMessagesUseCaseResult = Result<CampaignMessagesModel>

fun CampaignMessagesUseCaseResult.serializeCampaignMessagesApiResult(): SerializedResult<String> {
    return this.toJsonResult<CampaignMessagesModel>()
}

/**
 * Serializes [CampaignMessagesState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun CampaignMessagesState.serializeCampaignMessagesState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<CampaignMessagesModel>()
    }.asCFlow()
}

/**
 * Get this users campaign messages. Campaign messages are messages sent to this user from the business using the marketing engine. These might also be delivered by sms, email, or push notifications.  [Api] class
 */
class CampaignMessagesApi : Api() {

    val campaignMessagesState: CampaignMessagesState
        get() = _campaignMessagesState.asCFlow()

    companion object {
        private val _campaignMessagesState: MutableStateFlow<CampaignMessagesUseCaseResult> = MutableStateFlow(Idle())
    }

    private val campaignMessagesUseCase = CampaignMessagesUseCase()

    /**
     * Get this users campaign messages. Campaign messages are messages sent to this user from the business using the marketing engine. These might also be delivered by sms, email, or push notifications. 
     */
    suspend fun getCampaignMessages(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<CampaignMessagesModel> {
        /* set state to Loading as soon as function is called */
        _campaignMessagesState.value = Loading()
        val result = this.campaignMessagesUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _campaignMessagesState.value = result
            return@campaignMessagesUseCase result
        }
        return result
    }

}
