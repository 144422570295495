/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userPinResetRequest.internal

import features.userPinResetRequest.models.UserPinResetRequest
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField

internal class UserPinResetRequestRequest : NetworkRequest() {

    suspend fun requestPinReset(
        overrideHeaders: Map<String, String>? = null,
        mode: String,
        email: String?,
        phone: String?,
        countryCode: String?
    ): IOResult<UserPinResetRequest> {
        return safeNetworkCall {
            post<UserPinResetRequest, Any>(
                endpoint = UserPinResetRequestEndpoint,
                fields = listOf(
                    FormField(name = "mode", value = mode),
                    FormField(name = "email", value = email),
                    FormField(name = "phone", value = phone),
                    FormField(name = "country_code", value = countryCode)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
