/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.makePayPalPayment.internal

import features.makePayPalPayment.models.PayPalPaymentModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField

internal class PayPalPaymentRequest : NetworkRequest() {

    suspend fun makePayPalPayment(
        overrideHeaders: Map<String, String>? = null,
        intent: String,
        provider: String,
        giftcardId: Int,
        paypalReturnUrl: String,
        paypalCancelUrl: String
    ): IOResult<PayPalPaymentModel> {
        return safeNetworkCall {
            post<PayPalPaymentModel, Any>(
                endpoint = PayPalPaymentEndpoint,
                fields = listOf(
                    FormField(name = "intent", value = intent),
                    FormField(name = "provider", value = provider),
                    FormField(name = "giftcard_id", value = giftcardId),
                    FormField(name = "paypal_return_url", value = paypalReturnUrl),
                    FormField(name = "paypal_cancel_url", value = paypalCancelUrl)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
