/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.uploadImage

import features.uploadImage.internal.UploadImageUseCase
import features.uploadImage.internal.UploadImageUseCase.Params
import features.uploadImage.models.ImagesResponseModel
import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.krMultiPartForm.UploadImageRequestModel
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.json.Json
import kotlin.js.ExperimentalJsExport

typealias UploadImageState = CFlow<UploadImageUseCaseResult?>

typealias UploadImageUseCaseResult = Result<ImagesResponseModel>

fun UploadImageUseCaseResult.serializeUploadImageApiResult(): SerializedResult<String> {
    return this.toJsonResult<ImagesResponseModel>()
}

/**
 * Serializes [UploadImageState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UploadImageState.serializeUploadImageState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<ImagesResponseModel>()
    }.asCFlow()
}

/**
 * upload an image [Api] class
 */
class UploadImageApi : Api() {

    val uploadImageState: UploadImageState
        get() = _uploadImageState.asCFlow()

    companion object {
        private val _uploadImageState: MutableStateFlow<UploadImageUseCaseResult> = MutableStateFlow(Idle())
    }

    private val uploadImageUseCase = UploadImageUseCase()

    /**
     * upload an image
     */
    suspend fun uploadImage(
        overrideHeaders: Map<String, String>? = null,
        uploadImageBodyRequest: UploadImageRequestModel
    ): Result<ImagesResponseModel> {
        /* set state to Loading as soon as function is called */
        _uploadImageState.value = Loading()
        val result = this.uploadImageUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                uploadImageBodyRequest = uploadImageBodyRequest
            )
        ) { result ->
            _uploadImageState.value = result
            return@uploadImageUseCase result
        }
        return result
    }

    /**
    * upload an image
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun uploadImage(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<ImagesResponseModel> {
        return uploadImage(
            overrideHeaders = overrideHeaders,
            uploadImageBodyRequest = Json.decodeFromString(methods.getValue("uploadImageBodyRequest") as String)
        )
    }
}
