/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package kangaroo.multiplatformSdk.uploadImage.internal

import features.uploadImage.models.ImagesResponseModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.krMultiPartForm.UploadImageRequestModel
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.utils.Configuration.getUploadImageEndpoint

internal class UploadImageRequest : NetworkRequest() {

    suspend fun uploadImage(
        overrideHeaders: Map<String, String>? = null,
        uploadImageBodyRequest: UploadImageRequestModel
    ): IOResult<ImagesResponseModel> {
        return safeNetworkCall {
            post<ImagesResponseModel, UploadImageRequestModel>(
                endpoint = getUploadImageEndpoint(uploadImageBodyRequest.module),
                requestBody = uploadImageBodyRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
