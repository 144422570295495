/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.giftCards.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A language translation of this gift card.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class GiftCardTranslation(
    @SerialName("id")
    val id: Int,

    /** The ID of this specific translation */
    @SerialName("language_id")
    val languageId: Int?,

    /** The translated gift card title. */
    @SerialName("offer_title")
    val offerTitle: String?,

    /** The translated gift card description. */
    @SerialName("offer_description")
    val offerDescription: String?,

    /** The translated gift card terms and conditions. */
    @SerialName("offer_terms_conditions")
    val offerTermsConditions: String?,

    /** The language appropriate link of this gift card. */
    @SerialName("offer_link")
    val offerLink: String?,

    /** The language for which this translation applies. */
    @SerialName("language")
    val language: Language?
)
