/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicProducts.internal

import features.publicProducts.models.PublicProductsModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class PublicProductsRequest : NetworkRequest() {

    suspend fun getPublicProducts(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<PublicProductsModel> {
        return safeNetworkCall {
            get(
                endpoint = PublicProductsEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
