/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userOffers.internal

import features.userOffers.models.UserOffersModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserOffersServiceImpl : UserOffersService {

    private val userOffersRequest = UserOffersRequest()

    override suspend fun getUserOffers(
        overrideHeaders : Map<String, String>?,
    ): Result<UserOffersModel> {
        return userOffersRequest.getUserOffers(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
