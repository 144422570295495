/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.claimOffer.internal

import features.claimOffer.models.ClaimedOfferModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.claimOffer.internal.ClaimOfferUseCase.Params

internal class ClaimOfferUseCase : AsyncUseCase<ClaimedOfferModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val offerId: Int,
        val customerId: String
    )

    private val claimOfferService: ClaimOfferService = ClaimOfferServiceImpl()

    override suspend fun run(params: Params): Result<ClaimedOfferModel> {
        return claimOfferService.claimOffer(
            overrideHeaders = params.overrideHeaders,
            offerId = params.offerId,
            customerId = params.customerId
        )
    }
}
