/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.postOfferFacebookShare.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * User Profile balance data.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class UserBalance(
    /** The number of points this user currently has. */
    @SerialName("points")
    val points: Int?,

    /** The amount of store credit in the users&#39; e-wallet. */
    @SerialName("giftcard")
    val giftcard: Double?
)
