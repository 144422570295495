/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.getCrmFields.internal

import features.getCrmFields.models.CrmFields
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GetCrmFieldsServiceImpl : GetCrmFieldsService {

    private val getCrmFieldsRequest = GetCrmFieldsRequest()

    override suspend fun getCrmFields(
        overrideHeaders : Map<String, String>?,
    ): Result<CrmFields> {
        return getCrmFieldsRequest.getCrmFields(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
