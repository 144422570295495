/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userTransferRecall.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Contains the result of a transfer action.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class TransferActionResultModel(
    @SerialName("data")
    val data: TransferMessage?
) : Model()
