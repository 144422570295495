/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.performSocialMediaAction.internal

import features.performSocialMediaAction.models.PerformSocialMediaActionRequestModel
import features.performSocialMediaAction.models.PerformSocialMediaActionResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.performSocialMediaAction.internal.SocialMediaActionPerformUseCase.Params

internal class SocialMediaActionPerformUseCase : AsyncUseCase<PerformSocialMediaActionResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val performSocialMediaActionRequest: PerformSocialMediaActionRequestModel,
        val businessId: String
    )

    private val socialMediaActionPerformService: SocialMediaActionPerformService = SocialMediaActionPerformServiceImpl()

    override suspend fun run(params: Params): Result<PerformSocialMediaActionResponseModel> {
        return socialMediaActionPerformService.performSocialMediaAction(
            overrideHeaders = params.overrideHeaders,
            performSocialMediaActionRequest = params.performSocialMediaActionRequest,
            businessId = params.businessId
        )
    }
}
