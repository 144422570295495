/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessAlaCarteProducts.internal

import features.userBusinessAlaCarteProducts.models.AlaCarteProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessAlaCarteProductsServiceImpl : UserBusinessAlaCarteProductsService {

    private val userBusinessAlaCarteProductsRequest = UserBusinessAlaCarteProductsRequest()

    override suspend fun getUserBusinessAlaCarteProducts(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<AlaCarteProductsModel> {
        return userBusinessAlaCarteProductsRequest.getUserBusinessAlaCarteProducts(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
