/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userRegistration.internal

import features.userRegistration.models.UserProfileDataModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userRegistration.internal.UserRegistrationUseCase.Params

internal class UserRegistrationUseCase : AsyncUseCase<UserProfileDataModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val email: String?,
        val phone: String?,
        val countryCode: String?,
        val language: String?
    )

    private val userRegistrationService: UserRegistrationService = UserRegistrationServiceImpl()

    override suspend fun run(params: Params): Result<UserProfileDataModel> {
        return userRegistrationService.createAccount(
            overrideHeaders = params.overrideHeaders,
            email = params.email,
            phone = params.phone,
            countryCode = params.countryCode,
            language = params.language
        )
    }
}
