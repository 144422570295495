/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userBusinessSocialMediaLinks.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class BusinessActionModel(
    @SerialName("id")
    val id: Int?,

    @SerialName("points")
    val points: Int?,

    @SerialName("action_id")
    val actionId: Int?
) : Model()
