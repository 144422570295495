/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.redeemCoupon

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.redeemCoupon.internal.RedeemCouponsUseCase
import features.redeemCoupon.internal.RedeemCouponsUseCase.Params
import features.redeemCoupon.models.CouponRedemptionResponseModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.redeemCoupon.models.RedeemCouponRequest

typealias RedeemCouponsState = CFlow<RedeemCouponsUseCaseResult?>

typealias RedeemCouponsUseCaseResult = Result<CouponRedemptionResponseModel>

fun RedeemCouponsUseCaseResult.serializeRedeemCouponsApiResult(): SerializedResult<String> {
    return this.toJsonResult<CouponRedemptionResponseModel>()
}

/**
 * Serializes [RedeemCouponsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun RedeemCouponsState.serializeRedeemCouponsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<CouponRedemptionResponseModel>()
    }.asCFlow()
}

/**
 * Redeem a coupon. Coupons are created after an offer has been redeemed.  [Api] class
 */
class RedeemCouponsApi : Api() {

    val redeemCouponsState: RedeemCouponsState
        get() = _redeemCouponsState.asCFlow()

    companion object {
        private val _redeemCouponsState: MutableStateFlow<RedeemCouponsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val redeemCouponsUseCase = RedeemCouponsUseCase()

    /**
     * Redeem a coupon. Coupons are created after an offer has been redeemed. 
     */
    suspend fun redeemCoupon(
        overrideHeaders: Map<String, String>? = null,
        redeemCouponRequest: RedeemCouponRequest
    ): Result<CouponRedemptionResponseModel> {
        /* set state to Loading as soon as function is called */
        _redeemCouponsState.value = Loading()
        val result = this.redeemCouponsUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                redeemCouponRequest = redeemCouponRequest
            )
        ) { result ->
            _redeemCouponsState.value = result
            return@redeemCouponsUseCase result
        }
        return result
    }

    /**
    * Redeem a coupon. Coupons are created after an offer has been redeemed. 
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun redeemCoupon(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<CouponRedemptionResponseModel> {
        return redeemCoupon(
            overrideHeaders = overrideHeaders,
            redeemCouponRequest = Json.decodeFromString(methods.getValue("redeemCouponRequest") as String)
        )
    }
}
