/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userBusinessOffers.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Details about this business' category. This can be something like cafe, boutique, etc... 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class BusinessCategory(
    /** Name of this category */
    @SerialName("name")
    val name: String?,

    /** Url to this category&#39;s icon */
    @SerialName("icon")
    val icon: String?,

    /** Unique ategory code for this category */
    @SerialName("code")
    val code: String?
)
