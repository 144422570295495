/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.frequentBuyerPrograms.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class FrequentBuyerProgramsIncluded(
    @SerialName("frequent_buyer_programs")
    val frequentBuyerPrograms: FrequentBuyerProgramList?
)
