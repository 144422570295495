/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userProfile

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userProfile.internal.UserProfileUseCase
import features.userProfile.internal.UserProfileUseCase.Params
import features.userProfile.models.UserProfileModel


typealias UserProfileState = CFlow<UserProfileUseCaseResult?>

typealias UserProfileUseCaseResult = Result<UserProfileModel>

fun UserProfileUseCaseResult.serializeUserProfileApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProfileModel>()
}

/**
 * Serializes [UserProfileState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserProfileState.serializeUserProfileState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProfileModel>()
    }.asCFlow()
}

/**
 * Get user profile data for this user. [Api] class
 */
class UserProfileApi : Api() {

    val userProfileState: UserProfileState
        get() = _userProfileState.asCFlow()

    companion object {
        private val _userProfileState: MutableStateFlow<UserProfileUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userProfileUseCase = UserProfileUseCase()

    /**
     * Get user profile data for this user.
     */
    suspend fun getUserProfile(
        overrideHeaders: Map<String, String>? = null,

    ): Result<UserProfileModel> {
        /* set state to Loading as soon as function is called */
        _userProfileState.value = Loading()
        val result = this.userProfileUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _userProfileState.value = result
            return@userProfileUseCase result
        }
        return result
    }

}
