/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.socialMediaLinks.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class SocialMediaModel(
    @SerialName("icon")
    val icon: String?,

    @SerialName("url")
    val url: String?,

    @SerialName("business_action")
    val businessAction: BusinessActionModel?,

    @SerialName("user_action_completed")
    val userActionCompleted: Boolean?
) : Model()
