/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userScanReceipt

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userScanReceipt.internal.UserScanReceiptUseCase
import features.userScanReceipt.internal.UserScanReceiptUseCase.Params
import features.userScanReceipt.models.ScanReceiptResponseModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.userScanReceipt.models.ScanReceiptRequest

typealias UserScanReceiptState = CFlow<UserScanReceiptUseCaseResult?>

typealias UserScanReceiptUseCaseResult = Result<ScanReceiptResponseModel>

fun UserScanReceiptUseCaseResult.serializeUserScanReceiptApiResult(): SerializedResult<String> {
    return this.toJsonResult<ScanReceiptResponseModel>()
}

/**
 * Serializes [UserScanReceiptState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserScanReceiptState.serializeUserScanReceiptState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<ScanReceiptResponseModel>()
    }.asCFlow()
}

/**
 * Scan a purchase receipt with a business  [Api] class
 */
class UserScanReceiptApi : Api() {

    val userScanReceiptState: UserScanReceiptState
        get() = _userScanReceiptState.asCFlow()

    companion object {
        private val _userScanReceiptState: MutableStateFlow<UserScanReceiptUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userScanReceiptUseCase = UserScanReceiptUseCase()

    /**
     * Scan a purchase receipt with a business 
     */
    suspend fun scanReceipt(
        overrideHeaders: Map<String, String>? = null,
        scanReceiptRequest: ScanReceiptRequest
    ): Result<ScanReceiptResponseModel> {
        /* set state to Loading as soon as function is called */
        _userScanReceiptState.value = Loading()
        val result = this.userScanReceiptUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                scanReceiptRequest = scanReceiptRequest
            )
        ) { result ->
            _userScanReceiptState.value = result
            return@userScanReceiptUseCase result
        }
        return result
    }

    /**
    * Scan a purchase receipt with a business 
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun scanReceipt(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<ScanReceiptResponseModel> {
        return scanReceipt(
            overrideHeaders = overrideHeaders,
            scanReceiptRequest = Json.decodeFromString(methods.getValue("scanReceiptRequest") as String)
        )
    }
}
