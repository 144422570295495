/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.makeGiftCardPurchase

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.makeGiftCardPurchase.internal.GiftCardPurchaseUseCase
import features.makeGiftCardPurchase.internal.GiftCardPurchaseUseCase.Params
import features.makeGiftCardPurchase.models.GiftCardPayPalPaymentResponseModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.makeGiftCardPurchase.models.GiftCardPurchaseRequest

typealias GiftCardPurchaseState = CFlow<GiftCardPurchaseUseCaseResult?>

typealias GiftCardPurchaseUseCaseResult = Result<GiftCardPayPalPaymentResponseModel>

fun GiftCardPurchaseUseCaseResult.serializeGiftCardPurchaseApiResult(): SerializedResult<String> {
    return this.toJsonResult<GiftCardPayPalPaymentResponseModel>()
}

/**
 * Serializes [GiftCardPurchaseState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun GiftCardPurchaseState.serializeGiftCardPurchaseState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<GiftCardPayPalPaymentResponseModel>()
    }.asCFlow()
}

/**
 * Purchase a gift card [Api] class
 */
class GiftCardPurchaseApi : Api() {

    val giftCardPurchaseState: GiftCardPurchaseState
        get() = _giftCardPurchaseState.asCFlow()

    companion object {
        private val _giftCardPurchaseState: MutableStateFlow<GiftCardPurchaseUseCaseResult> = MutableStateFlow(Idle())
    }

    private val giftCardPurchaseUseCase = GiftCardPurchaseUseCase()

    /**
     * Purchase a gift card
     */
    suspend fun purchaseGiftCard(
        overrideHeaders: Map<String, String>? = null,
        purchaseGiftCardRequest: GiftCardPurchaseRequest
    ): Result<GiftCardPayPalPaymentResponseModel> {
        /* set state to Loading as soon as function is called */
        _giftCardPurchaseState.value = Loading()
        val result = this.giftCardPurchaseUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                purchaseGiftCardRequest = purchaseGiftCardRequest
            )
        ) { result ->
            _giftCardPurchaseState.value = result
            return@giftCardPurchaseUseCase result
        }
        return result
    }

    /**
    * Purchase a gift card
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun purchaseGiftCard(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<GiftCardPayPalPaymentResponseModel> {
        return purchaseGiftCard(
            overrideHeaders = overrideHeaders,
            purchaseGiftCardRequest = Json.decodeFromString(methods.getValue("purchaseGiftCardRequest") as String)
        )
    }
}
