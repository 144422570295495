@file:OptIn(ExperimentalJsExport::class, ExperimentalJsExport::class, ExperimentalJsExport::class)

package kangaroorewards.appsdk.core

import co.touchlab.kermit.Logger
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import kangaroorewards.appsdk.core.AuthenticationModel.Companion.ACCESS_TOKEN
import kangaroorewards.appsdk.core.AuthenticationModel.Companion.AUTH_SCOPE
import kangaroorewards.appsdk.core.AuthenticationModel.Companion.REFRESH_TOKEN
import kangaroorewards.appsdk.core.cache.EncryptedKeyValueStore
import kangaroorewards.appsdk.core.network.base.KHttpClient
import kangaroorewards.appsdk.core.utils.language.PREFERRED_LANGUAGE

@Suppress("unused")
actual class KangarooSdk {
    /**
     * Initializes the sdk. Accessing the sdk prior to running this command
     * will throw an exception
     */

    actual fun initialize(
        applicationKey: String,
        clientId: String,
        clientSecret: String,
        environment: String,
        baseURL: String
    ) {

        SdkContext.sdkInit = true

        SdkContext.tokenStore = TokenStore()

        SdkContext.applicationKey = applicationKey
        SdkContext.clientId = clientId
        SdkContext.clientSecret = clientSecret
        SdkContext.environment = environment
        SdkContext.baseURL = baseURL
    }

    /**
     * Gets the access token. If null or empty, the session has expired.
     */
    actual fun getSession(): String? {
        return SdkContext.tokenStore?.getTokens()?.accessToken
//        Logger.d { "" }
    }

    actual fun killSession() {
        val encryptedKeyValueStore = EncryptedKeyValueStore()
        with(encryptedKeyValueStore) {
            remove(ACCESS_TOKEN)
            remove(REFRESH_TOKEN)
            remove(AUTH_SCOPE)
        }
    }

    actual fun setPreferredLanguage(iso639LanguageCode: String) {
        val encryptedKeyValueStore = EncryptedKeyValueStore()
        with(encryptedKeyValueStore) {
            put(PREFERRED_LANGUAGE, iso639LanguageCode)
        }
    }

    actual fun getPreferredLanguage(): String? {
        return SdkContext.tokenStore?.getPreferredLanguage()
    }
}

actual class TokenStore {

    actual fun storeTokens(tokens: AuthenticationModel) {
        val encryptedKeyValueStore = EncryptedKeyValueStore()
        with(encryptedKeyValueStore) {
            Logger.d { "SDK Storing access token: ${tokens.accessToken}" }
            put(ACCESS_TOKEN, tokens.accessToken)
            put(REFRESH_TOKEN, tokens.refreshToken)
            put(AUTH_SCOPE, tokens.authScope)
        }
    }

    actual fun getTokens(): AuthenticationModel? {

        val encryptedKeyValueStore = EncryptedKeyValueStore()
        val accessToken: String = encryptedKeyValueStore.get(ACCESS_TOKEN)
        val refreshToken: String = encryptedKeyValueStore.get(REFRESH_TOKEN)
        val authScope: String = encryptedKeyValueStore.get(AUTH_SCOPE)

        Logger.d { "SDK Getting access token: $accessToken" }

        return if (accessToken.isBlank()) {
            Logger.d { "SDK Getting access token is blank" }
            null
        } else {
            Logger.d { "SDK Getting access token success" }
            AuthenticationModel(
                authScope = authScope,
                accessToken = accessToken,
                refreshToken = refreshToken
            )
        }
    }

    actual fun getPreferredLanguage(): String? {
        val encryptedKeyValueStore = EncryptedKeyValueStore()
        return encryptedKeyValueStore.get(PREFERRED_LANGUAGE)
    }
}