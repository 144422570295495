/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userNotificationPreferences

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userNotificationPreferences.GetNotificationPreferencesApi
import features.userNotificationPreferences.models.NotificationSettingsModel
import features.userNotificationPreferences.serializeGetNotificationPreferencesState



/**
 * Get a users notification settings.  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("GetNotificationPreferencesApi")
class GetNotificationPreferencesApiJs {

    /**
     * Get a users notification settings. 
     */
    fun getNotificationPreferences(
        overrideHeaders: String?, 

    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = GetNotificationPreferencesApi().getNotificationPreferences(
            overrideHeaders = decodedOverrideHeaders,
            
        ).toJsonResult<NotificationSettingsModel>()
        
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeGetNotificationPreferencesState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        GetNotificationPreferencesApi().getNotificationPreferencesState.serializeGetNotificationPreferencesState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
