/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.socialMediaLinks

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.socialMediaLinks.internal.SocialMediaLinksUseCase
import features.socialMediaLinks.internal.SocialMediaLinksUseCase.Params
import features.socialMediaLinks.models.SocialMediaLinksModel


typealias SocialMediaLinksState = CFlow<SocialMediaLinksUseCaseResult?>

typealias SocialMediaLinksUseCaseResult = Result<SocialMediaLinksModel>

fun SocialMediaLinksUseCaseResult.serializeSocialMediaLinksApiResult(): SerializedResult<String> {
    return this.toJsonResult<SocialMediaLinksModel>()
}

/**
 * Serializes [SocialMediaLinksState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun SocialMediaLinksState.serializeSocialMediaLinksState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<SocialMediaLinksModel>()
    }.asCFlow()
}

/**
 * Get a list of social media links for this business [Api] class
 */
class SocialMediaLinksApi : Api() {

    val socialMediaLinksState: SocialMediaLinksState
        get() = _socialMediaLinksState.asCFlow()

    companion object {
        private val _socialMediaLinksState: MutableStateFlow<SocialMediaLinksUseCaseResult> = MutableStateFlow(Idle())
    }

    private val socialMediaLinksUseCase = SocialMediaLinksUseCase()

    /**
     * Get a list of social media links for this business
     */
    suspend fun getSocialMediaLinks(
        overrideHeaders: Map<String, String>? = null,

    ): Result<SocialMediaLinksModel> {
        /* set state to Loading as soon as function is called */
        _socialMediaLinksState.value = Loading()
        val result = this.socialMediaLinksUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _socialMediaLinksState.value = result
            return@socialMediaLinksUseCase result
        }
        return result
    }

}
