/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.countries.internal

import features.countries.models.Countries
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class CountriesRequest : NetworkRequest() {

    suspend fun getCountries(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<Countries> {
        return safeNetworkCall {
            get(
                endpoint = CountriesEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
