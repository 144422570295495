/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.socialMediaLinks.internal

import features.socialMediaLinks.models.SocialMediaLinksModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class SocialMediaLinksServiceImpl : SocialMediaLinksService {

    private val socialMediaLinksRequest = SocialMediaLinksRequest()

    override suspend fun getSocialMediaLinks(
        overrideHeaders : Map<String, String>?,
    ): Result<SocialMediaLinksModel> {
        return socialMediaLinksRequest.getSocialMediaLinks(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
