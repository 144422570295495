/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.strings.internal

import kangaroorewards.appsdk.core.network.base.Endpoint

/**
 * The HTTP endpoint for the [StringsApi].
 *
 * [Endpoint] has a single [Endpoint.path] parameter ex. "users/me"
 */
internal val StringsEndpoint = Endpoint(path = "public/app-strings")
