/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.claimOffer.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class OfferModel(
    /** A unique ID identifying this reward */
    @SerialName("id")
    val id: Int,

    /** The amount of points required for this offer to be applied. Only used for Convertible Coupons  */
    @SerialName("points")
    val points: Int?,

    /** The publication date of this offer. */
    @SerialName("publish_at")
    val publishAt: String?,

    /** The expiration date of this offer. */
    @SerialName("expires_at")
    val expiresAt: String?,

    /** Whether this offer is currently published or not. */
    @SerialName("is_published")
    val isPublished: Boolean?,

    /** Whether this is a convertible coupon. Convertible coupons are automatically added to the customers account when the point threshold is met. The points are automatically deducted from the users&#39; account.  */
    @SerialName("coupon_convertible")
    val couponConvertible: Boolean?,

    /** The real value  (in $) of this offer */
    @SerialName("real_value")
    val realValue: Double?,

    /** The discount value (in $) of this offer */
    @SerialName("discount_value")
    val discountValue: Double?,

    /** The multiplication factor of this offer. This only applies to Point Multiplier offers.  */
    @SerialName("multip_factor")
    val multipFactor: Double?,

    /** The minimum purchase amount for this offer to be applied */
    @SerialName("min_purchase")
    val minPurchase: Double?,

    /** The maximum purchase amount for this offer to be applied. */
    @SerialName("max_purchase")
    val maxPurchase: Double?,

    /** Whether this offer must be claimed on a mobile app before being used in store.  */
    @SerialName("apps_only")
    val appsOnly: Boolean?,

    @SerialName("is_coupon_redeemed")
    val isCouponRedeemed: Boolean?,

    @SerialName("offer_frequency_id")
    val offerFrequencyId: Int?,

    @SerialName("freq_details")
    val freqDetails: List<String>?,

    @SerialName("peak_from")
    val peakFrom: String?,

    @SerialName("peak_to")
    val peakTo: String?,

    /** The offer type. This can be a free product, dollar based discount, percentage based discount, point multiplier, bonus points, and free shipping.  */
    @SerialName("type")
    val type: String?,

    /** The offer title. */
    @SerialName("title")
    val title: String?,

    /** The offer description. */
    @SerialName("description")
    val description: String?,

    /** The unique slug for this offer */
    @SerialName("slug")
    val slug: String?,

    /** The images attached to this offer. There are 3 available sizes: thumbnail, medium, and large.  */
    @SerialName("images")
    val images: List<ImageModel>?,

    /** Whether this offer ever expires or not. If false, this offer is available indefinitely (provided any other requirements are met)  */
    @SerialName("never_expires_flag")
    val neverExpiresFlag: Boolean?,

    /** The terms and conditions attached to this offer. */
    @SerialName("terms_conditions")
    val termsConditions: String?,

    /** The external link attached to this offer. */
    @SerialName("link")
    val link: String?,

    /** A list of translations of this offer into different languages. */
    @SerialName("offer_languages")
    val offerLanguages: List<OfferTranslation>?,

    /** A list of possible actions to perform on this offer. */
    @SerialName("actions")
    val actions: List<OfferAction>?
) : Model()
