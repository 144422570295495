/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.makeGiftCardPurchase.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class GiftCardPayPalPayment(
    @SerialName("payment_id")
    val paymentId: String,

    @SerialName("payer_id")
    val payerId: String,

    @SerialName("token")
    val token: String,

    @SerialName("success")
    val success: Boolean?
)
