/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userDetails

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userDetails.UserDetailsApi
import features.userDetails.models.UserDetailsModel
import features.userDetails.serializeUserDetailsState



/**
 * Get user details for this user. [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UserDetailsApi")
class UserDetailsApiJs {

    /**
     * Get user details for this user.
     */
    fun getUserDetails(
        overrideHeaders: String?, 

    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = UserDetailsApi().getUserDetails(
            overrideHeaders = decodedOverrideHeaders,
            
        ).toJsonResult<UserDetailsModel>()
        
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeUserDetailsState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserDetailsApi().userDetailsState.serializeUserDetailsState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
