/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userPinResetRequest.internal

import features.userPinResetRequest.models.UserPinResetRequest
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userPinResetRequest.internal.UserPinResetRequestUseCase.Params

internal class UserPinResetRequestUseCase : AsyncUseCase<UserPinResetRequest, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val mode: String,
        val email: String?,
        val phone: String?,
        val countryCode: String?
    )

    private val userPinResetRequestService: UserPinResetRequestService = UserPinResetRequestServiceImpl()

    override suspend fun run(params: Params): Result<UserPinResetRequest> {
        return userPinResetRequestService.requestPinReset(
            overrideHeaders = params.overrideHeaders,
            mode = params.mode,
            email = params.email,
            phone = params.phone,
            countryCode = params.countryCode
        )
    }
}
