/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.socialMediaLinks.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class ProfilesDataModel(
    /** Unique user ID. */
    @SerialName("id")
    val id: String?,

    /** Email address identifying this user. */
    @SerialName("email")
    val email: String?,

    /** Phone number associated with this user. */
    @SerialName("phone")
    val phone: String?,

    /** First name associated with this user. */
    @SerialName("first_name")
    val firstName: String?,

    /** Last name associated with this user. */
    @SerialName("last_name")
    val lastName: String?,

    /** Unique QR code identifying this user. */
    @SerialName("qrcode")
    val qrcode: String?,

    /** Date the account for this user was created. */
    @SerialName("created_at")
    val createdAt: String?,

    /** Denotes whether this is the master account or not */
    @SerialName("master_account")
    val masterAccount: Boolean?,

    @SerialName("business")
    val business: BusinessData?
) : Model()
