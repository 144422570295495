/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userProfile.internal

import features.userProfile.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserProfileServiceImpl : UserProfileService {

    private val userProfileRequest = UserProfileRequest()

    override suspend fun getUserProfile(
        overrideHeaders : Map<String, String>?,
    ): Result<UserProfileModel> {
        return userProfileRequest.getUserProfile(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
