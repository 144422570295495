/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userCampaignMessages

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userCampaignMessages.CampaignMessagesApi
import features.userCampaignMessages.models.CampaignMessagesModel
import features.userCampaignMessages.serializeCampaignMessagesState



/**
 * Get this users campaign messages. Campaign messages are messages sent to this user from the business using the marketing engine. These might also be delivered by sms, email, or push notifications.  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("CampaignMessagesApi")
class CampaignMessagesApiJs {

    /**
     * Get this users campaign messages. Campaign messages are messages sent to this user from the business using the marketing engine. These might also be delivered by sms, email, or push notifications. 
     */
    fun getCampaignMessages(
        overrideHeaders: String?, 
        businessId: String
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = CampaignMessagesApi().getCampaignMessages(
            overrideHeaders = decodedOverrideHeaders,
                        businessId = businessId
        ).toJsonResult<CampaignMessagesModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeCampaignMessagesState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        CampaignMessagesApi().campaignMessagesState.serializeCampaignMessagesState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
