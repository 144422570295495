/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.archiveInboxItem.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class ArchiveResponseData(
    @SerialName("id")
    val id: Int,

    @SerialName("type")
    val type: String?,

    @SerialName("key")
    val key: Int?,

    @SerialName("created_at")
    val createdAt: String?
)
