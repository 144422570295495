package features.userAuthentication.internal

import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField
import features.userAuthentication.models.UserAuthenticationModel

internal class UserAuthenticationRequest : NetworkRequest() {

    suspend fun authorizeUser(
        username: String?,
        password: String?,
        googleToken: String?,
        overrideHeaders: Map<String, String>?,
    ): IOResult<UserAuthenticationModel> {
        return safeNetworkCall {
            postForm(
                endpoint = UserAuthenticationEndpoint,
                FormField("grant_type", if (googleToken.isNullOrEmpty())  "password" else "google"),
                FormField("scope", "user"),
                FormField("username", username),
                FormField("password", password),
                FormField("google_token", googleToken),
                overrideHeaders = overrideHeaders
            )
        }
    }
}