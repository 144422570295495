/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.publicBusinessSettings.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * public settings related to the business
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class PublicBusinessSettings(
    /** Details about the business public settings */
    @SerialName("data")
    val data: PublicBusinessSettingsData?
) : Model()
