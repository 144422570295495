/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessAlaCarteProducts.internal

import features.userBusinessAlaCarteProducts.models.AlaCarteProductsModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class UserBusinessAlaCarteProductsRequest : NetworkRequest() {

    suspend fun getUserBusinessAlaCarteProducts(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): IOResult<AlaCarteProductsModel> {
        return safeNetworkCall {
            get(
                endpoint = UserBusinessAlaCarteProductsEndpoint.replaceInPath(
                    PathParameter("businessId", businessId),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
