/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessSocialMediaLinks.internal

import features.userBusinessSocialMediaLinks.models.SocialMediaLinksModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userBusinessSocialMediaLinks.internal.UserBusinessSocialMediaLinksUseCase.Params

internal class UserBusinessSocialMediaLinksUseCase : AsyncUseCase<SocialMediaLinksModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val userBusinessSocialMediaLinksService: UserBusinessSocialMediaLinksService = UserBusinessSocialMediaLinksServiceImpl()

    override suspend fun run(params: Params): Result<SocialMediaLinksModel> {
        return userBusinessSocialMediaLinksService.getUserBusinessSocialMediaLinks(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
