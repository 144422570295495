/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userTransferRecall

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userTransferRecall.TransferRecallApi
import features.userTransferRecall.models.TransferActionResultModel
import features.userTransferRecall.serializeTransferRecallState



/**
 * Recall a transfer. This could be a points transfer, e-wallet transfer, or a gift card  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("TransferRecallApi")
class TransferRecallApiJs {

    /**
     * Recall a transfer. This could be a points transfer, e-wallet transfer, or a gift card 
     */
    fun recallTransfer(
        overrideHeaders: String?, 
        recallId: String
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = TransferRecallApi().recallTransfer(
            overrideHeaders = decodedOverrideHeaders,
                        recallId = recallId
        ).toJsonResult<TransferActionResultModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeTransferRecallState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        TransferRecallApi().transferRecallState.serializeTransferRecallState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
