/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userCheckIn.internal

import features.userCheckIn.models.CheckInRequest
import features.userCheckIn.models.CheckInResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserCheckInServiceImpl : UserCheckInService {

    private val userCheckInRequest = UserCheckInRequest()

    override suspend fun userCheckIn(
        overrideHeaders : Map<String, String>?,
        checkInRequest: CheckInRequest    ): Result<CheckInResponseModel> {
        return userCheckInRequest.userCheckIn(
            overrideHeaders = overrideHeaders,
            checkInRequest = checkInRequest        ).toModel()
    }
}
