@file:OptIn(ExperimentalJsExport::class)

package features.userAuthentication.models

import kangaroorewards.appsdk.core.AuthenticationModel
import kangaroorewards.appsdk.core.AuthenticationScope
import kangaroorewards.appsdk.core.io.Model
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

/**
 * Represents a successful user authentication request.
 * @property accessToken The access token created for this session. Expires in 24 hours
 * @property refreshToken The refresh token created this session. Expires in 30 days
 */
@ExperimentalJsExport
@Serializable
@JsExport
data class UserAuthenticationModel(
    @SerialName("token_type")
    val tokenType: String? = "Bearer",
    @SerialName("expires_in")
    val expiresIn: Int? = 0,
    @SerialName("access_token")
    val accessToken: String?,
    @SerialName("refresh_token")
    val refreshToken: String? = "",
) : Model() {
    companion object {
        const val ACCESS_TOKEN = "access_token"
        const val REFRESH_TOKEN = "refresh_token"
    }
}

fun UserAuthenticationModel.toAuthenticationModel(): AuthenticationModel {
    return AuthenticationModel(
        authScope = AuthenticationScope.Customer.name,
        accessToken = accessToken,
        refreshToken = refreshToken,
    )
}