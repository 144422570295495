/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessTiers.internal

import features.userBusinessTiers.models.UserProfileWithTierProgram
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.Query

internal class UserBusinessTiersRequest : NetworkRequest() {

    suspend fun getUserBusinessTiers(
        overrideHeaders: Map<String, String>? = null,
        memberBusinessId: String
    ): IOResult<UserProfileWithTierProgram> {
        return safeNetworkCall {
            get(
                endpoint = UserBusinessTiersEndpoint,
                queries = listOf(
                    Query(name = "memberBusinessId", value = memberBusinessId)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
