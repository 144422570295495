/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userNotificationPreferences.internal

import features.userNotificationPreferences.models.NotificationSettingsModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class GetNotificationPreferencesRequest : NetworkRequest() {

    suspend fun getNotificationPreferences(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<NotificationSettingsModel> {
        return safeNetworkCall {
            get(
                endpoint = GetNotificationPreferencesEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
