/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userBalanceTransfer.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * The recipient data for this transfer. Either a phone number or an email address is required. If a phone number is used, the two character ISO country code must be supplied as well. 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class TransferRequestTo(
    @SerialName("name")
    val name: String?,

    @SerialName("phone")
    val phone: String?,

    @SerialName("country_code")
    val countryCode: String?,

    @SerialName("email")
    val email: String?
)
