/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.performSocialMediaAction.internal

import features.performSocialMediaAction.models.PerformSocialMediaActionRequestModel
import features.performSocialMediaAction.models.PerformSocialMediaActionResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class SocialMediaActionPerformServiceImpl : SocialMediaActionPerformService {

    private val socialMediaActionPerformRequest = SocialMediaActionPerformRequest()

    override suspend fun performSocialMediaAction(
        overrideHeaders : Map<String, String>?,
        performSocialMediaActionRequest: PerformSocialMediaActionRequestModel,
        businessId: String    ): Result<PerformSocialMediaActionResponseModel> {
        return socialMediaActionPerformRequest.performSocialMediaAction(
            overrideHeaders = overrideHeaders,
            performSocialMediaActionRequest = performSocialMediaActionRequest,
            businessId = businessId        ).toModel()
    }
}
