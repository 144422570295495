/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.tiers

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.tiers.internal.TiersUseCase
import features.tiers.internal.TiersUseCase.Params
import features.tiers.models.UserProfileWithTierProgram


typealias TiersState = CFlow<TiersUseCaseResult?>

typealias TiersUseCaseResult = Result<UserProfileWithTierProgram>

fun TiersUseCaseResult.serializeTiersApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProfileWithTierProgram>()
}

/**
 * Serializes [TiersState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun TiersState.serializeTiersState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProfileWithTierProgram>()
    }.asCFlow()
}

/**
 * Acquires information about the tier program. Users can move through different tiers if a business has activated their tier program. By acquire points, making purchases, and visiting the business, the user can be rewarded with different tier levels.  [Api] class
 */
class TiersApi : Api() {

    val tiersState: TiersState
        get() = _tiersState.asCFlow()

    companion object {
        private val _tiersState: MutableStateFlow<TiersUseCaseResult> = MutableStateFlow(Idle())
    }

    private val tiersUseCase = TiersUseCase()

    /**
     * Acquires information about the tier program. Users can move through different tiers if a business has activated their tier program. By acquire points, making purchases, and visiting the business, the user can be rewarded with different tier levels. 
     */
    suspend fun getTiers(
        overrideHeaders: Map<String, String>? = null,

    ): Result<UserProfileWithTierProgram> {
        /* set state to Loading as soon as function is called */
        _tiersState.value = Loading()
        val result = this.tiersUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _tiersState.value = result
            return@tiersUseCase result
        }
        return result
    }

}
