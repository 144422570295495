/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.makePayPalPayment

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.makePayPalPayment.PayPalPaymentApi
import features.makePayPalPayment.models.PayPalPaymentModel
import features.makePayPalPayment.serializePayPalPaymentState



/**
 * Create a paypal payment  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("PayPalPaymentApi")
class PayPalPaymentApiJs {

    /**
     * Create a paypal payment 
     */
    fun makePayPalPayment(
        overrideHeaders: String?, 
        intent: String = "buy_giftcard",
        provider: String = "paypal",
        giftcardId: Int,
        paypalReturnUrl: String,
        paypalCancelUrl: String
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = PayPalPaymentApi().makePayPalPayment(
            overrideHeaders = decodedOverrideHeaders,
                        intent = intent,
            provider = provider,
            giftcardId = giftcardId,
            paypalReturnUrl = paypalReturnUrl,
            paypalCancelUrl = paypalCancelUrl
        ).toJsonResult<PayPalPaymentModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observePayPalPaymentState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        PayPalPaymentApi().payPalPaymentState.serializePayPalPaymentState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
