/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessAlaCarteProducts.internal

import features.userBusinessAlaCarteProducts.models.AlaCarteProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userBusinessAlaCarteProducts.internal.UserBusinessAlaCarteProductsUseCase.Params

internal class UserBusinessAlaCarteProductsUseCase : AsyncUseCase<AlaCarteProductsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val userBusinessAlaCarteProductsService: UserBusinessAlaCarteProductsService = UserBusinessAlaCarteProductsServiceImpl()

    override suspend fun run(params: Params): Result<AlaCarteProductsModel> {
        return userBusinessAlaCarteProductsService.getUserBusinessAlaCarteProducts(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
