/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userBusinessTiers

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userBusinessTiers.internal.UserBusinessTiersUseCase
import features.userBusinessTiers.internal.UserBusinessTiersUseCase.Params
import features.userBusinessTiers.models.UserProfileWithTierProgram


typealias UserBusinessTiersState = CFlow<UserBusinessTiersUseCaseResult?>

typealias UserBusinessTiersUseCaseResult = Result<UserProfileWithTierProgram>

fun UserBusinessTiersUseCaseResult.serializeUserBusinessTiersApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProfileWithTierProgram>()
}

/**
 * Serializes [UserBusinessTiersState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserBusinessTiersState.serializeUserBusinessTiersState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProfileWithTierProgram>()
    }.asCFlow()
}

/**
 * Acquires information about the tier program for a member business of a conglomerate business. Users can move through different tiers if a business has activated their tier program. By acquire points, making purchases, and visiting the business, the user can be rewarded with different tier levels. [Api] class
 */
class UserBusinessTiersApi : Api() {

    val userBusinessTiersState: UserBusinessTiersState
        get() = _userBusinessTiersState.asCFlow()

    companion object {
        private val _userBusinessTiersState: MutableStateFlow<UserBusinessTiersUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userBusinessTiersUseCase = UserBusinessTiersUseCase()

    /**
     * Acquires information about the tier program for a member business of a conglomerate business. Users can move through different tiers if a business has activated their tier program. By acquire points, making purchases, and visiting the business, the user can be rewarded with different tier levels.
     */
    suspend fun getUserBusinessTiers(
        overrideHeaders: Map<String, String>? = null,
        memberBusinessId: String
    ): Result<UserProfileWithTierProgram> {
        /* set state to Loading as soon as function is called */
        _userBusinessTiersState.value = Loading()
        val result = this.userBusinessTiersUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                memberBusinessId = memberBusinessId
            )
        ) { result ->
            _userBusinessTiersState.value = result
            return@userBusinessTiersUseCase result
        }
        return result
    }

}
