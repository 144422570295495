/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userAlaCarte.internal

import features.userAlaCarte.models.AlaCarteProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class AlaCarteProductsServiceImpl : AlaCarteProductsService {

    private val alaCarteProductsRequest = AlaCarteProductsRequest()

    override suspend fun getAlaCarteProducts(
        overrideHeaders : Map<String, String>?,
    ): Result<AlaCarteProductsModel> {
        return alaCarteProductsRequest.getAlaCarteProducts(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
