/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.makeGiftCardPurchase.internal

import features.makeGiftCardPurchase.models.GiftCardPayPalPaymentResponseModel
import features.makeGiftCardPurchase.models.GiftCardPurchaseRequest
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class GiftCardPurchaseRequest : NetworkRequest() {

    suspend fun purchaseGiftCard(
        overrideHeaders: Map<String, String>? = null,
        purchaseGiftCardRequest: GiftCardPurchaseRequest
    ): IOResult<GiftCardPayPalPaymentResponseModel> {
        return safeNetworkCall {
            post<GiftCardPayPalPaymentResponseModel, GiftCardPurchaseRequest>(
                endpoint = GiftCardPurchaseEndpoint,
                requestBody = purchaseGiftCardRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
