/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.makeGiftCardPurchase.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class GiftCardPurchaseRequest(
    @SerialName("intent")
    val intent: String,

    @SerialName("provider")
    val provider: String,

    @SerialName("giftcard_id")
    val giftcardId: Int,

    @SerialName("paypal_payment")
    val paypalPayment: GiftCardPayPalPayment
)
