/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userProfile.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A referral program
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class ReferralProgram(
    /** The unique id of this referral program. */
    @SerialName("id")
    val id: Int?,

    @SerialName("rule_name")
    val ruleName: String?,

    /** The minimum purchawe amount required for a succesful referral. */
    @SerialName("cond_min_amount")
    val condMinAmount: Double?,

    /** The number of points the new customer will earn. */
    @SerialName("referee_earns")
    val refereeEarns: Int?,

    /** The number of points the new customer who made the referral will earn. */
    @SerialName("referer_earns")
    val refererEarns: Int?,

    /** The number of days the new customer has to sign up in order for a referral to be sucessful.  */
    @SerialName("expires_in")
    val expiresIn: Int?,

    /** Denotes whether this referral program is currently active. */
    @SerialName("enabled")
    val enabled: Boolean?,

    /** Denotes whether this referral program has enabled share links. Referral share links are links that the customer can send to prospective referrals. A User can sign up to the business through this link in order to complete the referral.  */
    @SerialName("link_referral_enabled")
    val linkReferralEnabled: Boolean?,

    /** A link that can be shared to prospective referrals. */
    @SerialName("referral_link")
    val referralLink: String?
)
