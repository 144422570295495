/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.postOfferFacebookShare

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.postOfferFacebookShare.OfferFacebookShareApi
import features.postOfferFacebookShare.models.UserProfileModel
import features.postOfferFacebookShare.serializeOfferFacebookShareState



/**
 * Notify Kangaroo that an offer has been shared to facebook [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("OfferFacebookShareApi")
class OfferFacebookShareApiJs {

    /**
     * Notify Kangaroo that an offer has been shared to facebook
     */
    fun postOfferFacebookShare(
        overrideHeaders: String?, 
        offerId: String,
        include: String = "balance",
        facebookUserId: String,
        type: String = "facebook_share",
        friendsCount: String = "1"
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = OfferFacebookShareApi().postOfferFacebookShare(
            overrideHeaders = decodedOverrideHeaders,
                        offerId = offerId,
            include = include,
            facebookUserId = facebookUserId,
            type = type,
            friendsCount = friendsCount
        ).toJsonResult<UserProfileModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeOfferFacebookShareState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        OfferFacebookShareApi().offerFacebookShareState.serializeOfferFacebookShareState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
