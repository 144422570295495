/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userRewards.internal

import features.userRewards.models.UserRewardsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userRewards.internal.UserRewardsUseCase.Params

internal class UserRewardsUseCase : AsyncUseCase<UserRewardsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val userRewardsService: UserRewardsService = UserRewardsServiceImpl()

    override suspend fun run(params: Params): Result<UserRewardsModel> {
        return userRewardsService.getUserRewards(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
