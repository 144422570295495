/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessFrequentBuyerPrograms.internal

import features.userBusinessFrequentBuyerPrograms.models.FrequentBuyerProgramsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessFrequentBuyerProgramsServiceImpl : UserBusinessFrequentBuyerProgramsService {

    private val userBusinessFrequentBuyerProgramsRequest = UserBusinessFrequentBuyerProgramsRequest()

    override suspend fun getUserBusinessFrequentBuyerPrograms(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<FrequentBuyerProgramsModel> {
        return userBusinessFrequentBuyerProgramsRequest.getUserBusinessFrequentBuyerPrograms(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
