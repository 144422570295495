/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.depositGiftCard

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.depositGiftCard.internal.GiftCardDepositUseCase
import features.depositGiftCard.internal.GiftCardDepositUseCase.Params
import features.depositGiftCard.models.TransferActionResultModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json


typealias GiftCardDepositState = CFlow<GiftCardDepositUseCaseResult?>

typealias GiftCardDepositUseCaseResult = Result<TransferActionResultModel>

fun GiftCardDepositUseCaseResult.serializeGiftCardDepositApiResult(): SerializedResult<String> {
    return this.toJsonResult<TransferActionResultModel>()
}

/**
 * Serializes [GiftCardDepositState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun GiftCardDepositState.serializeGiftCardDepositState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<TransferActionResultModel>()
    }.asCFlow()
}

/**
 * Deposit a giftcard [Api] class
 */
class GiftCardDepositApi : Api() {

    val giftCardDepositState: GiftCardDepositState
        get() = _giftCardDepositState.asCFlow()

    companion object {
        private val _giftCardDepositState: MutableStateFlow<GiftCardDepositUseCaseResult> = MutableStateFlow(Idle())
    }

    private val giftCardDepositUseCase = GiftCardDepositUseCase()

    /**
     * Deposit a giftcard
     */
    suspend fun depositGiftCard(
        overrideHeaders: Map<String, String>? = null,
        depositId: String
    ): Result<TransferActionResultModel> {
        /* set state to Loading as soon as function is called */
        _giftCardDepositState.value = Loading()
        val result = this.giftCardDepositUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                depositId = depositId
            )
        ) { result ->
            _giftCardDepositState.value = result
            return@giftCardDepositUseCase result
        }
        return result
    }

}
