/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userNotificationPreferences.internal

import features.userNotificationPreferences.models.NotificationSettingsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userNotificationPreferences.internal.GetNotificationPreferencesUseCase.Params

internal class GetNotificationPreferencesUseCase : AsyncUseCase<NotificationSettingsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val getNotificationPreferencesService: GetNotificationPreferencesService = GetNotificationPreferencesServiceImpl()

    override suspend fun run(params: Params): Result<NotificationSettingsModel> {
        return getNotificationPreferencesService.getNotificationPreferences(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
