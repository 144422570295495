@file:Suppress("unused", "MemberVisibilityCanBePrivate")
@file:OptIn(DelicateCoroutinesApi::class)

package js.features.userAuthentication

import kangaroorewards.appsdk.core.domain.SerializedResult
import features.userAuthentication.UserAuthenticationApi
import features.userAuthentication.models.UserAuthenticationModel
import features.userAuthentication.serializeUserAuthenticationState
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json

@ExperimentalJsExport
@JsExport
@JsName("UserAuthenticationApi")
class UserAuthenticationApiJs {
    fun authenticateUser(username: String?, password: String?, googleToken: String?, overrideHeaders: String?): Promise<String?> {
        return GlobalScope.promise {

                     val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
            } else {
                try {
                    Json.decodeFromString<Map<String, String>>(overrideHeaders)
                } catch (e: Exception) {
                    null
                }
            }

            val result = UserAuthenticationApi().authenticateUser(
                username, password, googleToken, decodedOverrideHeaders
            ).toJsonResult<UserAuthenticationModel>()

            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    fun observeUserAuthenticationSerialized(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserAuthenticationApi().userAuthenticationState.serializeUserAuthenticationState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("error forwarding stream")
            }
        }
    }
}