/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicOffer.internal

import features.publicOffer.models.PublicOfferResponseModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class PublicOfferRequest : NetworkRequest() {

    suspend fun getPublicOffer(
        overrideHeaders: Map<String, String>? = null,
        offerSlug: String
    ): IOResult<PublicOfferResponseModel> {
        return safeNetworkCall {
            get(
                endpoint = PublicOfferEndpoint.replaceInPath(
                    PathParameter("offerSlug", offerSlug),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
