/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userAlaCarte.internal

import features.userAlaCarte.models.AlaCarteProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userAlaCarte.internal.AlaCarteProductsUseCase.Params

internal class AlaCarteProductsUseCase : AsyncUseCase<AlaCarteProductsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val alaCarteProductsService: AlaCarteProductsService = AlaCarteProductsServiceImpl()

    override suspend fun run(params: Params): Result<AlaCarteProductsModel> {
        return alaCarteProductsService.getAlaCarteProducts(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
