/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.getPublicPromotions.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class PromotionsDataModel(
    /** id of the campaign. */
    @SerialName("id")
    val id: Int?,

    /** name of the campaign */
    @SerialName("name")
    val name: String?,

    /** A list of all offers available in this campaign */
    @SerialName("offers")
    val offers: List<OfferModel>?,

    /** A list of all rewards available in this campaign */
    @SerialName("rewards")
    val rewards: List<RewardModel>?
) : Model()
