/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userNotificationPreferencesUpdate

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userNotificationPreferencesUpdate.UpdateNotificationPreferencesApi
import features.userNotificationPreferencesUpdate.models.NotificationSettingsModel
import features.userNotificationPreferencesUpdate.serializeUpdateNotificationPreferencesState
import features.userNotificationPreferencesUpdate.models.NotificationSettingsUpdateRequest

import kotlinx.serialization.decodeFromString

/**
 * Update a users notification settings. This will enable or disable push, sms, and or email notifications from the selected business.  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UpdateNotificationPreferencesApi")
class UpdateNotificationPreferencesApiJs {

    /**
     * Update a users notification settings. This will enable or disable push, sms, and or email notifications from the selected business. 
     */


    
    fun updateNotificationPreferences(overrideHeaders: String?,
serializedNotificationPreferencesRequest: String): Promise<String?>  {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

        return GlobalScope.promise {
            val result = UpdateNotificationPreferencesApi().updateNotificationPreferences(
            overrideHeaders = decodedOverrideHeaders,
                notificationPreferencesRequest = Json.decodeFromString(
                    serializedNotificationPreferencesRequest
                )
            ).toJsonResult<NotificationSettingsModel>()

           return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    fun observeUpdateNotificationPreferencesState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UpdateNotificationPreferencesApi().updateNotificationPreferencesState.serializeUpdateNotificationPreferencesState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
