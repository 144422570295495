/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicOffer.internal

import features.publicOffer.models.PublicOfferResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.publicOffer.internal.PublicOfferUseCase.Params

internal class PublicOfferUseCase : AsyncUseCase<PublicOfferResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val offerSlug: String
    )

    private val publicOfferService: PublicOfferService = PublicOfferServiceImpl()

    override suspend fun run(params: Params): Result<PublicOfferResponseModel> {
        return publicOfferService.getPublicOffer(
            overrideHeaders = params.overrideHeaders,
            offerSlug = params.offerSlug
        )
    }
}
