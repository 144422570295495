/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userUnsubscribe.internal

import kangaroorewards.appsdk.core.io.EmptyResponse
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class UserUnsubscribeRequest : NetworkRequest() {

    suspend fun unsubscribe(
        overrideHeaders: Map<String, String>? = null,
        campaignId: String,
        token: String
    ): IOResult<EmptyResponse> {
        return safeNetworkCall {
            delete<EmptyResponse, Any>(
                endpoint = UserUnsubscribeEndpoint.replaceInPath(
                    PathParameter("campaignId", campaignId),
                    PathParameter("token", token),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
