/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.pushTokenRegistration

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.pushTokenRegistration.PushTokenRegistrationApi
import features.pushTokenRegistration.models.UserProfileModel
import features.pushTokenRegistration.serializePushTokenRegistrationState



/**
 * Send user Push notification token [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("PushTokenRegistrationApi")
class PushTokenRegistrationApiJs {

    /**
     * Send user Push notification token
     */
    fun registerPushToken(
        overrideHeaders: String?, 
        androidDeviceToken: String? = null,
        iosDeviceToken: String? = null
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = PushTokenRegistrationApi().registerPushToken(
            overrideHeaders = decodedOverrideHeaders,
                        androidDeviceToken = androidDeviceToken,
            iosDeviceToken = iosDeviceToken
        ).toJsonResult<UserProfileModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observePushTokenRegistrationState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        PushTokenRegistrationApi().pushTokenRegistrationState.serializePushTokenRegistrationState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
