/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.updateDefaultBusinessId

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.updateDefaultBusinessId.UpdateDefaultBusinessIdApi
import features.updateDefaultBusinessId.models.DefaultBusiness
import features.updateDefaultBusinessId.serializeUpdateDefaultBusinessIdState



/**
 * update the last business chosen by the user in the conglomerate feature [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UpdateDefaultBusinessIdApi")
class UpdateDefaultBusinessIdApiJs {

    /**
     * update the last business chosen by the user in the conglomerate feature
     */
    fun updateDefaultBusinessId(
        overrideHeaders: String?, 
        businessId: String,
        defaultBusinessId: String
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = UpdateDefaultBusinessIdApi().updateDefaultBusinessId(
            overrideHeaders = decodedOverrideHeaders,
                        businessId = businessId,
            defaultBusinessId = defaultBusinessId
        ).toJsonResult<DefaultBusiness>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeUpdateDefaultBusinessIdState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UpdateDefaultBusinessIdApi().updateDefaultBusinessIdState.serializeUpdateDefaultBusinessIdState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
