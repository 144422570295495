/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.makePayPalPayment.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A paypal payment response data 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class PayPalPayment(
    /** Unique payment ID. */
    @SerialName("id")
    val id: String?,

    @SerialName("provider")
    val provider: String?,

    @SerialName("status")
    val status: String?,

    @SerialName("approval_url")
    val approvalUrl: String?,

    /** A giftcard received from a paypal payment  */
    @SerialName("giftcard")
    val giftcard: PayPalPaymentGiftCard?
)
