/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userCampaignMessages.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Campaign Message. These are sent to the user by the business through Kangaroo's Marketing module. 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class CampaignMessage(
    /** Unique campaign ID. */
    @SerialName("id")
    val id: String?,

    /** The original delivery method for this campaign */
    @SerialName("cell_email")
    val cellEmail: Int?,

    /** The title for this campaign. */
    @SerialName("subject")
    val subject: String?,

    /** The original send date of this campaign. */
    @SerialName("sent_at")
    val sentAt: String?,

    /** If this campaign message is archived or not. */
    @SerialName("archived")
    val archived: Boolean?,

    /** A short / simple message for this campaign. */
    @SerialName("message")
    val message: String?,

    @SerialName("campaign")
    val campaign: Campaign?
)
