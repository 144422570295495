/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userTransferRecall.internal

import features.userTransferRecall.models.TransferActionResultModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userTransferRecall.internal.TransferRecallUseCase.Params

internal class TransferRecallUseCase : AsyncUseCase<TransferActionResultModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val recallId: String
    )

    private val transferRecallService: TransferRecallService = TransferRecallServiceImpl()

    override suspend fun run(params: Params): Result<TransferActionResultModel> {
        return transferRecallService.recallTransfer(
            overrideHeaders = params.overrideHeaders,
            recallId = params.recallId
        )
    }
}
