/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userBusinessUsedCoupons.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class UserBusinessCouponsData(
    /** Contains a list of all coupons available to this user */
    @SerialName("coupons")
    val coupons: UserCouponsData?
)
