/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userCheckIn.internal

import features.userCheckIn.models.CheckInRequest
import features.userCheckIn.models.CheckInResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userCheckIn.internal.UserCheckInUseCase.Params

internal class UserCheckInUseCase : AsyncUseCase<CheckInResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val checkInRequest: CheckInRequest
    )

    private val userCheckInService: UserCheckInService = UserCheckInServiceImpl()

    override suspend fun run(params: Params): Result<CheckInResponseModel> {
        return userCheckInService.userCheckIn(
            overrideHeaders = params.overrideHeaders,
            checkInRequest = params.checkInRequest
        )
    }
}
