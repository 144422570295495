/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.frequentBuyerPrograms

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.frequentBuyerPrograms.FrequentBuyerProgramsApi
import features.frequentBuyerPrograms.models.FrequentBuyerProgramsModel
import features.frequentBuyerPrograms.serializeFrequentBuyerProgramsState



/**
 * Get a list of frequent buyer programs for this business [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("FrequentBuyerProgramsApi")
class FrequentBuyerProgramsApiJs {

    /**
     * Get a list of frequent buyer programs for this business
     */
    fun getFrequentBuyerPrograms(
        overrideHeaders: String?, 

    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = FrequentBuyerProgramsApi().getFrequentBuyerPrograms(
            overrideHeaders = decodedOverrideHeaders,
            
        ).toJsonResult<FrequentBuyerProgramsModel>()
        
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeFrequentBuyerProgramsState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        FrequentBuyerProgramsApi().frequentBuyerProgramsState.serializeFrequentBuyerProgramsState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
