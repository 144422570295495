/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.redeemReward.internal

import features.redeemReward.models.RedeemRequest
import features.redeemReward.models.RedeemResponseModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class RedeemRewardsRequest : NetworkRequest() {

    suspend fun redeemReward(
        overrideHeaders: Map<String, String>? = null,
        redeemRequest: RedeemRequest
    ): IOResult<RedeemResponseModel> {
        return safeNetworkCall {
            post<RedeemResponseModel, RedeemRequest>(
                endpoint = RedeemRewardsEndpoint,
                requestBody = redeemRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
