/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.publicBranches.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class BranchPhone(
    @SerialName("number")
    val number: String?,

    @SerialName("country_code")
    val countryCode: String?,

    @SerialName("national_format")
    val nationalFormat: String?,

    @SerialName("intl_format")
    val intlFormat: String?
)
