/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.businessList.internal

import features.businessList.models.Businesses
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class BusinessesRequest : NetworkRequest() {

    suspend fun getBusinesses(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<Businesses> {
        return safeNetworkCall {
            get(
                endpoint = BusinessesEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
