/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.socialMediaLinks.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Social media links for this business
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class SocialMediaLinksModel(
    /** Contains all user data */
    @SerialName("data")
    val data: UserProfileDataModel?,

    /** Contains social media links */
    @SerialName("included")
    val included: SocialMediaLinksIncluded?
) : Model()
