/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.getPublicCampaign

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.getPublicCampaign.internal.GetPublicCampaignUseCase
import features.getPublicCampaign.internal.GetPublicCampaignUseCase.Params
import features.getPublicCampaign.models.PublicCampaignsResponseModel


typealias GetPublicCampaignState = CFlow<GetPublicCampaignUseCaseResult?>

typealias GetPublicCampaignUseCaseResult = Result<PublicCampaignsResponseModel>

fun GetPublicCampaignUseCaseResult.serializeGetPublicCampaignApiResult(): SerializedResult<String> {
    return this.toJsonResult<PublicCampaignsResponseModel>()
}

/**
 * Serializes [GetPublicCampaignState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun GetPublicCampaignState.serializeGetPublicCampaignState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PublicCampaignsResponseModel>()
    }.asCFlow()
}

/**
 * Get the offers / rewards for a related campaign [Api] class
 */
class GetPublicCampaignApi : Api() {

    val getPublicCampaignState: GetPublicCampaignState
        get() = _getPublicCampaignState.asCFlow()

    companion object {
        private val _getPublicCampaignState: MutableStateFlow<GetPublicCampaignUseCaseResult> = MutableStateFlow(Idle())
    }

    private val getPublicCampaignUseCase = GetPublicCampaignUseCase()

    /**
     * Get the offers / rewards for a related campaign
     */
    suspend fun getPublicCampaign(
        overrideHeaders: Map<String, String>? = null,
        campaignId: String
    ): Result<PublicCampaignsResponseModel> {
        /* set state to Loading as soon as function is called */
        _getPublicCampaignState.value = Loading()
        val result = this.getPublicCampaignUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                campaignId = campaignId
            )
        ) { result ->
            _getPublicCampaignState.value = result
            return@getPublicCampaignUseCase result
        }
        return result
    }

}
