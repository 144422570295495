/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.getSpinToWinPrizes.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class SpinToWinPrizesData(
    /** spin to win id */
    @SerialName("spin_win_id")
    val spinWinId: Int?,

    /** List of prizes */
    @SerialName("spin_prizes")
    val spinPrizes: List<PrizeModel>?
)
