/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBalanceTransfer.internal

import features.userBalanceTransfer.models.TransferRequestModel
import features.userBalanceTransfer.models.TransferResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userBalanceTransfer.internal.BalanceTransferUseCase.Params

internal class BalanceTransferUseCase : AsyncUseCase<TransferResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val transferRequest: TransferRequestModel
    )

    private val balanceTransferService: BalanceTransferService = BalanceTransferServiceImpl()

    override suspend fun run(params: Params): Result<TransferResponseModel> {
        return balanceTransferService.transfer(
            overrideHeaders = params.overrideHeaders,
            transferRequest = params.transferRequest
        )
    }
}
