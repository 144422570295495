/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.makePayPalPayment.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A giftcard received from a paypal payment 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class PayPalPaymentGiftCard(
    /** Unique gift card ID. */
    @SerialName("id")
    val id: Int?,

    @SerialName("title")
    val title: String?,

    @SerialName("price")
    val price: Float?,

    @SerialName("currency")
    val currency: String?,

    @SerialName("quantity")
    val quantity: Int?
)
