/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.banners.internal

import features.banners.models.BannersModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class BannersRequest : NetworkRequest() {

    suspend fun getBanners(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<BannersModel> {
        return safeNetworkCall {
            get(
                endpoint = BannersEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
