/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessTiers.internal

import features.userBusinessTiers.models.UserProfileWithTierProgram
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userBusinessTiers.internal.UserBusinessTiersUseCase.Params

internal class UserBusinessTiersUseCase : AsyncUseCase<UserProfileWithTierProgram, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val memberBusinessId: String
    )

    private val userBusinessTiersService: UserBusinessTiersService = UserBusinessTiersServiceImpl()

    override suspend fun run(params: Params): Result<UserProfileWithTierProgram> {
        return userBusinessTiersService.getUserBusinessTiers(
            overrideHeaders = params.overrideHeaders,
            memberBusinessId = params.memberBusinessId
        )
    }
}
