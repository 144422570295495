/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userPinResetWithVerificationCode.internal

import kangaroorewards.appsdk.core.io.EmptyResponse
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField

internal class UserPinResetRequest : NetworkRequest() {

    suspend fun resetPin(
        overrideHeaders: Map<String, String>? = null,
        verificationCode: String,
        pinCode: String,
        email: String?,
        phone: String?,
        countryCode: String?
    ): IOResult<EmptyResponse> {
        return safeNetworkCall {
            post<EmptyResponse, Any>(
                endpoint = UserPinResetEndpoint,
                fields = listOf(
                    FormField(name = "verification_code", value = verificationCode),
                    FormField(name = "pin_code", value = pinCode),
                    FormField(name = "email", value = email),
                    FormField(name = "phone", value = phone),
                    FormField(name = "country_code", value = countryCode)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
