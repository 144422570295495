/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userTransactionHistory.internal

import features.userTransactionHistory.models.UserTransactionHistoryModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserTransactionHistoryServiceImpl : UserTransactionHistoryService {

    private val userTransactionHistoryRequest = UserTransactionHistoryRequest()

    override suspend fun getUserTransactionHistory(
        overrideHeaders : Map<String, String>?,
    ): Result<UserTransactionHistoryModel> {
        return userTransactionHistoryRequest.getUserTransactionHistory(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
