/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userRegistrationWithCrm.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class UserAddress(
    @SerialName("street")
    val street: String?,

    @SerialName("city")
    val city: String?,

    @SerialName("region")
    val region: String?,

    @SerialName("country")
    val country: String?,

    @SerialName("country_code")
    val countryCode: String?,

    @SerialName("zip_code")
    val zipCode: String?
)
