/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.postOfferFacebookShare.internal

import features.postOfferFacebookShare.models.UserProfileModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField
import kangaroorewards.appsdk.core.network.base.Query
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class OfferFacebookShareRequest : NetworkRequest() {

    suspend fun postOfferFacebookShare(
        overrideHeaders: Map<String, String>? = null,
        offerId: String,
        include: String,
        facebookUserId: String,
        type: String,
        friendsCount: String
    ): IOResult<UserProfileModel> {
        return safeNetworkCall {
            post<UserProfileModel, Any>(
                endpoint = OfferFacebookShareEndpoint.replaceInPath(
                    PathParameter("offerId", offerId),
                ),
                queries = listOf(
                    Query(name = "include", value = include)),
                fields = listOf(
                    FormField(name = "facebook_user_id", value = facebookUserId),
                    FormField(name = "type", value = type),
                    FormField(name = "friends_count", value = friendsCount)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
