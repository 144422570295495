/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.publicBusinessSettings.internal

import features.publicBusinessSettings.models.PublicBusinessSettings
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PublicBusinessSettingsServiceImpl : PublicBusinessSettingsService {

    private val publicBusinessSettingsRequest = PublicBusinessSettingsRequest()

    override suspend fun getPublicBusinessSettings(
        overrideHeaders : Map<String, String>?,
    ): Result<PublicBusinessSettings> {
        return publicBusinessSettingsRequest.getPublicBusinessSettings(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
