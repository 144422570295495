/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userCoupons.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class CouponModel(
    /** A unique ID identifying this coupon */
    @SerialName("id")
    val id: Int?,

    /** The coupon title. */
    @SerialName("title")
    val title: String?,

    /** The coupon description. */
    @SerialName("description")
    val description: String?,

    /** The terms and conditions attached to this coupon. */
    @SerialName("terms_conditions")
    val termsConditions: String?,

    @SerialName("qrcode")
    val qrcode: String?,

    @SerialName("coupon_locked")
    val couponLocked: Boolean?,

    @SerialName("coupon_redeemed")
    val couponRedeemed: Boolean?,

    @SerialName("coupon_type")
    val couponType: String?,

    @SerialName("expires_at")
    val expiresAt: String?,

    @SerialName("offer")
    val offer: OfferModel?
) : Model()
