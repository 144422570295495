/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userProfileUpdate

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userProfileUpdate.UserProfileUpdateApi
import features.userProfileUpdate.models.UserProfileModel
import features.userProfileUpdate.serializeUserProfileUpdateState



/**
 * Update a users' profile [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UserProfileUpdateApi")
class UserProfileUpdateApiJs {

    /**
     * Update a users' profile
     */
    fun updateUserProfile(
        overrideHeaders: String?, 
        firstName: String? = null,
        lastName: String? = null,
        birthDate: String? = null,
        language: String? = null,
        gender: String? = null,
        profilePhoto: String? = null
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = UserProfileUpdateApi().updateUserProfile(
            overrideHeaders = decodedOverrideHeaders,
                        firstName = firstName,
            lastName = lastName,
            birthDate = birthDate,
            language = language,
            gender = gender,
            profilePhoto = profilePhoto
        ).toJsonResult<UserProfileModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeUserProfileUpdateState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserProfileUpdateApi().userProfileUpdateState.serializeUserProfileUpdateState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
