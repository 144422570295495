/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userPinResetWithToken

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userPinResetWithToken.UserPinResetWithTokenApi

import features.userPinResetWithToken.serializeUserPinResetWithTokenState
import kangaroorewards.appsdk.core.io.EmptyResponse
import features.userPinResetWithToken.models.PinResetWithTokenRequestModel


/**
 * Reset a customer PIN. Requires both a token and either a phone number or email address  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UserPinResetWithTokenApi")
class UserPinResetWithTokenApiJs {

    /**
     * Reset a customer PIN. Requires both a token and either a phone number or email address 
     */


    
    fun resetPinWithToken(overrideHeaders: String?,
serializedPinResetWithTokenRequest: String): Promise<String?>  {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

        return GlobalScope.promise {
            val result = UserPinResetWithTokenApi().resetPinWithToken(
            overrideHeaders = decodedOverrideHeaders,
                pinResetWithTokenRequest = Json.decodeFromString(
                    serializedPinResetWithTokenRequest
                )
            ).toJsonResult<EmptyResponse>()

           return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    fun observeUserPinResetWithTokenState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserPinResetWithTokenApi().userPinResetWithTokenState.serializeUserPinResetWithTokenState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.EmptyResponse -> onData(Json.encodeToString(EmptyResponse.serializer(), EmptyResponse))
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
