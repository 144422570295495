/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userProfile.internal

import features.userProfile.models.UserProfileModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class UserProfileRequest : NetworkRequest() {

    suspend fun getUserProfile(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<UserProfileModel> {
        return safeNetworkCall {
            get(
                endpoint = UserProfileEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
