/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userCoupons.internal

import features.userCoupons.models.UserCouponsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userCoupons.internal.UserCouponsUseCase.Params

internal class UserCouponsUseCase : AsyncUseCase<UserCouponsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val userCouponsService: UserCouponsService = UserCouponsServiceImpl()

    override suspend fun run(params: Params): Result<UserCouponsModel> {
        return userCouponsService.getUserCoupons(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
