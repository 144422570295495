/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.frequentBuyerPrograms.internal

import features.frequentBuyerPrograms.models.FrequentBuyerProgramsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class FrequentBuyerProgramsServiceImpl : FrequentBuyerProgramsService {

    private val frequentBuyerProgramsRequest = FrequentBuyerProgramsRequest()

    override suspend fun getFrequentBuyerPrograms(
        overrideHeaders : Map<String, String>?,
    ): Result<FrequentBuyerProgramsModel> {
        return frequentBuyerProgramsRequest.getFrequentBuyerPrograms(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
