/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.archiveInboxItem.internal

import features.archiveInboxItem.models.ArchiveRequest
import features.archiveInboxItem.models.ArchiveResponseModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class ArchiveInboxItemRequest : NetworkRequest() {

    suspend fun archiveInboxItem(
        overrideHeaders: Map<String, String>? = null,
        archiveRequest: ArchiveRequest
    ): IOResult<ArchiveResponseModel> {
        return safeNetworkCall {
            post<ArchiveResponseModel, ArchiveRequest>(
                endpoint = ArchiveInboxItemEndpoint,
                requestBody = archiveRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
