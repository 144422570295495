/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.tiers.internal

import features.tiers.models.UserProfileWithTierProgram
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class TiersRequest : NetworkRequest() {

    suspend fun getTiers(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<UserProfileWithTierProgram> {
        return safeNetworkCall {
            get(
                endpoint = TiersEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
