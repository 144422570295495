/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.businessList.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A list of businesses associated with this user and this app. Usually there will only be one business. 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class Businesses(
    /** A list of businesses for this user and this app. */
    @SerialName("data")
    val data: List<BusinessData>?
) : Model()
