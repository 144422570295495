/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.postOfferFacebookShare.internal

import features.postOfferFacebookShare.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class OfferFacebookShareServiceImpl : OfferFacebookShareService {

    private val offerFacebookShareRequest = OfferFacebookShareRequest()

    override suspend fun postOfferFacebookShare(
        overrideHeaders : Map<String, String>?,
        offerId: String,
        include: String,
        facebookUserId: String,
        type: String,
        friendsCount: String    ): Result<UserProfileModel> {
        return offerFacebookShareRequest.postOfferFacebookShare(
            overrideHeaders = overrideHeaders,
            offerId = offerId,
            include = include,
            facebookUserId = facebookUserId,
            type = type,
            friendsCount = friendsCount        ).toModel()
    }
}
