/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.getCrmFields.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * CRM Field data
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class CrmFieldData(
    @SerialName("id")
    val id: Int?,

    @SerialName("name")
    val name: String?,

    @SerialName("label")
    val label: String?,

    @SerialName("value")
    val value: String?,

    @SerialName("is_hidden")
    val isHidden: Boolean?,

    @SerialName("is_required")
    val isRequired: Boolean?,

    @SerialName("field_type")
    val fieldType: String?,

    @SerialName("is_custom_field")
    val isCustomField: Boolean?,

    @SerialName("display_order")
    val displayOrder: Int?
)
