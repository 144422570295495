/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicRewards.internal

import features.publicRewards.models.PublicRewardsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.publicRewards.internal.PublicRewardsUseCase.Params

internal class PublicRewardsUseCase : AsyncUseCase<PublicRewardsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val publicRewardsService: PublicRewardsService = PublicRewardsServiceImpl()

    override suspend fun run(params: Params): Result<PublicRewardsModel> {
        return publicRewardsService.getPublicRewards(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
