/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userVerification

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userVerification.UserAccountVerificationApi
import features.userVerification.models.UserVerificationResponseModel
import features.userVerification.serializeUserAccountVerificationState



/**
 * Verify a user's account  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UserAccountVerificationApi")
class UserAccountVerificationApiJs {

    /**
     * Verify a user's account 
     */
    fun verifyAccount(
        overrideHeaders: String?, 
        token: String,
        email: String? = null,
        phone: String? = null,
        countryCode: String? = null
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = UserAccountVerificationApi().verifyAccount(
            overrideHeaders = decodedOverrideHeaders,
                        token = token,
            email = email,
            phone = phone,
            countryCode = countryCode
        ).toJsonResult<UserVerificationResponseModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeUserAccountVerificationState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserAccountVerificationApi().userAccountVerificationState.serializeUserAccountVerificationState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
