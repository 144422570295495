/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.publicBanners.internal

import features.publicBanners.models.BannersModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PublicBannersServiceImpl : PublicBannersService {

    private val publicBannersRequest = PublicBannersRequest()

    override suspend fun getPublicBanners(
        overrideHeaders : Map<String, String>?,
    ): Result<BannersModel> {
        return publicBannersRequest.getPublicBanners(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
