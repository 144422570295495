/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.redeemReward.internal

import features.redeemReward.models.RedeemRequest
import features.redeemReward.models.RedeemResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.redeemReward.internal.RedeemRewardsUseCase.Params

internal class RedeemRewardsUseCase : AsyncUseCase<RedeemResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val redeemRequest: RedeemRequest
    )

    private val redeemRewardsService: RedeemRewardsService = RedeemRewardsServiceImpl()

    override suspend fun run(params: Params): Result<RedeemResponseModel> {
        return redeemRewardsService.redeemReward(
            overrideHeaders = params.overrideHeaders,
            redeemRequest = params.redeemRequest
        )
    }
}
