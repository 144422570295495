/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.draws.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A draw branch.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class DrawBranches(
    /** Unique branch id. */
    @SerialName("id")
    val id: String?,

    @SerialName("name")
    val name: String?
)
