/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.business.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class BranchAddress(
    @SerialName("formatted")
    val formatted: String?,

    @SerialName("street")
    val street: String?,

    @SerialName("city")
    val city: String?,

    @SerialName("region")
    val region: String?,

    @SerialName("country")
    val country: String?,

    @SerialName("lat")
    val lat: Float?,

    @SerialName("long")
    val long: Float?
)
