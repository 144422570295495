/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.getPublicPromotions.internal

import features.getPublicPromotions.models.PromotionsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GetPublicPromotionsServiceImpl : GetPublicPromotionsService {

    private val getPublicPromotionsRequest = GetPublicPromotionsRequest()

    override suspend fun getPublicPromotions(
        overrideHeaders : Map<String, String>?,
        campaignId: String    ): Result<PromotionsModel> {
        return getPublicPromotionsRequest.getPublicPromotions(
            overrideHeaders = overrideHeaders,
            campaignId = campaignId        ).toModel()
    }
}
