package kangaroorewards.appsdk.core.network.base

import kotlin.jvm.JvmInline

/**
 * Base endpoint Class. Paths are relative.
 *
 * @param path the path to the specified endpoint eg users/me/rewards.
 */
@JvmInline
value class Endpoint(val path: String)

data class PathParameter(
    val subPath: String,
    val value: Any?
)

fun Endpoint.replaceInPath(vararg extraPaths: PathParameter): Endpoint {
    var newPath = this.path
    extraPaths.forEach {
        newPath = newPath.replace("{${it.subPath}}", it.value.toString())
    }
    return Endpoint(newPath)
}