/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.redeemReward.internal

import features.redeemReward.models.RedeemRequest
import features.redeemReward.models.RedeemResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class RedeemRewardsServiceImpl : RedeemRewardsService {

    private val redeemRewardsRequest = RedeemRewardsRequest()

    override suspend fun redeemReward(
        overrideHeaders : Map<String, String>?,
        redeemRequest: RedeemRequest    ): Result<RedeemResponseModel> {
        return redeemRewardsRequest.redeemReward(
            overrideHeaders = overrideHeaders,
            redeemRequest = redeemRequest        ).toModel()
    }
}
