package kangaroorewards.appsdk.core.io

import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.ResultMetaData
import kotlinx.serialization.Serializable

sealed class IOResult<out T : Model?> {
    @Serializable
    class Loading(
        val state: ResultMetaData = ResultMetaData(
            type = "Loading",
            code = -1,
            msg = "result is loading"
        )
    ) : IOResult<Nothing>()

    @Serializable
    data class Idle(
        val state: ResultMetaData = ResultMetaData(
            type = "Idle",
            code = -1,
            msg = "result is idle"
        )
    ) : IOResult<Nothing>()

    @Serializable
    data class EmptyResponse(
        val body: String = ""
    ) : IOResult<Nothing>()

    abstract class Error(
        open val error: ResultMetaData
    ) : IOResult<Nothing>()

    data class UnauthorizedError(
        override val error: ResultMetaData,
    ) : Error(error)

    data class ConnectionError(
        override val error: ResultMetaData,
    ) : Error(error)

    data class UnknownError(
        override val error: ResultMetaData,
    ) : Error(error)

    data class TimeoutError(
        override val error: ResultMetaData,
    ) : Error(error)

    data class Success<out T : Model>(val data: T) : IOResult<T>()
}

/**
 * Maps IOResult to Result, along with possible errors. Errors should be mapped
 * to relevant equivalents in the Domain layer.
 */
fun <T : Model> IOResult<T>.toModel(): Result<T> {
    return when (this) {
        is IOResult.Success -> Result.Success(data)
        is IOResult.Idle -> Result.Idle(state)
        is IOResult.Loading -> Result.Loading(state)
        is IOResult.UnauthorizedError -> Result.UnauthorizedError(error)
        is IOResult.UnknownError -> Result.UnknownError(error)
        is IOResult.EmptyResponse -> return Result.EmptyResponse(body)
        else -> Result.UnknownError(
            ResultMetaData(
                type = this::class.simpleName ?: "unknown Result",
                code = -1,
                msg = "unknown error"
            )
        )
    }
}