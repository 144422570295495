/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.claimOffer.internal

import features.claimOffer.models.ClaimedOfferModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class ClaimOfferServiceImpl : ClaimOfferService {

    private val claimOfferRequest = ClaimOfferRequest()

    override suspend fun claimOffer(
        overrideHeaders : Map<String, String>?,
        offerId: Int,
        customerId: String    ): Result<ClaimedOfferModel> {
        return claimOfferRequest.claimOffer(
            overrideHeaders = overrideHeaders,
            offerId = offerId,
            customerId = customerId        ).toModel()
    }
}
