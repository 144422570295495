/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userRegistrationWithCrm

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userRegistrationWithCrm.UserRegistrationWithCrmApi
import features.userRegistrationWithCrm.models.UserProfileDataModel
import features.userRegistrationWithCrm.serializeUserRegistrationWithCrmState
import features.userRegistrationWithCrm.models.CrmRegisterRequest

import kotlinx.serialization.decodeFromString

/**
 * Register a new user using the crm settings. [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UserRegistrationWithCrmApi")
class UserRegistrationWithCrmApiJs {

    /**
     * Register a new user using the crm settings.
     */


    
    fun createAccountWithCrm(overrideHeaders: String?,
serializedRegisterWithCrmRequest: String): Promise<String?>  {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

        return GlobalScope.promise {
            val result = UserRegistrationWithCrmApi().createAccountWithCrm(
            overrideHeaders = decodedOverrideHeaders,
                registerWithCrmRequest = Json.decodeFromString(
                    serializedRegisterWithCrmRequest
                )
            ).toJsonResult<UserProfileDataModel>()

           return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    fun observeUserRegistrationWithCrmState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserRegistrationWithCrmApi().userRegistrationWithCrmState.serializeUserRegistrationWithCrmState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
