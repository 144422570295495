/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userVerification.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents a users' tier progress within their current tier. This also includes other useful information about the tier such as start and end dates, as well as the next tier the user will reach. 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class TierProgress(
    /** The start date for this tier session. */
    @SerialName("start_date")
    val startDate: String?,

    /** The end date for this tier session. The tier will be reset once this date is reached.  */
    @SerialName("end_date")
    val endDate: String?,

    /** The name of the user&#39;s current tier level  */
    @SerialName("tier_level")
    val tierLevel: String?,

    /** The name of the user&#39;s current tier level. Tiers are recalculated once per day, as such \&quot;tier_level\&quot; may not be completely up to date.  */
    @SerialName("tier_level_live")
    val tierLevelLive: String?,

    /** The name of the user&#39;s next tier level.  */
    @SerialName("next_tier_level")
    val nextTierLevel: String?,

    /** The users current tier progress in their \&quot;tier_level\&quot;. This will be a number between 0 and 1. A tier progress of 0.5 means that the user has made 50% progress in their current tier.  */
    @SerialName("tier_progress")
    val tierProgress: Float?,

    /** The amount of points left in the user&#39;s current tier progress. Once they have acquired this many more amount of points, they will be moved into the next tier.  */
    @SerialName("left_points")
    val leftPoints: Int?,

    /** The amount of spend left in the user&#39;s current tier progress. Once they have acquired this many more amount of spend, they will be moved into the next tier.  */
    @SerialName("left_amount")
    val leftAmount: Float?,

    /** The amount of visits left in the user&#39;s current tier progress. Once they have acquired this many more amount of visits, they will be moved into the next tier.  */
    @SerialName("left_visits")
    val leftVisits: Int?,

    /** Purchase points between start_date and end_date.  */
    @SerialName("total_points")
    val totalPoints: Int?,

    /** Purchase amount between start_date and end_date.  */
    @SerialName("total_amount")
    val totalAmount: Float?,

    /** Purchase visits between start_date and end_date.  */
    @SerialName("total_visits")
    val totalVisits: Int?
)
