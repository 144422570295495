/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.getDefaultBusinessId.internal

import features.getDefaultBusinessId.models.DefaultBusiness
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GetDefaultBusinessIdServiceImpl : GetDefaultBusinessIdService {

    private val getDefaultBusinessIdRequest = GetDefaultBusinessIdRequest()

    override suspend fun getDefaultBusinessId(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<DefaultBusiness> {
        return getDefaultBusinessIdRequest.getDefaultBusinessId(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
