/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicReward.internal

import features.publicReward.models.PublicRewardResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.publicReward.internal.PublicRewardUseCase.Params

internal class PublicRewardUseCase : AsyncUseCase<PublicRewardResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val rewardSlug: String
    )

    private val publicRewardService: PublicRewardService = PublicRewardServiceImpl()

    override suspend fun run(params: Params): Result<PublicRewardResponseModel> {
        return publicRewardService.getPublicReward(
            overrideHeaders = params.overrideHeaders,
            rewardSlug = params.rewardSlug
        )
    }
}
