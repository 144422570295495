/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessOffers.internal

import features.userBusinessOffers.models.UserBusinessOffersModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessOffersServiceImpl : UserBusinessOffersService {

    private val userBusinessOffersRequest = UserBusinessOffersRequest()

    override suspend fun getUserBusinessOffers(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<UserBusinessOffersModel> {
        return userBusinessOffersRequest.getUserBusinessOffers(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
