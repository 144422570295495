/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.performSocialMediaAction

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.performSocialMediaAction.SocialMediaActionPerformApi
import features.performSocialMediaAction.models.PerformSocialMediaActionResponseModel
import features.performSocialMediaAction.serializeSocialMediaActionPerformState
import features.performSocialMediaAction.models.PerformSocialMediaActionRequestModel


import kotlinx.serialization.decodeFromString

/**
 * To perform a social media action and get rewarded after.  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("SocialMediaActionPerformApi")
class SocialMediaActionPerformApiJs {

    /**
     * To perform a social media action and get rewarded after. 
     */


    

    
    fun performSocialMediaAction(
    overrideHeaders: String?,
        serializedPerformSocialMediaActionRequest: String,
        businessId: String
    ): Promise<String?> {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

        return GlobalScope.promise {
            val result = SocialMediaActionPerformApi().performSocialMediaAction(
        overrideHeaders = decodedOverrideHeaders,
        performSocialMediaActionRequest = Json.decodeFromString(
                    serializedPerformSocialMediaActionRequest
                ),
businessId = businessId
            ).toJsonResult<PerformSocialMediaActionResponseModel>()

        return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    fun observeSocialMediaActionPerformState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        SocialMediaActionPerformApi().socialMediaActionPerformState.serializeSocialMediaActionPerformState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
