/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessSocialMediaLinks.internal

import features.userBusinessSocialMediaLinks.models.SocialMediaLinksModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessSocialMediaLinksServiceImpl : UserBusinessSocialMediaLinksService {

    private val userBusinessSocialMediaLinksRequest = UserBusinessSocialMediaLinksRequest()

    override suspend fun getUserBusinessSocialMediaLinks(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<SocialMediaLinksModel> {
        return userBusinessSocialMediaLinksRequest.getUserBusinessSocialMediaLinks(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
