/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.getPublicPromotions.internal

import features.getPublicPromotions.models.PromotionsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.getPublicPromotions.internal.GetPublicPromotionsUseCase.Params

internal class GetPublicPromotionsUseCase : AsyncUseCase<PromotionsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val campaignId: String
    )

    private val getPublicPromotionsService: GetPublicPromotionsService = GetPublicPromotionsServiceImpl()

    override suspend fun run(params: Params): Result<PromotionsModel> {
        return getPublicPromotionsService.getPublicPromotions(
            overrideHeaders = params.overrideHeaders,
            campaignId = params.campaignId
        )
    }
}
