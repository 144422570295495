/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.draws.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A business banner.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class DrawRule(
    /** Unique draw rule id. */
    @SerialName("draw_rule_id")
    val drawRuleId: Int?,

    /** If this draw rule is required or not. */
    @SerialName("required")
    val required: Boolean?,

    /** If this draw rule is completed or not. */
    @SerialName("completed")
    val completed: Boolean?,

    @SerialName("languages")
    val languages: List<Languages>?,

    /** The draw rule title. */
    @SerialName("text")
    val text: String?,

    @SerialName("conditions")
    val conditions: List<DrawRuleCondition>?
)
