/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userProfileUpdate.internal

import features.userProfileUpdate.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userProfileUpdate.internal.UserProfileUpdateUseCase.Params

internal class UserProfileUpdateUseCase : AsyncUseCase<UserProfileModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val firstName: String?,
        val lastName: String?,
        val birthDate: String?,
        val language: String?,
        val gender: String?,
        val profilePhoto: String?
    )

    private val userProfileUpdateService: UserProfileUpdateService = UserProfileUpdateServiceImpl()

    override suspend fun run(params: Params): Result<UserProfileModel> {
        return userProfileUpdateService.updateUserProfile(
            overrideHeaders = params.overrideHeaders,
            firstName = params.firstName,
            lastName = params.lastName,
            birthDate = params.birthDate,
            language = params.language,
            gender = params.gender,
            profilePhoto = params.profilePhoto
        )
    }
}
