/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.tiers.internal

import features.tiers.models.UserProfileWithTierProgram
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class TiersServiceImpl : TiersService {

    private val tiersRequest = TiersRequest()

    override suspend fun getTiers(
        overrideHeaders : Map<String, String>?,
    ): Result<UserProfileWithTierProgram> {
        return tiersRequest.getTiers(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
