/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userPinUpdate

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userPinUpdate.internal.UserPinUpdateUseCase
import features.userPinUpdate.internal.UserPinUpdateUseCase.Params
import features.userPinUpdate.models.UserProfileModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.userPinUpdate.models.UpdatePinRequest

typealias UserPinUpdateState = CFlow<UserPinUpdateUseCaseResult?>

typealias UserPinUpdateUseCaseResult = Result<UserProfileModel>

fun UserPinUpdateUseCaseResult.serializeUserPinUpdateApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProfileModel>()
}

/**
 * Serializes [UserPinUpdateState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserPinUpdateState.serializeUserPinUpdateState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProfileModel>()
    }.asCFlow()
}

/**
 * Update a users' PIN [Api] class
 */
class UserPinUpdateApi : Api() {

    val userPinUpdateState: UserPinUpdateState
        get() = _userPinUpdateState.asCFlow()

    companion object {
        private val _userPinUpdateState: MutableStateFlow<UserPinUpdateUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userPinUpdateUseCase = UserPinUpdateUseCase()

    /**
     * Update a users' PIN
     */
    suspend fun updatePin(
        overrideHeaders: Map<String, String>? = null,
        updatePinRequest: UpdatePinRequest
    ): Result<UserProfileModel> {
        /* set state to Loading as soon as function is called */
        _userPinUpdateState.value = Loading()
        val result = this.userPinUpdateUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                updatePinRequest = updatePinRequest
            )
        ) { result ->
            _userPinUpdateState.value = result
            return@userPinUpdateUseCase result
        }
        return result
    }

    /**
    * Update a users' PIN
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun updatePin(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>
    
    ): Result<UserProfileModel> {
        return updatePin(
            overrideHeaders = overrideHeaders,
            updatePinRequest = Json.decodeFromString(methods.getValue("updatePinRequest") as String)
        )
    }
}
