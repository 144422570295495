/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userProfileUpdate.internal

import features.userProfileUpdate.models.UserProfileModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField

internal class UserProfileUpdateRequest : NetworkRequest() {

    suspend fun updateUserProfile(
        overrideHeaders: Map<String, String>? = null,
        firstName: String?,
        lastName: String?,
        birthDate: String?,
        language: String?,
        gender: String?,
        profilePhoto: String?
    ): IOResult<UserProfileModel> {
        return safeNetworkCall {
            patch<UserProfileModel, Any>(
                endpoint = UserProfileUpdateEndpoint,
                fields = listOf(
                    FormField(name = "first_name", value = firstName),
                    FormField(name = "last_name", value = lastName),
                    FormField(name = "birth_date", value = birthDate),
                    FormField(name = "language", value = language),
                    FormField(name = "gender", value = gender),
                    FormField(name = "profile_photo", value = profilePhoto)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
