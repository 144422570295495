/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userTransferMessages.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Contains a list of transfer messages sent to this user
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class TransferMessagesModel(
    /** List of transfer messages. */
    @SerialName("data")
    val data: List<TransferMessage>?
) : Model()
