/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userDelete.internal

import kangaroorewards.appsdk.core.io.EmptyResponse
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class UserDeleteRequest : NetworkRequest() {

    suspend fun deleteUserAccount(
        overrideHeaders: Map<String, String>? = null,

    ): IOResult<EmptyResponse> {
        return safeNetworkCall {
            delete<EmptyResponse, Any>(
                endpoint = UserDeleteEndpoint,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
