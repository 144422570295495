/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.makePayPalPayment.internal

import features.makePayPalPayment.models.PayPalPaymentModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.makePayPalPayment.internal.PayPalPaymentUseCase.Params

internal class PayPalPaymentUseCase : AsyncUseCase<PayPalPaymentModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val intent: String,
        val provider: String,
        val giftcardId: Int,
        val paypalReturnUrl: String,
        val paypalCancelUrl: String
    )

    private val payPalPaymentService: PayPalPaymentService = PayPalPaymentServiceImpl()

    override suspend fun run(params: Params): Result<PayPalPaymentModel> {
        return payPalPaymentService.makePayPalPayment(
            overrideHeaders = params.overrideHeaders,
            intent = params.intent,
            provider = params.provider,
            giftcardId = params.giftcardId,
            paypalReturnUrl = params.paypalReturnUrl,
            paypalCancelUrl = params.paypalCancelUrl
        )
    }
}
