/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessFrequentBuyerPrograms.internal

import features.userBusinessFrequentBuyerPrograms.models.FrequentBuyerProgramsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userBusinessFrequentBuyerPrograms.internal.UserBusinessFrequentBuyerProgramsUseCase.Params

internal class UserBusinessFrequentBuyerProgramsUseCase : AsyncUseCase<FrequentBuyerProgramsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val userBusinessFrequentBuyerProgramsService: UserBusinessFrequentBuyerProgramsService = UserBusinessFrequentBuyerProgramsServiceImpl()

    override suspend fun run(params: Params): Result<FrequentBuyerProgramsModel> {
        return userBusinessFrequentBuyerProgramsService.getUserBusinessFrequentBuyerPrograms(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
