/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.redeemReward

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.redeemReward.RedeemRewardsApi
import features.redeemReward.models.RedeemResponseModel
import features.redeemReward.serializeRedeemRewardsState
import features.redeemReward.models.RedeemRequest

import kotlinx.serialization.decodeFromString

/**
 * Redeem a set of rewards. This action costs points which will be deducted from the users' balance. Only Partner Rewards can be redeemed from the customer api.  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("RedeemRewardsApi")
class RedeemRewardsApiJs {

    /**
     * Redeem a set of rewards. This action costs points which will be deducted from the users' balance. Only Partner Rewards can be redeemed from the customer api. 
     */


    
    fun redeemReward(overrideHeaders: String?,
serializedRedeemRequest: String): Promise<String?>  {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

        return GlobalScope.promise {
            val result = RedeemRewardsApi().redeemReward(
            overrideHeaders = decodedOverrideHeaders,
                redeemRequest = Json.decodeFromString(
                    serializedRedeemRequest
                )
            ).toJsonResult<RedeemResponseModel>()

           return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    fun observeRedeemRewardsState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        RedeemRewardsApi().redeemRewardsState.serializeRedeemRewardsState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
