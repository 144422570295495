/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userNotificationPreferencesUpdate.internal

import features.userNotificationPreferencesUpdate.models.NotificationSettingsModel
import features.userNotificationPreferencesUpdate.models.NotificationSettingsUpdateRequest
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UpdateNotificationPreferencesServiceImpl : UpdateNotificationPreferencesService {

    private val updateNotificationPreferencesRequest = UpdateNotificationPreferencesRequest()

    override suspend fun updateNotificationPreferences(
        overrideHeaders : Map<String, String>?,
        notificationPreferencesRequest: NotificationSettingsUpdateRequest    ): Result<NotificationSettingsModel> {
        return updateNotificationPreferencesRequest.updateNotificationPreferences(
            overrideHeaders = overrideHeaders,
            notificationPreferencesRequest = notificationPreferencesRequest        ).toModel()
    }
}
