/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userPinResetWithVerificationCode.internal

import kangaroorewards.appsdk.core.io.EmptyResponse
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userPinResetWithVerificationCode.internal.UserPinResetUseCase.Params

internal class UserPinResetUseCase : AsyncUseCase<EmptyResponse, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val verificationCode: String,
        val pinCode: String,
        val email: String?,
        val phone: String?,
        val countryCode: String?
    )

    private val userPinResetService: UserPinResetService = UserPinResetServiceImpl()

    override suspend fun run(params: Params): Result<EmptyResponse> {
        return userPinResetService.resetPin(
            overrideHeaders = params.overrideHeaders,
            verificationCode = params.verificationCode,
            pinCode = params.pinCode,
            email = params.email,
            phone = params.phone,
            countryCode = params.countryCode
        )
    }
}
