/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.updateDefaultBusinessId.internal

import features.updateDefaultBusinessId.models.DefaultBusiness
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.updateDefaultBusinessId.internal.UpdateDefaultBusinessIdUseCase.Params

internal class UpdateDefaultBusinessIdUseCase : AsyncUseCase<DefaultBusiness, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String,
        val defaultBusinessId: String
    )

    private val updateDefaultBusinessIdService: UpdateDefaultBusinessIdService = UpdateDefaultBusinessIdServiceImpl()

    override suspend fun run(params: Params): Result<DefaultBusiness> {
        return updateDefaultBusinessIdService.updateDefaultBusinessId(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId,
            defaultBusinessId = params.defaultBusinessId
        )
    }
}
