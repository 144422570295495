/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.uploadImage.internal

import features.uploadImage.models.ImagesResponseModel
import kangaroo.multiplatformSdk.uploadImage.internal.UploadImageRequest
import kangaroorewards.appsdk.core.io.toModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.krMultiPartForm.UploadImageRequestModel

internal class UploadImageServiceImpl : UploadImageService {

    private val uploadImageRequest = UploadImageRequest()

    override suspend fun uploadImage(
        overrideHeaders : Map<String, String>?,
        uploadImageBodyRequest: UploadImageRequestModel
    ): Result<ImagesResponseModel> {
        return uploadImageRequest.uploadImage(
            overrideHeaders = overrideHeaders,
            uploadImageBodyRequest = uploadImageBodyRequest        ).toModel()
    }
}
