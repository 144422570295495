/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.tiers.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Information about the tier program. Users can move through different tiers if a business has activated their tier program. By acquire points, making purchases, and visiting the business, the user can be rewarded with different tier levels. 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class TierProgramInclude(
    /** User&#39;s tier status.  */
    @SerialName("tier_level")
    val tierLevel: UserTierLevel?,

    /** Information about the tier program. Users can move through different tiers if a business has activated their tier program. By acquire points, making purchases, and visiting the business, the user can be rewarded with different tier levels.  */
    @SerialName("tiers")
    val tiers: Tiers?,

    /** User&#39;s tier progress and status. If a user&#39;s tier status is statically fixed, this will be null as they do not progress from tier to tier.  */
    @SerialName("tier_progress")
    val tierProgress: TierProgress?
)
