/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.publicBanners.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Business banners. Used to display promotional material.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class BannersModel(
    @SerialName("data")
    val data: List<Banner>?
) : Model()
