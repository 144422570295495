/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userCampaignMessages.internal

import features.userCampaignMessages.models.CampaignMessagesModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class CampaignMessagesServiceImpl : CampaignMessagesService {

    private val campaignMessagesRequest = CampaignMessagesRequest()

    override suspend fun getCampaignMessages(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<CampaignMessagesModel> {
        return campaignMessagesRequest.getCampaignMessages(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
