/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.strings.internal

import features.strings.models.ApplicationStringsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class StringsServiceImpl : StringsService {

    private val stringsRequest = StringsRequest()

    override suspend fun getStrings(
        overrideHeaders : Map<String, String>?,
    ): Result<ApplicationStringsModel> {
        return stringsRequest.getStrings(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
