/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.getPublicCampaign

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.getPublicCampaign.GetPublicCampaignApi
import features.getPublicCampaign.models.PublicCampaignsResponseModel
import features.getPublicCampaign.serializeGetPublicCampaignState



/**
 * Get the offers / rewards for a related campaign [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("GetPublicCampaignApi")
class GetPublicCampaignApiJs {

    /**
     * Get the offers / rewards for a related campaign
     */
    fun getPublicCampaign(
        overrideHeaders: String?, 
        campaignId: String
    ): Promise<String?>  {
        return GlobalScope.promise {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

            val result = GetPublicCampaignApi().getPublicCampaign(
            overrideHeaders = decodedOverrideHeaders,
                        campaignId = campaignId
        ).toJsonResult<PublicCampaignsResponseModel>()
        
            return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    

    fun observeGetPublicCampaignState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        GetPublicCampaignApi().getPublicCampaignState.serializeGetPublicCampaignState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
