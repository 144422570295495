/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userScanReceipt.internal

import features.userScanReceipt.models.ScanReceiptRequest
import features.userScanReceipt.models.ScanReceiptResponseModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class UserScanReceiptRequest : NetworkRequest() {

    suspend fun scanReceipt(
        overrideHeaders: Map<String, String>? = null,
        scanReceiptRequest: ScanReceiptRequest
    ): IOResult<ScanReceiptResponseModel> {
        return safeNetworkCall {
            post<ScanReceiptResponseModel, ScanReceiptRequest>(
                endpoint = UserScanReceiptEndpoint,
                requestBody = scanReceiptRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
