/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.getSpinToWinPrizes.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class PrizeModel(
    /** fill style color */
    @SerialName("fill_style")
    val fillStyle: String?,

    /** prize name */
    @SerialName("text")
    val text: String?,

    /** prize description */
    @SerialName("desc")
    val desc: String?,

    /** prize id */
    @SerialName("prize_id")
    val prizeId: Int?
) : Model()
