@file:Suppress("unused")

package kangaroorewards.appsdk.core.domain

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.io.Model
import kotlinx.serialization.InternalSerializationApi
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.serializer
import kotlin.js.ExperimentalJsExport

/**
 * Base result class returned from all [Api] calls.
 * Result will wrap various states such as Success, Error, Loading, etc...
 */
sealed class Result<out T : Model?> {
    data class Idle<out T : Model>(
        val state: ResultMetaData = ResultMetaData(
            type = Idle::class.simpleName ?: "unknown Result",
            code = -1,
            msg = "result is idle"
        )
    ) : Result<T>()

    @Serializable
    data class Loading<out T : Model>(
        val state: ResultMetaData = ResultMetaData(
            type = Loading::class.simpleName ?: "unknown Result",
            code = -1,
            msg = "result is loading"
        )
    ) : Result<T>()

    @Serializable
    data class UnauthorizedError<out T : Model>(val error: ResultMetaData) : Result<T>()

    @Serializable
    data class EmptyResponse<out T : Model>(val body: String) : Result<T>()

    @Serializable
    data class UnknownError<out T : Model>(val error: ResultMetaData) : Result<T>()

    @Serializable
    data class Success<out T : Model>(val data: T) : Result<T>()
}

/**
 * Metadata class used to store serializable information
 * about [Result] states. Any state that is not [Result.Success]
 * should contain metadata.
 */
@Serializable
data class ResultMetaData(
    /** Must match the class name of the Error being returned eg "UnauthorizedError" */
    val type: String,
    val code: Int,
    val msg: String,
)

@ExperimentalJsExport
sealed class SerializedResult<out T : String?> {
    @Serializable
    data class Idle(val state: String) : SerializedResult<String>()

    @Serializable
    data class Loading(val state: String) : SerializedResult<String>()

    @Serializable
    data class UnauthorizedError(val error: String) : SerializedResult<String>()

    @Serializable
    data class UnknownError(val error: String) : SerializedResult<String>()

    @Serializable
    data class EmptyResponse(val body: String) : SerializedResult<String>()

    @Serializable
    data class Success(val data: String) : SerializedResult<String>()
}

@ExperimentalJsExport
@OptIn(InternalSerializationApi::class)
inline fun < reified R : Model> Result<Model>.toJsonResult(): SerializedResult<String> {
    return when (this) {
        is Result.Idle -> {
            println("json result mapper: idle ${R::class}");
            SerializedResult.Idle(Json.encodeToString(ResultMetaData::class.serializer(), state))
        }
        is Result.Loading -> {
            println("json result mapper: loading ${R::class}");
            SerializedResult.Loading(Json.encodeToString(ResultMetaData::class.serializer(), state))
        }
        is Result.Success -> {
            println("json result mapper: success ${R::class}");
            SerializedResult.Success(Json.encodeToString(R::class.serializer(), data as R))
        }
        is Result.UnauthorizedError -> {
            println("json result mapper: unauth ${R::class}");
            SerializedResult.UnauthorizedError(
                Json.encodeToString(
                    ResultMetaData::class.serializer(),
                    error
                )
            )
        }
        is Result.UnknownError -> {
            println("json result mapper: unknown error ${R::class}");
            SerializedResult.UnknownError(
                Json.encodeToString(
                    ResultMetaData::class.serializer(),
                    error
                )
            )
        }
        is Result.EmptyResponse -> {
            println("json result mapper: empty response ${R::class}");
            SerializedResult.EmptyResponse("{}")
        }
    }
}