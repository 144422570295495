/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.banners.internal

import features.banners.models.BannersModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class BannersServiceImpl : BannersService {

    private val bannersRequest = BannersRequest()

    override suspend fun getBanners(
        overrideHeaders : Map<String, String>?,
    ): Result<BannersModel> {
        return bannersRequest.getBanners(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
