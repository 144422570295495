/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessBanners.internal

import features.userBusinessBanners.models.UserBusinessBannersModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userBusinessBanners.internal.UserBusinessBannersUseCase.Params

internal class UserBusinessBannersUseCase : AsyncUseCase<UserBusinessBannersModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val userBusinessBannersService: UserBusinessBannersService = UserBusinessBannersServiceImpl()

    override suspend fun run(params: Params): Result<UserBusinessBannersModel> {
        return userBusinessBannersService.getUserBusinessBanners(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
