/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.depositGiftCard.internal

import features.depositGiftCard.models.TransferActionResultModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class GiftCardDepositRequest : NetworkRequest() {

    suspend fun depositGiftCard(
        overrideHeaders: Map<String, String>? = null,
        depositId: String
    ): IOResult<TransferActionResultModel> {
        return safeNetworkCall {
            patch<TransferActionResultModel, Any>(
                endpoint = GiftCardDepositEndpoint.replaceInPath(
                    PathParameter("depositId", depositId),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
