/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userAlaCarte.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * User A la carte products model class containing user A la carte list
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class AlaCarteProductsModel(
    /** Contains all user data */
    @SerialName("data")
    val data: UserProfileDataModel?,

    /** Contains all A la carte items */
    @SerialName("included")
    val included: UserAlaCarteIncluded?
) : Model()
