/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userNotificationPreferencesUpdate.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class NotificationSettingsDataPayload(
    /** The notification settings id. This is not required when updating notification settings.  */
    @SerialName("id")
    val id: String?,

    @SerialName("business")
    val business: NotificationBusinessPayload?,

    @SerialName("allow_email")
    val allowEmail: Boolean?,

    @SerialName("allow_sms")
    val allowSms: Boolean?,

    @SerialName("allow_push")
    val allowPush: Boolean?
)
