/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userPinUpdate.internal

import features.userPinUpdate.models.UpdatePinRequest
import features.userPinUpdate.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userPinUpdate.internal.UserPinUpdateUseCase.Params

internal class UserPinUpdateUseCase : AsyncUseCase<UserProfileModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val updatePinRequest: UpdatePinRequest
    )

    private val userPinUpdateService: UserPinUpdateService = UserPinUpdateServiceImpl()

    override suspend fun run(params: Params): Result<UserProfileModel> {
        return userPinUpdateService.updatePin(
            overrideHeaders = params.overrideHeaders,
            updatePinRequest = params.updatePinRequest
        )
    }
}
