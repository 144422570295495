/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userVerification.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Extra user profile data
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class UserProfileInclude(
    /** User Profile balance data. */
    @SerialName("balance")
    val balance: UserBalance?,

    /** User&#39;s tier status.  */
    @SerialName("tier_level")
    val tierLevel: TierLevel?,

    /** User&#39;s tier progress and status. If a user&#39;s tier status is statically fixed, this will be null as they do not progress from tier to tier.  */
    @SerialName("tier_progress")
    val tierProgress: TierProgress?,

    /** A list of referral programs the user has access to. */
    @SerialName("referral_programs")
    val referralPrograms: List<ReferralProgram>?
)
