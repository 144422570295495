/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.getSpinToWinPrizes

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.getSpinToWinPrizes.internal.GetSpinToWinPrizesUseCase
import features.getSpinToWinPrizes.internal.GetSpinToWinPrizesUseCase.Params
import features.getSpinToWinPrizes.models.SpinToWinPrizesModel


typealias GetSpinToWinPrizesState = CFlow<GetSpinToWinPrizesUseCaseResult?>

typealias GetSpinToWinPrizesUseCaseResult = Result<SpinToWinPrizesModel>

fun GetSpinToWinPrizesUseCaseResult.serializeGetSpinToWinPrizesApiResult(): SerializedResult<String> {
    return this.toJsonResult<SpinToWinPrizesModel>()
}

/**
 * Serializes [GetSpinToWinPrizesState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun GetSpinToWinPrizesState.serializeGetSpinToWinPrizesState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<SpinToWinPrizesModel>()
    }.asCFlow()
}

/**
 * Get the spin to win prizes of a business [Api] class
 */
class GetSpinToWinPrizesApi : Api() {

    val getSpinToWinPrizesState: GetSpinToWinPrizesState
        get() = _getSpinToWinPrizesState.asCFlow()

    companion object {
        private val _getSpinToWinPrizesState: MutableStateFlow<GetSpinToWinPrizesUseCaseResult> = MutableStateFlow(Idle())
    }

    private val getSpinToWinPrizesUseCase = GetSpinToWinPrizesUseCase()

    /**
     * Get the spin to win prizes of a business
     */
    suspend fun getSpinToWinPrizes(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<SpinToWinPrizesModel> {
        /* set state to Loading as soon as function is called */
        _getSpinToWinPrizesState.value = Loading()
        val result = this.getSpinToWinPrizesUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _getSpinToWinPrizesState.value = result
            return@getSpinToWinPrizesUseCase result
        }
        return result
    }

}
