/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userProfile.internal

import features.userProfile.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userProfile.internal.UserProfileUseCase.Params

internal class UserProfileUseCase : AsyncUseCase<UserProfileModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val userProfileService: UserProfileService = UserProfileServiceImpl()

    override suspend fun run(params: Params): Result<UserProfileModel> {
        return userProfileService.getUserProfile(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
