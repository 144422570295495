/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.getSpinToWinPrizes.internal

import features.getSpinToWinPrizes.models.SpinToWinPrizesModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.getSpinToWinPrizes.internal.GetSpinToWinPrizesUseCase.Params

internal class GetSpinToWinPrizesUseCase : AsyncUseCase<SpinToWinPrizesModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val businessId: String
    )

    private val getSpinToWinPrizesService: GetSpinToWinPrizesService = GetSpinToWinPrizesServiceImpl()

    override suspend fun run(params: Params): Result<SpinToWinPrizesModel> {
        return getSpinToWinPrizesService.getSpinToWinPrizes(
            overrideHeaders = params.overrideHeaders,
            businessId = params.businessId
        )
    }
}
