/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.archiveInboxItem.internal

import features.archiveInboxItem.models.ArchiveRequest
import features.archiveInboxItem.models.ArchiveResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.archiveInboxItem.internal.ArchiveInboxItemUseCase.Params

internal class ArchiveInboxItemUseCase : AsyncUseCase<ArchiveResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val archiveRequest: ArchiveRequest
    )

    private val archiveInboxItemService: ArchiveInboxItemService = ArchiveInboxItemServiceImpl()

    override suspend fun run(params: Params): Result<ArchiveResponseModel> {
        return archiveInboxItemService.archiveInboxItem(
            overrideHeaders = params.overrideHeaders,
            archiveRequest = params.archiveRequest
        )
    }
}
