/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userPinUpdate.internal

import features.userPinUpdate.models.UpdatePinRequest
import features.userPinUpdate.models.UserProfileModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class UserPinUpdateRequest : NetworkRequest() {

    suspend fun updatePin(
        overrideHeaders: Map<String, String>? = null,
        updatePinRequest: UpdatePinRequest
    ): IOResult<UserProfileModel> {
        return safeNetworkCall {
            patch<UserProfileModel, UpdatePinRequest>(
                endpoint = UserPinUpdateEndpoint,
                requestBody = updatePinRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
