/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userBalanceTransfer

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userBalanceTransfer.BalanceTransferApi
import features.userBalanceTransfer.models.TransferResponseModel
import features.userBalanceTransfer.serializeBalanceTransferState
import features.userBalanceTransfer.models.TransferRequestModel

import kotlinx.serialization.decodeFromString

/**
 * Transfer points or e-wallet balance to another users account.  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("BalanceTransferApi")
class BalanceTransferApiJs {

    /**
     * Transfer points or e-wallet balance to another users account. 
     */


    
    fun transfer(overrideHeaders: String?,
serializedTransferRequest: String): Promise<String?>  {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

        return GlobalScope.promise {
            val result = BalanceTransferApi().transfer(
            overrideHeaders = decodedOverrideHeaders,
                transferRequest = Json.decodeFromString(
                    serializedTransferRequest
                )
            ).toJsonResult<TransferResponseModel>()

           return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    fun observeBalanceTransferState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        BalanceTransferApi().balanceTransferState.serializeBalanceTransferState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
