package features.userAuthentication.internal

import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userAuthentication.internal.AuthenticateUser.Params
import features.userAuthentication.models.UserAuthenticationModel

internal class AuthenticateUser : AsyncUseCase<UserAuthenticationModel, Params>() {

    data class Params(
        val username: String?,
        val password: String?,
        val googleToken: String?,
        val overrideHeaders: Map<String, String>?,
    )

    private val userAuthenticationService: UserAuthenticationService = UserAuthenticationServiceImpl()

    override suspend fun run(params: Params): Result<UserAuthenticationModel> {
        return userAuthenticationService.authenticateUser(
            overrideHeaders = params.overrideHeaders,
            username = params.username,
            password = params.password,
            googleToken = params.googleToken
        )
    }
}