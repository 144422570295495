/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.depositGiftCard.internal

import features.depositGiftCard.models.TransferActionResultModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GiftCardDepositServiceImpl : GiftCardDepositService {

    private val giftCardDepositRequest = GiftCardDepositRequest()

    override suspend fun depositGiftCard(
        overrideHeaders : Map<String, String>?,
        depositId: String    ): Result<TransferActionResultModel> {
        return giftCardDepositRequest.depositGiftCard(
            overrideHeaders = overrideHeaders,
            depositId = depositId        ).toModel()
    }
}
