/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userBusinessRewards.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class RewardModel(
    /** A unique ID identifying this reward */
    @SerialName("id")
    val id: Int?,

    @SerialName("points")
    val points: Int?,

    @SerialName("publish_at")
    val publishAt: String?,

    @SerialName("expires_at")
    val expiresAt: String?,

    @SerialName("is_published")
    val isPublished: Boolean?,

    @SerialName("amount")
    val amount: Float?,

    @SerialName("real_value")
    val realValue: Float?,

    @SerialName("discount_value")
    val discountValue: Float?,

    @SerialName("partner_reward")
    val partnerReward: Boolean?,

    @SerialName("partner_reward_type")
    val partnerRewardType: Int?,

    @SerialName("redeem_for_gift_card")
    val redeemForGiftCard: Boolean?,

    @SerialName("type")
    val type: String?,

    @SerialName("title")
    val title: String?,

    @SerialName("description")
    val description: String?,

    @SerialName("slug")
    val slug: String?,

    @SerialName("images")
    val images: List<ImageModel>?,

    @SerialName("never_expires_flag")
    val neverExpiresFlag: Boolean?,

    @SerialName("terms_conditions")
    val termsConditions: String?,

    @SerialName("link")
    val link: String?,

    @SerialName("reward_languages")
    val rewardLanguages: List<RewardTranslation>?
) : Model()
