/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.depositGiftCard.internal

import features.depositGiftCard.models.TransferActionResultModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.depositGiftCard.internal.GiftCardDepositUseCase.Params

internal class GiftCardDepositUseCase : AsyncUseCase<TransferActionResultModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val depositId: String
    )

    private val giftCardDepositService: GiftCardDepositService = GiftCardDepositServiceImpl()

    override suspend fun run(params: Params): Result<TransferActionResultModel> {
        return giftCardDepositService.depositGiftCard(
            overrideHeaders = params.overrideHeaders,
            depositId = params.depositId
        )
    }
}
