/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.branches

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.branches.internal.BranchesUseCase
import features.branches.internal.BranchesUseCase.Params
import features.branches.models.Business


typealias BranchesState = CFlow<BranchesUseCaseResult?>

typealias BranchesUseCaseResult = Result<Business>

fun BranchesUseCaseResult.serializeBranchesApiResult(): SerializedResult<String> {
    return this.toJsonResult<Business>()
}

/**
 * Serializes [BranchesState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun BranchesState.serializeBranchesState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<Business>()
    }.asCFlow()
}

/**
 * Get the branches of a business [Api] class
 */
class BranchesApi : Api() {

    val branchesState: BranchesState
        get() = _branchesState.asCFlow()

    companion object {
        private val _branchesState: MutableStateFlow<BranchesUseCaseResult> = MutableStateFlow(Idle())
    }

    private val branchesUseCase = BranchesUseCase()

    /**
     * Get the branches of a business
     */
    suspend fun getBusinessBranches(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): Result<Business> {
        /* set state to Loading as soon as function is called */
        _branchesState.value = Loading()
        val result = this.branchesUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                businessId = businessId
            )
        ) { result ->
            _branchesState.value = result
            return@branchesUseCase result
        }
        return result
    }

}
