/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userScanReceipt.internal

import features.userScanReceipt.models.ScanReceiptRequest
import features.userScanReceipt.models.ScanReceiptResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userScanReceipt.internal.UserScanReceiptUseCase.Params

internal class UserScanReceiptUseCase : AsyncUseCase<ScanReceiptResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val scanReceiptRequest: ScanReceiptRequest
    )

    private val userScanReceiptService: UserScanReceiptService = UserScanReceiptServiceImpl()

    override suspend fun run(params: Params): Result<ScanReceiptResponseModel> {
        return userScanReceiptService.scanReceipt(
            overrideHeaders = params.overrideHeaders,
            scanReceiptRequest = params.scanReceiptRequest
        )
    }
}
