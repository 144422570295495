/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.redeemReward.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class RedeemRequest(
    @SerialName("branch_id")
    val branchId: String,

    /** A list of rewards to redeem. */
    @SerialName("catalog_items")
    val catalogItems: List<RedemptionModel>,

    @SerialName("intent")
    val intent: String
)
