/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.giftCards.internal

import features.giftCards.models.GiftCardsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.giftCards.internal.GiftCardsUseCase.Params

internal class GiftCardsUseCase : AsyncUseCase<GiftCardsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val giftCardsService: GiftCardsService = GiftCardsServiceImpl()

    override suspend fun run(params: Params): Result<GiftCardsModel> {
        return giftCardsService.getGiftCards(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
