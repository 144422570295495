/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.giftCards.internal

import features.giftCards.models.GiftCardsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GiftCardsServiceImpl : GiftCardsService {

    private val giftCardsRequest = GiftCardsRequest()

    override suspend fun getGiftCards(
        overrideHeaders : Map<String, String>?,
    ): Result<GiftCardsModel> {
        return giftCardsRequest.getGiftCards(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
