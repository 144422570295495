/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userVerification.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * User Profile model class containing user data
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class UserProfileModel(
    /** Contains all user profile data */
    @SerialName("data")
    val data: UserProfileDataModel?,

    /** Contains user balance data */
    @SerialName("included")
    val included: UserProfileInclude?
) : Model()
