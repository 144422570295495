/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.business.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Details about a business. Basic information is in main data field, while information on the business' branches are in the 'include' parameter 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class Business(
    /** Details about a business */
    @SerialName("data")
    val data: BusinessData?,

    /** Details about a business&#39; branches */
    @SerialName("included")
    val included: BusinessBranchesData?
) : Model()
