/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userNotificationPreferencesUpdate.internal

import features.userNotificationPreferencesUpdate.models.NotificationSettingsModel
import features.userNotificationPreferencesUpdate.models.NotificationSettingsUpdateRequest
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class UpdateNotificationPreferencesRequest : NetworkRequest() {

    suspend fun updateNotificationPreferences(
        overrideHeaders: Map<String, String>? = null,
        notificationPreferencesRequest: NotificationSettingsUpdateRequest
    ): IOResult<NotificationSettingsModel> {
        return safeNetworkCall {
            put<NotificationSettingsModel, NotificationSettingsUpdateRequest>(
                endpoint = UpdateNotificationPreferencesEndpoint,
                requestBody = notificationPreferencesRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
