/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userVerification.internal

import features.userVerification.models.UserVerificationResponseModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userVerification.internal.UserAccountVerificationUseCase.Params

internal class UserAccountVerificationUseCase : AsyncUseCase<UserVerificationResponseModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val token: String,
        val email: String?,
        val phone: String?,
        val countryCode: String?
    )

    private val userAccountVerificationService: UserAccountVerificationService = UserAccountVerificationServiceImpl()

    override suspend fun run(params: Params): Result<UserVerificationResponseModel> {
        return userAccountVerificationService.verifyAccount(
            overrideHeaders = params.overrideHeaders,
            token = params.token,
            email = params.email,
            phone = params.phone,
            countryCode = params.countryCode
        )
    }
}
