/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.performSocialMediaAction.internal

import features.performSocialMediaAction.models.PerformSocialMediaActionRequestModel
import features.performSocialMediaAction.models.PerformSocialMediaActionResponseModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class SocialMediaActionPerformRequest : NetworkRequest() {

    suspend fun performSocialMediaAction(
        overrideHeaders: Map<String, String>? = null,
        performSocialMediaActionRequest: PerformSocialMediaActionRequestModel,
        businessId: String
    ): IOResult<PerformSocialMediaActionResponseModel> {
        return safeNetworkCall {
            post<PerformSocialMediaActionResponseModel, PerformSocialMediaActionRequestModel>(
                endpoint = SocialMediaActionPerformEndpoint.replaceInPath(
                    PathParameter("businessId", businessId),
                ),
                requestBody = performSocialMediaActionRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
