/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.performSocialMediaAction

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.performSocialMediaAction.internal.SocialMediaActionPerformUseCase
import features.performSocialMediaAction.internal.SocialMediaActionPerformUseCase.Params
import features.performSocialMediaAction.models.PerformSocialMediaActionResponseModel
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import features.performSocialMediaAction.models.PerformSocialMediaActionRequestModel


typealias SocialMediaActionPerformState = CFlow<SocialMediaActionPerformUseCaseResult?>

typealias SocialMediaActionPerformUseCaseResult = Result<PerformSocialMediaActionResponseModel>

fun SocialMediaActionPerformUseCaseResult.serializeSocialMediaActionPerformApiResult(): SerializedResult<String> {
    return this.toJsonResult<PerformSocialMediaActionResponseModel>()
}

/**
 * Serializes [SocialMediaActionPerformState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun SocialMediaActionPerformState.serializeSocialMediaActionPerformState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PerformSocialMediaActionResponseModel>()
    }.asCFlow()
}

/**
 * To perform a social media action and get rewarded after.  [Api] class
 */
class SocialMediaActionPerformApi : Api() {

    val socialMediaActionPerformState: SocialMediaActionPerformState
        get() = _socialMediaActionPerformState.asCFlow()

    companion object {
        private val _socialMediaActionPerformState: MutableStateFlow<SocialMediaActionPerformUseCaseResult> = MutableStateFlow(Idle())
    }

    private val socialMediaActionPerformUseCase = SocialMediaActionPerformUseCase()

    /**
     * To perform a social media action and get rewarded after. 
     */
    suspend fun performSocialMediaAction(
        overrideHeaders: Map<String, String>? = null,
        performSocialMediaActionRequest: PerformSocialMediaActionRequestModel,
        businessId: String
    ): Result<PerformSocialMediaActionResponseModel> {
        /* set state to Loading as soon as function is called */
        _socialMediaActionPerformState.value = Loading()
        val result = this.socialMediaActionPerformUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                performSocialMediaActionRequest = performSocialMediaActionRequest,
                businessId = businessId
            )
        ) { result ->
            _socialMediaActionPerformState.value = result
            return@socialMediaActionPerformUseCase result
        }
        return result
    }

    /**
    * To perform a social media action and get rewarded after. 
    *
    * This method is used by the Native artifact when dealing with object body params
    */
    suspend fun performSocialMediaAction(
        overrideHeaders: Map<String, String>? = null,
        methods: Map<String, Any>,
businessId: String
    
    ): Result<PerformSocialMediaActionResponseModel> {
        return performSocialMediaAction(
            overrideHeaders = overrideHeaders,
            performSocialMediaActionRequest = Json.decodeFromString(methods.getValue("performSocialMediaActionRequest") as String),
            businessId = businessId
        )
    }
}
