/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.publicProducts.internal

import features.publicProducts.models.PublicProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PublicProductsServiceImpl : PublicProductsService {

    private val publicProductsRequest = PublicProductsRequest()

    override suspend fun getPublicProducts(
        overrideHeaders : Map<String, String>?,
    ): Result<PublicProductsModel> {
        return publicProductsRequest.getPublicProducts(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
