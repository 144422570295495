/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userPinResetRequest

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userPinResetRequest.internal.UserPinResetRequestUseCase
import features.userPinResetRequest.internal.UserPinResetRequestUseCase.Params
import features.userPinResetRequest.models.UserPinResetRequest


typealias UserPinResetRequestState = CFlow<UserPinResetRequestUseCaseResult?>

typealias UserPinResetRequestUseCaseResult = Result<UserPinResetRequest>

fun UserPinResetRequestUseCaseResult.serializeUserPinResetRequestApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserPinResetRequest>()
}

/**
 * Serializes [UserPinResetRequestState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserPinResetRequestState.serializeUserPinResetRequestState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserPinResetRequest>()
    }.asCFlow()
}

/**
 * Request a PIN reset. Either a phone number or email address is required If successful, a verification code will be sent by either email or SMS message.  [Api] class
 */
class UserPinResetRequestApi : Api() {

    val userPinResetRequestState: UserPinResetRequestState
        get() = _userPinResetRequestState.asCFlow()

    companion object {
        private val _userPinResetRequestState: MutableStateFlow<UserPinResetRequestUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userPinResetRequestUseCase = UserPinResetRequestUseCase()

    /**
     * Request a PIN reset. Either a phone number or email address is required If successful, a verification code will be sent by either email or SMS message. 
     */
    suspend fun requestPinReset(
        overrideHeaders: Map<String, String>? = null,
        mode: String = "verification_code",
        email: String? = null,
        phone: String? = null,
        countryCode: String? = null
    ): Result<UserPinResetRequest> {
        /* set state to Loading as soon as function is called */
        _userPinResetRequestState.value = Loading()
        val result = this.userPinResetRequestUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                mode = mode,
                email = email,
                phone = phone,
                countryCode = countryCode
            )
        ) { result ->
            _userPinResetRequestState.value = result
            return@userPinResetRequestUseCase result
        }
        return result
    }

}
