/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.makeGiftCardPurchase.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class GiftCardPurchaseResponseGiftCardData(
    @SerialName("id")
    val id: Int?,

    @SerialName("title")
    val title: String?,

    @SerialName("price")
    val price: Double?,

    @SerialName("currency")
    val currency: String?,

    @SerialName("quantity")
    val quantity: String?
)
