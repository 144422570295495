/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.redeemCoupon.internal

import features.redeemCoupon.models.CouponRedemptionResponseModel
import features.redeemCoupon.models.RedeemCouponRequest
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest

internal class RedeemCouponsRequest : NetworkRequest() {

    suspend fun redeemCoupon(
        overrideHeaders: Map<String, String>? = null,
        redeemCouponRequest: RedeemCouponRequest
    ): IOResult<CouponRedemptionResponseModel> {
        return safeNetworkCall {
            post<CouponRedemptionResponseModel, RedeemCouponRequest>(
                endpoint = RedeemCouponsEndpoint,
                requestBody = redeemCouponRequest,
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
