/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.businessList.internal

import features.businessList.models.Businesses
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.businessList.internal.BusinessesUseCase.Params

internal class BusinessesUseCase : AsyncUseCase<Businesses, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val businessesService: BusinessesService = BusinessesServiceImpl()

    override suspend fun run(params: Params): Result<Businesses> {
        return businessesService.getBusinesses(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
