/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.getPublicCampaign.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class PublicCampaignsDataModel(
    @SerialName("id")
    val id: Int?,

    @SerialName("group_id")
    val groupId: Int?,

    @SerialName("name")
    val name: String?,

    @SerialName("subject")
    val subject: String?,

    @SerialName("html_content")
    val htmlContent: String?,

    @SerialName("plain_content")
    val plainContent: String?,

    @SerialName("push_title")
    val pushTitle: String?,

    @SerialName("push_body")
    val pushBody: String?,

    @SerialName("push_link")
    val pushLink: String?,

    @SerialName("push_image")
    val pushImage: String?,

    @SerialName("status")
    val status: String?,

    @SerialName("type")
    val type: String?,

    @SerialName("frequency")
    val frequency: Int?,

    @SerialName("created_at")
    val createdAt: String?,

    @SerialName("scheduled_at")
    val scheduledAt: String?,

    @SerialName("expires_at")
    val expiresAt: String?,

    @SerialName("offers")
    val offers: List<OfferRewardDataModel>?,

    @SerialName("business")
    val business: BusinessData?
) : Model()
