/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userRegistrationWithCrm.internal

import features.userRegistrationWithCrm.models.CrmRegisterRequest
import features.userRegistrationWithCrm.models.UserProfileDataModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.userRegistrationWithCrm.internal.UserRegistrationWithCrmUseCase.Params

internal class UserRegistrationWithCrmUseCase : AsyncUseCase<UserProfileDataModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val registerWithCrmRequest: CrmRegisterRequest
    )

    private val userRegistrationWithCrmService: UserRegistrationWithCrmService = UserRegistrationWithCrmServiceImpl()

    override suspend fun run(params: Params): Result<UserProfileDataModel> {
        return userRegistrationWithCrmService.createAccountWithCrm(
            overrideHeaders = params.overrideHeaders,
            registerWithCrmRequest = params.registerWithCrmRequest
        )
    }
}
