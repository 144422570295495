/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessProfile.internal

import features.userBusinessProfile.models.UserBusinessProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessProfileServiceImpl : UserBusinessProfileService {

    private val userBusinessProfileRequest = UserBusinessProfileRequest()

    override suspend fun getUserBusinessProfile(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<UserBusinessProfileModel> {
        return userBusinessProfileRequest.getUserBusinessProfile(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
