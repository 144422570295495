/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.draws.internal

import features.draws.models.DrawsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class DrawsServiceImpl : DrawsService {

    private val drawsRequest = DrawsRequest()

    override suspend fun getDraws(
        overrideHeaders : Map<String, String>?,
    ): Result<DrawsModel> {
        return drawsRequest.getDraws(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
