/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userNotificationPreferencesUpdate.internal

import kangaroorewards.appsdk.core.network.base.Endpoint

/**
 * The HTTP endpoint for the [UpdateNotificationPreferencesApi].
 *
 * [Endpoint] has a single [Endpoint.path] parameter ex. "users/me"
 */
internal val UpdateNotificationPreferencesEndpoint = Endpoint(path = "users/me/settings/notifications")
