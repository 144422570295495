/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userBusinessCoupons.internal

import features.userBusinessCoupons.models.UserBusinessCouponsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserBusinessCouponsServiceImpl : UserBusinessCouponsService {

    private val userBusinessCouponsRequest = UserBusinessCouponsRequest()

    override suspend fun getUserBusinessCoupons(
        overrideHeaders : Map<String, String>?,
        businessId: String    ): Result<UserBusinessCouponsModel> {
        return userBusinessCouponsRequest.getUserBusinessCoupons(
            overrideHeaders = overrideHeaders,
            businessId = businessId        ).toModel()
    }
}
