/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.pushTokenRegistration.internal

import features.pushTokenRegistration.models.UserProfileModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField

internal class PushTokenRegistrationRequest : NetworkRequest() {

    suspend fun registerPushToken(
        overrideHeaders: Map<String, String>? = null,
        androidDeviceToken: String?,
        iosDeviceToken: String?
    ): IOResult<UserProfileModel> {
        return safeNetworkCall {
            patch<UserProfileModel, Any>(
                endpoint = PushTokenRegistrationEndpoint,
                fields = listOf(
                    FormField(name = "android_device_token", value = androidDeviceToken),
                    FormField(name = "ios_device_token", value = iosDeviceToken)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
