/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.redeemCoupon.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class RedeemCouponRequest(
    @SerialName("intent")
    val intent: String,

    @SerialName("pin_code")
    val pinCode: String,

    /** The coupon being redeemed */
    @SerialName("coupon")
    val coupon: CouponRedemptionModel,

    /** The offer that created this coupon */
    @SerialName("offer")
    val offer: OfferRedemptionModel,

    @SerialName("int")
    val int: String?
)
