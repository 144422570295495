/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicALaCarteProducts.internal

import features.publicALaCarteProducts.models.PublicALaCarteProductsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.publicALaCarteProducts.internal.PublicAlaCarteProductsUseCase.Params

internal class PublicAlaCarteProductsUseCase : AsyncUseCase<PublicALaCarteProductsModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val publicAlaCarteProductsService: PublicAlaCarteProductsService = PublicAlaCarteProductsServiceImpl()

    override suspend fun run(params: Params): Result<PublicALaCarteProductsModel> {
        return publicAlaCarteProductsService.getPublicAlaCarteProducts(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
