/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userRegistration.internal

import features.userRegistration.models.UserProfileDataModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.FormField

internal class UserRegistrationRequest : NetworkRequest() {

    suspend fun createAccount(
        overrideHeaders: Map<String, String>? = null,
        email: String?,
        phone: String?,
        countryCode: String?,
        language: String?
    ): IOResult<UserProfileDataModel> {
        return safeNetworkCall {
            post<UserProfileDataModel, Any>(
                endpoint = UserRegistrationEndpoint,
                fields = listOf(
                    FormField(name = "email", value = email),
                    FormField(name = "phone", value = phone),
                    FormField(name = "country_code", value = countryCode),
                    FormField(name = "language", value = language)),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
