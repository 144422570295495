/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userPinResetWithToken.internal

import features.userPinResetWithToken.models.PinResetWithTokenRequestModel
import kangaroorewards.appsdk.core.io.EmptyResponse
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserPinResetWithTokenServiceImpl : UserPinResetWithTokenService {

    private val userPinResetWithTokenRequest = UserPinResetWithTokenRequest()

    override suspend fun resetPinWithToken(
        overrideHeaders : Map<String, String>?,
        pinResetWithTokenRequest: PinResetWithTokenRequestModel    ): Result<EmptyResponse> {
        return userPinResetWithTokenRequest.resetPinWithToken(
            overrideHeaders = overrideHeaders,
            pinResetWithTokenRequest = pinResetWithTokenRequest        ).toModel()
    }
}
