/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userRegistration.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class ImageModel(
    @SerialName("large")
    val large: String?,

    @SerialName("medium")
    val medium: String?,

    @SerialName("thumbnail")
    val thumbnail: String?,

    @SerialName("default")
    val default: Boolean?,

    @SerialName("path")
    val path: String?
) : Model()
