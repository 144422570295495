/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userTransactionHistory.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * User Profile model class containing user data
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class UserTransactionHistoryModel(
    /** Contains user transaction history. */
    @SerialName("data")
    val data: List<Transaction>?
) : Model()
