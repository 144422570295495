/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userProducts

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userProducts.internal.UserProductsUseCase
import features.userProducts.internal.UserProductsUseCase.Params
import features.userProducts.models.UserProductsModel


typealias UserProductsState = CFlow<UserProductsUseCaseResult?>

typealias UserProductsUseCaseResult = Result<UserProductsModel>

fun UserProductsUseCaseResult.serializeUserProductsApiResult(): SerializedResult<String> {
    return this.toJsonResult<UserProductsModel>()
}

/**
 * Serializes [UserProductsState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun UserProductsState.serializeUserProductsState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<UserProductsModel>()
    }.asCFlow()
}

/**
 * Get user products list [Api] class
 */
class UserProductsApi : Api() {

    val userProductsState: UserProductsState
        get() = _userProductsState.asCFlow()

    companion object {
        private val _userProductsState: MutableStateFlow<UserProductsUseCaseResult> = MutableStateFlow(Idle())
    }

    private val userProductsUseCase = UserProductsUseCase()

    /**
     * Get user products list
     */
    suspend fun getUserProducts(
        overrideHeaders: Map<String, String>? = null,

    ): Result<UserProductsModel> {
        /* set state to Loading as soon as function is called */
        _userProductsState.value = Loading()
        val result = this.userProductsUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _userProductsState.value = result
            return@userProductsUseCase result
        }
        return result
    }

}
