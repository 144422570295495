/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.updateDefaultBusinessId.internal

import features.updateDefaultBusinessId.models.DefaultBusiness
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UpdateDefaultBusinessIdServiceImpl : UpdateDefaultBusinessIdService {

    private val updateDefaultBusinessIdRequest = UpdateDefaultBusinessIdRequest()

    override suspend fun updateDefaultBusinessId(
        overrideHeaders : Map<String, String>?,
        businessId: String,
        defaultBusinessId: String    ): Result<DefaultBusiness> {
        return updateDefaultBusinessIdRequest.updateDefaultBusinessId(
            overrideHeaders = overrideHeaders,
            businessId = businessId,
            defaultBusinessId = defaultBusinessId        ).toModel()
    }
}
