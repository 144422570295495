/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.makePayPalPayment.internal

import features.makePayPalPayment.models.PayPalPaymentModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PayPalPaymentServiceImpl : PayPalPaymentService {

    private val payPalPaymentRequest = PayPalPaymentRequest()

    override suspend fun makePayPalPayment(
        overrideHeaders : Map<String, String>?,
        intent: String,
        provider: String,
        giftcardId: Int,
        paypalReturnUrl: String,
        paypalCancelUrl: String    ): Result<PayPalPaymentModel> {
        return payPalPaymentRequest.makePayPalPayment(
            overrideHeaders = overrideHeaders,
            intent = intent,
            provider = provider,
            giftcardId = giftcardId,
            paypalReturnUrl = paypalReturnUrl,
            paypalCancelUrl = paypalCancelUrl        ).toModel()
    }
}
