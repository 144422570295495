package kangaroorewards.appsdk.core.utils

import kangaroorewards.appsdk.core.SdkContext
import kangaroorewards.appsdk.core.network.base.Endpoint

object Configuration {

    fun getServerHostUrl(serverHostId : Int): String{
        return when(serverHostId){
            0 -> getBaseUrl()
            1 -> getIntegrationUrl()

            else -> getBaseUrl()
        }
    }

    fun getBaseUrl(): String {
        if(SdkContext.baseURL.isNullOrEmpty()){
            return when (SdkContext.environment) {
                "production" -> {
                    "https://api.kangaroorewards.com/"
                }
                "staging" -> {
                    "https://api-stg.traktrok.com/"
                }
                "development" -> {
                    "https://api-dev.traktrok.com/"
                }
                else -> {
                    "https://api.kangaroorewards.com/"
                }
            }
        }
        else{
            return SdkContext.baseURL!!
        }
    }

    private fun getIntegrationUrl(): String {
        return when (SdkContext.environment) {
            "production" -> {
                "https://integrations.kangarooapis.com/"
            }
            "staging" -> {
                "https://integrations.kangarooapis.com/"
            }
            "development" -> {
                "https://integ-api-dev.traktrok.com/"
            }
            else -> {
                "https://integ-api-dev.traktrok.com/"
            }
        }
    }

    fun getUploadImageEndpoint(module: Int?): Endpoint {
        return when (module) {
            0 -> {
                Endpoint(path = "users/me/images")
            }
            1 -> {
                Endpoint(path = "attachments")
            }
            2 -> {
                Endpoint(path = "offers/images")
            }
            3 -> {
                Endpoint(path = "rewards/images")
            }
            else -> {
                Endpoint(path = "users/me/images")
            }
        }
    }
}
