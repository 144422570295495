/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.userConglomerateNotificationPreferences

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.userConglomerateNotificationPreferences.internal.GetConglomerateNotificationPreferencesUseCase
import features.userConglomerateNotificationPreferences.internal.GetConglomerateNotificationPreferencesUseCase.Params
import features.userConglomerateNotificationPreferences.models.NotificationSettingsModel


typealias GetConglomerateNotificationPreferencesState = CFlow<GetConglomerateNotificationPreferencesUseCaseResult?>

typealias GetConglomerateNotificationPreferencesUseCaseResult = Result<NotificationSettingsModel>

fun GetConglomerateNotificationPreferencesUseCaseResult.serializeGetConglomerateNotificationPreferencesApiResult(): SerializedResult<String> {
    return this.toJsonResult<NotificationSettingsModel>()
}

/**
 * Serializes [GetConglomerateNotificationPreferencesState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun GetConglomerateNotificationPreferencesState.serializeGetConglomerateNotificationPreferencesState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<NotificationSettingsModel>()
    }.asCFlow()
}

/**
 * Get a users notification settings for a conglomerate business.  [Api] class
 */
class GetConglomerateNotificationPreferencesApi : Api() {

    val getConglomerateNotificationPreferencesState: GetConglomerateNotificationPreferencesState
        get() = _getConglomerateNotificationPreferencesState.asCFlow()

    companion object {
        private val _getConglomerateNotificationPreferencesState: MutableStateFlow<GetConglomerateNotificationPreferencesUseCaseResult> = MutableStateFlow(Idle())
    }

    private val getConglomerateNotificationPreferencesUseCase = GetConglomerateNotificationPreferencesUseCase()

    /**
     * Get a users notification settings for a conglomerate business. 
     */
    suspend fun getConglomerateNotificationPreferences(
        overrideHeaders: Map<String, String>? = null,

    ): Result<NotificationSettingsModel> {
        /* set state to Loading as soon as function is called */
        _getConglomerateNotificationPreferencesState.value = Loading()
        val result = this.getConglomerateNotificationPreferencesUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _getConglomerateNotificationPreferencesState.value = result
            return@getConglomerateNotificationPreferencesUseCase result
        }
        return result
    }

}
