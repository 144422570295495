/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicBusinessSettings.internal

import features.publicBusinessSettings.models.PublicBusinessSettings
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.publicBusinessSettings.internal.PublicBusinessSettingsUseCase.Params

internal class PublicBusinessSettingsUseCase : AsyncUseCase<PublicBusinessSettings, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val publicBusinessSettingsService: PublicBusinessSettingsService = PublicBusinessSettingsServiceImpl()

    override suspend fun run(params: Params): Result<PublicBusinessSettings> {
        return publicBusinessSettingsService.getPublicBusinessSettings(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
