/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userRegistration.internal

import kangaroorewards.appsdk.core.network.base.Endpoint

/**
 * The HTTP endpoint for the [UserRegistrationApi].
 *
 * [Endpoint] has a single [Endpoint.path] parameter ex. "users/me"
 */
internal val UserRegistrationEndpoint = Endpoint(path = "users")
