/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.publicRewards.internal

import features.publicRewards.models.PublicRewardsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PublicRewardsServiceImpl : PublicRewardsService {

    private val publicRewardsRequest = PublicRewardsRequest()

    override suspend fun getPublicRewards(
        overrideHeaders : Map<String, String>?,
    ): Result<PublicRewardsModel> {
        return publicRewardsRequest.getPublicRewards(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
