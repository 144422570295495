/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userProducts.internal

import kangaroorewards.appsdk.core.network.base.Endpoint

/**
 * The HTTP endpoint for the [UserProductsApi].
 *
 * [Endpoint] has a single [Endpoint.path] parameter ex. "users/me"
 */
internal val UserProductsEndpoint = Endpoint(path = "users/me?include=products")
