/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicDepositGiftCard.internal

import features.publicDepositGiftCard.models.TransferActionResultModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.publicDepositGiftCard.internal.PublicDepositGiftCardUseCase.Params

internal class PublicDepositGiftCardUseCase : AsyncUseCase<TransferActionResultModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,
        val userId: String,
        val messageId: String
    )

    private val publicDepositGiftCardService: PublicDepositGiftCardService = PublicDepositGiftCardServiceImpl()

    override suspend fun run(params: Params): Result<TransferActionResultModel> {
        return publicDepositGiftCardService.publicDepositGiftCard(
            overrideHeaders = params.overrideHeaders,
            userId = params.userId,
            messageId = params.messageId
        )
    }
}
