/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.countries.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A country model
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class Country(
    /** Unique country ID. */
    @SerialName("id")
    val id: Int?,

    /** The name of this country */
    @SerialName("name")
    val name: String?,

    /** The two character country code for this country. Follows ISO standards.  */
    @SerialName("code")
    val code: String?,

    /** The three character country code for this country. Follows ISO standards.  */
    @SerialName("code_iso3")
    val codeIso3: String?,

    /** The international phoen code for this country. */
    @SerialName("phone_code")
    val phoneCode: Int?
)
