package kangaroorewards.appsdk.core.utils

import io.ktor.utils.io.core.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach

/**
 * Wraps Flow with [CFlow] wrapper
 */
//@JsExport
fun <T> Flow<T>.asCFlow(): CFlow<T> = CFlow(this)

/**
 * Common Flow wrapper which can be observed from JVM and Native code
 *
 */
//@JsExport
class CFlow<T>(private val origin: Flow<T>) : Flow<T> by origin {
    /**
     * Observes the flow for value updates.
     */
    fun observe(block: (T) -> Unit): Closeable {
        val job = Job()

        onEach { it ->
            block(it)
        }.launchIn(CoroutineScope(dispatcher() + job))

        return object : Closeable {
            override fun close() {
                job.cancel()
            }
        }
    }
}