/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userNotificationPreferences.internal

import features.userNotificationPreferences.models.NotificationSettingsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class GetNotificationPreferencesServiceImpl : GetNotificationPreferencesService {

    private val getNotificationPreferencesRequest = GetNotificationPreferencesRequest()

    override suspend fun getNotificationPreferences(
        overrideHeaders : Map<String, String>?,
    ): Result<NotificationSettingsModel> {
        return getNotificationPreferencesRequest.getNotificationPreferences(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
