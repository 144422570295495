/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.redeemReward.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class RedeemResponseData(
    @SerialName("id")
    val id: Int,

    @SerialName("amount")
    val amount: Double?,

    @SerialName("points")
    val points: Int?,

    @SerialName("name")
    val name: String?,

    @SerialName("created_at")
    val createdAt: String?,

    @SerialName("updated_at")
    val updatedAt: String?,

    @SerialName("customer")
    val customer: UserProfileModel?
)
