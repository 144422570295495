/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.pushTokenRegistration.internal

import features.pushTokenRegistration.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PushTokenRegistrationServiceImpl : PushTokenRegistrationService {

    private val pushTokenRegistrationRequest = PushTokenRegistrationRequest()

    override suspend fun registerPushToken(
        overrideHeaders : Map<String, String>?,
        androidDeviceToken: String?,
        iosDeviceToken: String?    ): Result<UserProfileModel> {
        return pushTokenRegistrationRequest.registerPushToken(
            overrideHeaders = overrideHeaders,
            androidDeviceToken = androidDeviceToken,
            iosDeviceToken = iosDeviceToken        ).toModel()
    }
}
