/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.businessList

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.businessList.internal.BusinessesUseCase
import features.businessList.internal.BusinessesUseCase.Params
import features.businessList.models.Businesses


typealias BusinessesState = CFlow<BusinessesUseCaseResult?>

typealias BusinessesUseCaseResult = Result<Businesses>

fun BusinessesUseCaseResult.serializeBusinessesApiResult(): SerializedResult<String> {
    return this.toJsonResult<Businesses>()
}

/**
 * Serializes [BusinessesState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun BusinessesState.serializeBusinessesState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<Businesses>()
    }.asCFlow()
}

/**
 * Get a list of businesses associated with this user account and application [Api] class
 */
class BusinessesApi : Api() {

    val businessesState: BusinessesState
        get() = _businessesState.asCFlow()

    companion object {
        private val _businessesState: MutableStateFlow<BusinessesUseCaseResult> = MutableStateFlow(Idle())
    }

    private val businessesUseCase = BusinessesUseCase()

    /**
     * Get a list of businesses associated with this user account and application
     */
    suspend fun getBusinesses(
        overrideHeaders: Map<String, String>? = null,

    ): Result<Businesses> {
        /* set state to Loading as soon as function is called */
        _businessesState.value = Loading()
        val result = this.businessesUseCase(
            Params(
                overrideHeaders = overrideHeaders,

            )
        
        ) { result ->
            _businessesState.value = result
            return@businessesUseCase result
        }
        return result
    }

}
