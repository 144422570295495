@file:OptIn(
    ExperimentalJsExport::class, ExperimentalJsExport::class, ExperimentalJsExport::class,
    ExperimentalJsExport::class, ExperimentalJsExport::class, ExperimentalJsExport::class
)

package kangaroorewards.appsdk.core.network.base

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kangaroorewards.appsdk.core.AuthenticationModel
import kangaroorewards.appsdk.core.AuthenticationScope
import kangaroorewards.appsdk.core.SdkContext
import kangaroorewards.appsdk.core.io.Model
import kangaroorewards.appsdk.core.utils.Configuration
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

object KHttpClient {
    /**
     * Http client used for all http requests
     */
    @OptIn(ExperimentalSerializationApi::class)
    val defaultClient: HttpClient = HttpClient {
        install(ContentNegotiation) {
            json(Json {
                prettyPrint = true
                isLenient = true
                ignoreUnknownKeys = true
                explicitNulls = false

                    /*
                     * Disabled in ktor 1.6.0 as it causes immutability exception
                     * in Native(iOS) platforms
                     *
                     *  TODO test useAlternativeNames on ktor 2.0+
                     */
                    useAlternativeNames = false
                })
            }
            /*
             * LogLevel.ALL and LogLevel.BODY causes the http client to stop responding
             * on Native(iOS) platforms.
             */
            install(Logging)
            install(Logging) {
                logger = Logger.DEFAULT
                level = LogLevel.BODY
            }

        install(Auth) {
            bearer {
                loadTokens {
                    getCachedTokens()
                }
                refreshTokens {
                    refreshTokens(client)
                }
            }
        }
    }

    /**
     * Http client used for all http requests with custom authorization bearer
     */
    @OptIn(ExperimentalSerializationApi::class)
    val customBearerClient: HttpClient = HttpClient {
        install(ContentNegotiation) {
            json(Json {
                prettyPrint = true
                isLenient = true
                ignoreUnknownKeys = true
                explicitNulls = false

                /*
                 * Disabled in ktor 1.6.0 as it causes immutability exception
                 * in Native(iOS) platforms
                 *
                 *  TODO test useAlternativeNames on ktor 2.0+
                 */
                useAlternativeNames = false
            })
        }
        /*
         * LogLevel.ALL and LogLevel.BODY causes the http client to stop responding
         * on Native(iOS) platforms.
         */
        install(Logging)
        install(Logging) {
            logger = Logger.DEFAULT
            level = LogLevel.ALL
        }
    }



    /**
     * Returns an  ISO 639-1 language code
     */
    private fun getPreferredLanguage(): String? {
//        SdkContext.tokenStore.get()
        return SdkContext.tokenStore?.getPreferredLanguage()
    }

    /**
     * Retrieves tokens from cache.
     */
    private fun getCachedTokens(): BearerTokens {
        co.touchlab.kermit.Logger.d {"SDK KHttpClient get token start"}
        val cachedTokens = SdkContext.tokenStore?.getTokens()

        co.touchlab.kermit.Logger.d {"SDK KHttpClient get token result: ${cachedTokens?.accessToken}"}

        return BearerTokens(
            accessToken = cachedTokens?.accessToken ?: "",
            refreshToken = cachedTokens?.refreshToken ?: "",
        )
    }
}

// TODO make dynamic depending on auth scope
internal val UserAuthenticationEndpoint = Endpoint(path = "oauth/token")
suspend fun RefreshTokensParams.refreshTokens(client: HttpClient): BearerTokens? {
    try {
        // We should be able to use oldTokens?.refresh token, but it seems to be unpredictable and occasionally fails.
        val cachedTokens = SdkContext.tokenStore?.getTokens()
        val refreshToken = cachedTokens?.refreshToken
        val tokenScope = cachedTokens?.authScope

        val requestScope = if (tokenScope == AuthenticationScope.Customer.name) "user" else "admin"

        val authResult: UserReAuthenticationModel = client.submitForm(
            url = "${Configuration.getBaseUrl()}${UserAuthenticationEndpoint.path}",
            formParameters = Parameters.build {
                append("client_id", SdkContext.clientId ?: "")
                append("client_secret", SdkContext.clientSecret ?: "")
                append("X-Application-Key", SdkContext.applicationKey ?: "")
                append("refresh_token", refreshToken ?: "")
                append("scope", requestScope)
                append("grant_type", "refresh_token")
            }
        ) { markAsRefreshTokenRequest() }.body()

        SdkContext.storeTokens(authResult.toAuthenticationModel(tokenScope))

        return if (authResult.accessToken != null && authResult.refreshToken != null) {
            BearerTokens(
                accessToken = authResult.accessToken,
                refreshToken = authResult.refreshToken,
            )
        } else {
            null
        }

    } catch (e: Exception) {
        println("RE AUTH FAILED. FETCHING PUBLIC TOKENS")
        // If re-auth fails, get public tokens to allow public access
        val cachedTokens = SdkContext.tokenStore?.getTokens()
        val publicAuthResult: UserReAuthenticationModel = client.submitForm(
            url = "${Configuration.getBaseUrl()}${UserAuthenticationEndpoint.path}",
            formParameters = Parameters.build {
                append("client_id", SdkContext.clientId ?: "")
                append("client_secret", SdkContext.clientSecret ?: "")
                append("X-Application-Key", SdkContext.applicationKey ?: "")
                append("grant_type", "client_credentials")
                append("scope", "user")
            }
        ) { markAsRefreshTokenRequest() }.body()
//        SdkContext.tokenStore?.storeTokens(publicAuthResult.toAuthenticationModel(cachedTokens?.authScope))
        return if (publicAuthResult.accessToken != null && publicAuthResult.refreshToken != null) {
            BearerTokens(
                accessToken = publicAuthResult.accessToken,
                refreshToken = publicAuthResult.refreshToken,
            )
        } else {
            null
        }
    }
}

@ExperimentalJsExport
@Serializable
@JsExport
data class UserReAuthenticationModel(
    @SerialName("token_type")
    val tokenType: String? = "Bearer",
    @SerialName("expires_in")
    val expiresIn: Int? = 0,
    @SerialName("access_token")
    val accessToken: String?,
    @SerialName("refresh_token")
    val refreshToken: String? = "",
) : Model() {
    companion object {
        const val ACCESS_TOKEN = "access_token"
        const val REFRESH_TOKEN = "refresh_token"
    }
}

fun UserReAuthenticationModel.toAuthenticationModel(scope: String?): AuthenticationModel {
    return AuthenticationModel(
        authScope = scope ?: "",
        accessToken = accessToken,
        refreshToken = refreshToken,
    )
}