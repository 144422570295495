/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userTransferRecall.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Transfer Message. These are sent to the user by other users. They can be points or e-wallet transfers or gift cards (which is deposited into the users' e-wallet) 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class TransferMessage(
    /** The unique ID of this transfer. */
    @SerialName("id")
    val id: Int?,

    /** The type ID of this transfer. */
    @SerialName("type_id")
    val typeId: Int?,

    /** A string representation of this transfer type. */
    @SerialName("type")
    val type: String?,

    /** The description of this transfer. */
    @SerialName("description")
    val description: String?,

    /** The amount of points sent in this transfer. */
    @SerialName("points")
    val points: Int?,

    /** The amount in $ sent in this transfer. */
    @SerialName("amount")
    val amount: Double?,

    @SerialName("hidden")
    val hidden: Boolean?,

    /** The orignal send date of this transfer. */
    @SerialName("created_at")
    val createdAt: String?,

    /** The gift card in this transfer if applicable. Gift cards are an offer sub-type in Kangaroo.  */
    @SerialName("offer")
    val offer: OfferModel?,

    @SerialName("gift_card_queue")
    val giftCardQueue: TransferMessageGiftCardQueue?,

    @SerialName("actions")
    val actions: List<TransferMessageActions>?,

    /** If this transfer message is archived or not. */
    @SerialName("archived")
    val archived: Boolean?
)
