@file:Suppress("unused")

package kangaroorewards.js.appsdk

import kangaroorewards.appsdk.core.KangarooSdk

@OptIn(ExperimentalJsExport::class)
@JsExport
class KangarooSdk {
    fun initialize(
        applicationKey: String,
        clientId: String,
        clientSecret: String,
        environment: String = "production",
        isDebug: Boolean,
        baseURL: String
    ) {
        if(isDebug){
            println("initializing sdk with $applicationKey - $clientId - $clientSecret - $baseURL - $isDebug")
        }
        KangarooSdk().initialize(applicationKey, clientId, clientSecret, environment, baseURL)
    }

    /**
     * Sets a preferred language for the SDK
     *
     * @param preferredLanguage A language's ISO 639-1 code.
     */
    fun setPreferredLanguage(preferredLanguage: String) {
        KangarooSdk().setPreferredLanguage(preferredLanguage)
    }

    /**
     * Returns the preferred language setting for the SDK
     *
     * @return A language's ISO 639-1 code.
     */
    fun getPreferredLanguage(): String? {
        return KangarooSdk().getPreferredLanguage()
    }

    fun getSession(): String? {
        return KangarooSdk().getSession()
    }

    fun killSession() {
        KangarooSdk().killSession()
    }
}