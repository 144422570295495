/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.redeemCoupon.internal

import features.redeemCoupon.models.CouponRedemptionResponseModel
import features.redeemCoupon.models.RedeemCouponRequest
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class RedeemCouponsServiceImpl : RedeemCouponsService {

    private val redeemCouponsRequest = RedeemCouponsRequest()

    override suspend fun redeemCoupon(
        overrideHeaders : Map<String, String>?,
        redeemCouponRequest: RedeemCouponRequest    ): Result<CouponRedemptionResponseModel> {
        return redeemCouponsRequest.redeemCoupon(
            overrideHeaders = overrideHeaders,
            redeemCouponRequest = redeemCouponRequest        ).toModel()
    }
}
