/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate")

package js.features.userScanReceipt

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise
import kotlinx.serialization.json.Json
import features.userScanReceipt.UserScanReceiptApi
import features.userScanReceipt.models.ScanReceiptResponseModel
import features.userScanReceipt.serializeUserScanReceiptState
import features.userScanReceipt.models.ScanReceiptRequest

import kotlinx.serialization.decodeFromString

/**
 * Scan a purchase receipt with a business  [Api] class
 */
@OptIn(DelicateCoroutinesApi::class)
@ExperimentalJsExport
@JsExport
@JsName("UserScanReceiptApi")
class UserScanReceiptApiJs {

    /**
     * Scan a purchase receipt with a business 
     */


    
    fun scanReceipt(overrideHeaders: String?,
serializedScanReceiptRequest: String): Promise<String?>  {

        val decodedOverrideHeaders = if (overrideHeaders == null) {
                null
        } else {
            try {
                Json.decodeFromString<Map<String, String>>(overrideHeaders)
            } catch (e: Exception) {
                null
                }
            }

        return GlobalScope.promise {
            val result = UserScanReceiptApi().scanReceipt(
            overrideHeaders = decodedOverrideHeaders,
                scanReceiptRequest = Json.decodeFromString(
                    serializedScanReceiptRequest
                )
            ).toJsonResult<ScanReceiptResponseModel>()

           return@promise when (result) {
                is SerializedResult.Success -> result.data
                is SerializedResult.UnauthorizedError -> result.error
                is SerializedResult.UnknownError -> result.error
                else -> null
            }
        }
    }

    

    fun observeUserScanReceiptState(
        onData: (String) -> Unit,
        onStreamError: (String) -> Unit,
    ) {
        UserScanReceiptApi().userScanReceiptState.serializeUserScanReceiptState().observe { result ->
            when (result) {
                is SerializedResult.Idle -> onData(result.state)
                is SerializedResult.Loading -> onData(result.state)
                is SerializedResult.Success -> onData(result.data)
                is SerializedResult.UnauthorizedError -> onData(result.error)
                is SerializedResult.UnknownError -> onData(result.error)
                else -> onStreamError("unknown error in stream")
            }
        }
    }
}
