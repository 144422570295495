/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userDetails.internal

import features.userDetails.models.UserDetailsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserDetailsServiceImpl : UserDetailsService {

    private val userDetailsRequest = UserDetailsRequest()

    override suspend fun getUserDetails(
        overrideHeaders : Map<String, String>?,
    ): Result<UserDetailsModel> {
        return userDetailsRequest.getUserDetails(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
