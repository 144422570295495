/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessOffers.internal

import features.userBusinessOffers.models.UserBusinessOffersModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class UserBusinessOffersRequest : NetworkRequest() {

    suspend fun getUserBusinessOffers(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): IOResult<UserBusinessOffersModel> {
        return safeNetworkCall {
            get(
                endpoint = UserBusinessOffersEndpoint.replaceInPath(
                    PathParameter("businessId", businessId),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
