/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.userBusinessRewards.internal

import features.userBusinessRewards.models.UserBusinessRewardsModel
import kangaroorewards.appsdk.core.io.IOResult
import kangaroorewards.appsdk.core.network.NetworkRequest
import kangaroorewards.appsdk.core.network.base.PathParameter
import kangaroorewards.appsdk.core.network.base.replaceInPath

internal class UserBusinessRewardsRequest : NetworkRequest() {

    suspend fun getUserBusinessRewards(
        overrideHeaders: Map<String, String>? = null,
        businessId: String
    ): IOResult<UserBusinessRewardsModel> {
        return safeNetworkCall {
            get(
                endpoint = UserBusinessRewardsEndpoint.replaceInPath(
                    PathParameter("businessId", businessId),
                ),
                serverHostId = 0,
                overrideHeaders = overrideHeaders
            )
        }
    }
}
