/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userCoupons.internal

import features.userCoupons.models.UserCouponsModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserCouponsServiceImpl : UserCouponsService {

    private val userCouponsRequest = UserCouponsRequest()

    override suspend fun getUserCoupons(
        overrideHeaders : Map<String, String>?,
    ): Result<UserCouponsModel> {
        return userCouponsRequest.getUserCoupons(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
