/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.tiers.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents the user's current tier level.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class UserTierLevel(
    /** The ID for this tier. */
    @SerialName("id")
    val id: Int?,

    /** The name of this tier level. */
    @SerialName("name")
    val name: String?,

    /** The amount of money spent required to reach this tier. */
    @SerialName("reach_spend")
    val reachSpend: Int?,

    /** The amount of visits made to reach this tier. */
    @SerialName("reach_visits")
    val reachVisits: Int?,

    /** The amount of points acquired that is required to reach this tier. */
    @SerialName("reach_points")
    val reachPoints: Int?
)
